import { PlantPartTimeSeries } from "../core/timeseries.mjs";
import { $, cleanArea, createElementAndClassList, createsetAttribute, checkDebug, $E } from "../helper.mjs";
import { myEvent, navigate } from "../index.mjs";
import { gLoginState, login, userjson } from "../mymsal.mjs";
import { PlantObject } from "../core/plant.mjs";

function loginCheck(loginstate) {
    try {
        if (loginstate) {
            $("userw").innerHTML = userjson.displayName;
            $("loginq").innerHTML = "Please, select your options from the menu  - or check the high level status below.";
            fillOverview();
        } else {
            $("userw").innerHTML = "User";
            $("loginq").innerHTML = "You are not logged in, yet. Login? Press here: ";

            let but = $E("button");
            but.setAttributeNode(document.createAttribute("class"));
            but.classList.add("btn","btn-primary");
            but.setAttributeNode(document.createAttribute("id"));
            but.setAttribute("id", "loginbw");
            but.innerHTML = "Login";

            $("loginq").appendChild(but);
            
            but.addEventListener("click", login)
        }
    }
    catch (e) {
        console.log(e);
    }
}

async function fillOverview() {
    let start = $("systemcards");
    start.classList.add("row", "row-cols-auto");
    Object.keys(gLoginState.authorizedPlants).forEach(async (p) => {
        let plant = gLoginState.authorizedPlants[p];
        if (plant.File !== "" && plant.File !== undefined) {
            let col = createElementAndClassList("div", "col");
            let system = createElementAndClassList("div", "card");
            start.appendChild(col);
            col.appendChild(system);
            let teaser = createElementAndClassList("p", ["card-text", "text-break"]);
            let systemHead = createElementAndClassList("div", "card-header");
            let title = createElementAndClassList("h5", "card-title");
            title.textContent = plant.Menu;
            systemHead.appendChild(title);
            system.appendChild(systemHead);
            let systemBody = createElementAndClassList("div", "card-body");
            systemBody.append(teaser);
            system.appendChild(systemBody);
            if (plant.VIP !== "") {
                let ulTime = createElementAndClassList("ul", ["d-inline", "p-3"]);
                let intervalButtons = [{ "interval": "short", "label": "Short (3 days)" }, { "interval": "middle", "label": "Middle (12 weeks)" }, { "interval": "long", "label": "Long (12 months)" }];
                let vip = new PlantObject();
                vip.init(plant.VIPname, plant.VIP, false);
                for (let i = 0; i < intervalButtons.length; i++) {
                    let li = createElementAndClassList("li", ["nav-item", "d-inline"]);
                    let btn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-primary", "me-2"]);
                    btn.type = "button";
                    btn.id = intervalButtons[i]["interval"];
                    btn.textContent = intervalButtons[i]["label"];
                    myEvent.attach(btn, "click", async () => {
                        let plantPartTS = new PlantPartTimeSeries(intervalButtons[i]["interval"], null);
                        let _ = await plantPartTS.getTimeSeries(null, intervalButtons[i]["interval"], plant.System, plant.VIP);
                        plantPartTS.fillTimeSeriesChart(plant.AreaKey, [plant.VIP], false, true, vip);
                    });
                    li.appendChild(btn);
                    ulTime.appendChild(li);
                }
                let divider = createElementAndClassList("div", "mt-3");
                let anchor = createElementAndClassList("div", "card-img-top");
                anchor.id = plant.AreaKey;
                anchor.textContent = "Loading time series for " + plant.VIPname;
                systemBody.append(ulTime, divider, anchor);
                let ppts = new PlantPartTimeSeries("short", null);
                let ts = await ppts.getTimeSeries(null, "short", plant.System, plant.VIP);
                if (checkDebug()) console.log(ts);
                teaser.textContent = "Time series below show sensors: " + plant.VIP +" - "+ plant.VIPname;
                ppts.fillTimeSeriesChart(anchor.id, [plant.VIP], false, true, vip);
                fixResize(false);
            }
            let systemFooter = createElementAndClassList("div", ["card-footer", "d-flex", "justify-content-between"]);
            let goToButtons = [
                {"text": "Monitored Objects", "data": "plant=" },
                {"text": "Assets", "data": "asset="},
                {"text": "Computed Sensors", "data": "compsens="}
            ];
            goToButtons.forEach(v => {
                let button = createElementAndClassList("a", ["btn", "btn-primary"]);
                button.href = "#";
                button.textContent = v.text;
                createsetAttribute(button, "data", v.data + plant.AreaKey);
                myEvent.attach(button, "click", navigate);
                systemFooter.appendChild(button);
            });

            system.appendChild(systemFooter);
        }
    });
    
}

function fixResize(recreate = true) {

    let defitems = [];
    let plantKeys = [];
    Object.keys(gLoginState.authorizedPlants).forEach(async (p) => {
        let plant = gLoginState.authorizedPlants[p];
        if (plant.File !== "" && plant.File !== undefined) {
            if (plant.VIP !== "") {
                plantKeys.push(plant.AreaKey);
            }
        }
    });
    for (let v of plantKeys) {
        if ($(v) !== null)
            defitems.push($(v));
    }
    for (let v of defitems) {
        try {
            if (recreate) {
                let p = { ...v };
                cleanArea(v.id);
                Plotly.newPlot(v.id, p.data, p.layout, p.config);
            } else {
                Plotly.newPlot(v.id, v.data, v.layout, v.config);
            }
        } catch (e) { console.log(e); }
    }
}

export function startwelcome(){
    console.log("startwelcome");
    let state = false;
    try {
        if (typeof(gLoginState) == "undefined")
            state = false;
        else {
            state = gLoginState.getLoginState();
        }
    } catch (e) {
        console.log(e)
    }
    loginCheck(state);
}

export function destroywelcome() {
    let plantKeys = [];
    Object.keys(gLoginState.authorizedPlants).forEach(async (p) => {
        let plant = gLoginState.authorizedPlants[p];
        if (plant.File !== "" && plant.File !== undefined) {
            if (plant.VIP !== "") {
                plantKeys.push(plant.AreaKey);
            }
        }
    });
    for (let v of plantKeys) {
        if ($(v)) Plotly.purge(v);
    }
}
