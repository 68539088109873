import { checkDebug } from "./helper.mjs";
import { Server } from "./mymsal.mjs";
let apps = {
    "localhost": "http://localhost:3030",
    "test": "https://icmp-we-test-ui-backdoor.azurewebsites.net",
    "prod": "https://icmp-we-prod-ui-backdoor.azurewebsites.net",
    "basf": "https://icmp-we-prod-ui-backdoor.azurewebsites.net"
}
let myurl = "https://icmp-we-test-ui-backdoor.azurewebsites.net";
for (let app of Object.keys(apps)) {
    if (window.location.host.includes(app)) {
        myurl = apps[app];
        break;
    }
}

class LocalCacheItem {
    constructor(url, options, response) {
        this.url = url;
        this.options = options;
        this.response = response;
        this.timestamp = new Date();
    }
}

class LocalCache {
    constructor() {
        this.cache = [];
        console.info("LocalCache created.");
        this.blacklist = [
            "/blackbox/model/build", "/blob/upload/MOConfig", "/blackbox/model/train", "/blackbox/model/clear", "/blackbox/timeseries/uploadFromBlob",
            "/ingest/triggerExport",
            "/blob/download", "/blob/delete", "/blob/container/contents",
            "/msg/read"
        ];
        this.whitewashsetup = { whiteurls: ["msg/insert"], timespan: 60000 };
    }

    get(url, options) {
        let you = this.cache.find((v) => {
            return v.url === url && v.options.method === options.method && v.options.body === options.body;
        });
        if (you === undefined) return false;
        if (this.blacklist.includes(you.url)) return false;
        for (let w in this.whitewashsetup.whiteurls) {
            if (this.checkUrlHistory(this.whitewashsetup.whiteurls[w], this.whitewashsetup.timespan)) {
                if (checkDebug(2)) console.info("whitewash blacklist");
                this.cache.filter(item => item.timestamp <= you.timestamp);
                return false;
            }
        }
        let deadline = new Date(you.timestamp);
        if (checkDebug(2)) console.info(new Date() - Number(deadline));
        if (new Date() - Number(deadline) > 600000) {
            this.cache.filter(item => item.timestamp <= deadline);
            return false;
        }
        if (checkDebug(2)) console.info("LocalCache saved again a lot of traffic");
        //let urls = this.cache.map((v) => {return v.url});
        return you.response;
    }

    push(url, options, response) {
        let me = new LocalCacheItem(url, options, response);
        this.cache.push(me);
    }

    checkUrlHistory(url, timespan) {
        let item = this.cache.find((v) => {
            return v.url == url && v.timestamp > new Date() - timespan;
        })
        if (item === undefined) return false
        else return true;
    }
}

let cache = new LocalCache();

export async function callMe(url, method, body, myoptions = false, dojson = true, dostring = true) {
    let headers = await Server.getHeader();
    headers.append("Content-Type", "application/json");
    if (checkDebug(2)) console.info("Request to ", url, body);
    var options = {};
    if (myoptions) {
        options = myoptions;
        if (Object.keys(options).includes("headers"))
            if (Object.keys(options.headers).includes("Content-Type")) options.headers["Content-Type"] = "application/json";
            else options.headers.append("Content-Type", "application/json");
        else options.headers = headers;
    } else {
        options = {
            method: method, // GET, POST, PUT, DELETE, etc.
            cache: "no-cache", // default, no-cache, reload, force-cache, only-if-cached
            headers: headers
        };
        if (method == "POST" || method == "PUT") {
            if (dostring) {
                options.body = JSON.stringify(body, null, 4);
            } else {
                options.body = body;
            }
        }
    }
    let response = cache.get(url, options);
    if (!response) {
        response = await fetch(myurl + url, options);
        if (!response.ok) {
            let error = await response.json();
            console.error("So ein Mist", response.status, error);
            return error;
        } else if (checkDebug(3)) console.log(response);
        let data = response.body;
        if (dojson) {
            try {
                data = await response.json();
            }
            catch (error) {
                console.info("json error", response);
            }
        }      
        cache.push(url, options, data);
        return data;

    } else return response;

}