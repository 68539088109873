import { $, Spinner, cleanArea, checkData, PlotlyConfig, createElementAndClassList, createListElementWithLink, checkDebug, safeAppend, $T } from "../helper.mjs";
import { callMe } from "../mybackend.mjs";
import { myEvent } from "../index.mjs";
import { Signals } from "./signals.mjs"
import { kpis } from "./strategy.mjs";
import { gLoginState } from "../mymsal.mjs";
import { SENSORMAPPING } from "./plant.mjs";
import { swatches } from "../3rdparty/d3/color-legend.mjs";

class MonitoredObjectTimeSeries {
    constructor(interval, moctrlmodel) {
        this.interval = interval;
        this.monitored_object;
        this.strategy;
        this.mosystem;
        this.mo = moctrlmodel;
        this.tscharts = [];
    }

    getTimeSeries(source, interval) {
        this.interval = interval;
        let link = "";
        let prefix = "btn";
        if (source instanceof Element && source.getAttribute("data") !== undefined) {
            let data = source.getAttribute("data");
            data = data.split("§");
            this.monitored_object = data[0];
            this.strategy = data[1];
            link = "-" + this.monitored_object + this.strategy;
        }
        else {
            this.monitored_object = source;
            prefix = "";
        }
        try {
            let intervals = ["short", "middle", "long"];
            for (let i = 0; i < intervals.length; i++) {
                $(prefix + intervals[i] + link).classList.remove("active");
            }
            $(prefix + this.interval + link).classList.add("active");
        } catch (e) {
            console.warn(e);
        }

    }

    setMOSystem(mosystem) {
        this.mosystem = mosystem;
    }

    fillTimeSeriesChart(id, sensors, showlegend, autoexpand, me) {
        let content = cleanArea(id);
        if (!checkData(this.timeseries, content)) return;
        let udata = this.timeseries.data;
        let data = [];
        if (me) {
            this.me = me;
        }
        for (let d in udata) {
            let timestamp = new Date(d);
            timestamp.setUTCMinutes(timestamp.getUTCMinutes() + timestamp.getTimezoneOffset() * -1);
            for (let s in udata[d]) {
                if (data.findIndex((v) => v.sensor === s) < 0 && sensors.includes(s)) {
                    let trace = new Object({ sensor: s, x: [timestamp.toISOString()], y: [udata[d][s]] });
                    data.push(trace);
                } else {
                    let trace = data.find((v) => v.sensor === s);
                    if (trace !== undefined) {
                        trace.x.push(timestamp.toISOString());
                        trace.y.push(udata[d][s]);
                    }
                }
            }
        }
        let mylegend = true;
        if (typeof (showlegend) == "boolean") {
            mylegend = showlegend;
        }
        let myexpand = false;
        if (typeof (myexpand) == "boolean") {
            myexpand = autoexpand;
        }
        let traces = [];
        let prefix = 0;
        if (Number.isInteger(this.mosystem)) {
            let mosys = this.mosystem;
            let plants = gLoginState.getAuthorizedPlants();
            for (let p of Object.keys(plants)) {
                if (plants[p].System == mosys) {
                    prefix = plants[p].Prefix.length;
                    break;
                }
            }
        }
        for (let t in data) {
            traces.push({
                "x": data[t].x,
                "y": data[t].y,
                "customdata": [],
                "name": (data[t].sensor + "").substring(prefix, data[t].sensor.length),
                "xhoverformat": "%x %X",
                "hovertemplate": "%{x}<br />%{y}",
                "mode": 'lines+markers',
                "marker": {
                    "size": 3
                }
            });
        }
        var layout = {
            showlegend: mylegend,
            margin: {
                autoexpand: myexpand,
                l: 40,
                b: 80,
                t: 20,
                r: 70
            },
            shapes: []
        };
        if (this.changepoints) {
            let pairs = [];
            if (Object.keys(this.changepoints).includes("changepoints")) {
                let i = 0;
                for (let c of this.changepoints.changepoints) {
                    layout.shapes.push({
                        type: 'line',
                        x0: c,
                        y0: 0,
                        x1: c,
                        yref: 'paper',
                        y1: 1,
                        line: {
                            color: 'grey',
                            width: 1.5,
                            dash: 'dot'
                        }
                    });
                    if (i == 0) {
                        pairs.push([data[0].x[0], c]);
                    } else pairs.push([this.changepoints.changepoints[i - 1], c]);
                    if (i == this.changepoints.changepoints.length - 1) {
                        pairs.push([c, data[0].x[data[0].x.length - 1]]);
                    }
                    i++;
                }
                let color = ["#2c2c2c", "#404040", "#565656", "#6c6c6c", "#828282", "#9a9a9a", "#b2b2b2", "#cbcbcb"];
                let c = 0;
                for (let p of pairs) {
                    layout.shapes.push({
                        type: 'rect',
                        x0: p[0],
                        y0: 1.1,
                        x1: p[1],
                        yref: 'paper',
                        y1: 0.95,
                        fillcolor: color[c],
                        opacity: 0.4,
                        layer: "below",
                        line: {
                            color: 'grey',
                            width: 1.5,
                            dash: 'dot'
                        }
                    });
                    c = c == color.length - 1 ? 0 : c + 1;
                }
            }
        }
        if (this.statistics) {
            let currentq1 = this.statistics[1].data.statistics[sensors[0]].value_quantile_1;
            let currentq3 = this.statistics[1].data.statistics[sensors[0]].value_quantile_3;
            let trainq1 = this.statistics[0].data.statistics[sensors[0]].value_quantile_1;
            let trainq3 = this.statistics[0].data.statistics[sensors[0]].value_quantile_3;
            let trainiqr = this.statistics[0].data.statistics[sensors[0]].value_iqr;
            let trainmed = this.statistics[0].data.statistics[sensors[0]].value_median;

            let upperlimit = trainmed + trainiqr * 1.5;
            let lowerlimit = trainmed - trainiqr * 1.5;
            if (this.me && upperlimit > this.me.RangeMax) upperlimit = this.me.RangeMax;
            if (this.me && lowerlimit < this.me.RangeMin) lowerlimit = this.me.RangeMin;

            layout.shapes.push({
                type: 'rect',
                x0: 0,
                y0: trainq3,
                x1: 1,
                xref: 'paper',
                y1: trainq1,
                fillcolor: 'lightgreen',
                opacity: 0.3,
                layer: "below",
                line: {
                    color: 'green',
                    width: 1.5,
                    dash: 'dot'
                }
            });
            layout.shapes.push({
                type: 'line',
                x0: 0,
                y0: lowerlimit,
                x1: 1,
                xref: 'paper',
                y1: lowerlimit,
                layer: "below",
                line: {
                    color: 'green',
                    width: 2,
                    dash: 'dot'
                }
            });
            layout.shapes.push({
                type: 'line',
                x0: 0,
                y0: upperlimit,
                x1: 1,
                xref: 'paper',
                y1: upperlimit,
                layer: "below",
                line: {
                    color: 'green',
                    width: 2,
                    dash: 'dot'
                }
            });
            layout.shapes.push({
                type: 'rect',
                x0: 0,
                y0: currentq3,
                x1: 1,
                xref: 'paper',
                y1: currentq1,
                fillcolor: 'lightblue',
                opacity: 0.3,
                layer: "below",
                line: {
                    color: 'blue',
                    width: 1.5,
                    dash: 'dot'
                }
            });
        }
        if (mylegend) {
            layout["legend"] = {
                "orientation": "v"
            };
            layout["margin"]["r"] = 200;
        };
        let config = new PlotlyConfig().getConfig("TimeSeries View on Actuals for " + this.monitored_object);
        config.modeBarButtonsToRemove.concat(['lasso2d']);
        config.modeBarButtonsToAdd = [];
        if (!this.statistics)
            config.modeBarButtonsToAdd.push(
                {
                    name: 'z-standard-normal',
                    title: 'toggle (de-)normalize values',
                    icon: PlotlyConfig.getZIcon(),
                    toggle: true,
                    attr: false,
                    click: function (gd) {
                        if (this.attr) {
                            for (let n = 0; n < gd.data.length; n++) {
                                gd.data[n].y = gd.data[n].customdata;
                                gd.data[n].hovertemplate = "%{x}<br />%{y}";
                            }
                            this.attr = false;
                        }
                        else {
                            for (let n = 0; n < gd.data.length; n++) {
                                let mean = math.mean(gd.data[n].y);
                                let std = math.std(gd.data[n].y);
                                std = isNaN(std) || std == 0 ? 0.00001 : std;
                                gd.data[n].customdata = gd.data[n].y;
                                gd.data[n].y = gd.data[n].y.map((v) => { return (v - mean) / std });
                                gd.data[n].hovertemplate = "%{x}<br />%{customdata}";
                            }
                            this.attr = true;
                        }
                        Plotly.react(gd.id, gd.data, gd.layout, gd.config);
                    }
                }
            );
        try {
            Plotly.newPlot(id, traces, layout, config);
        } catch {
            console.warn("page content changed, cannot create time series plot.")
        }

    }
}
export class PredictedTimeSeries extends MonitoredObjectTimeSeries {
    constructor(interval, moctrlmodel) {
        super(interval, moctrlmodel);
        this.estimator;
    }

    async getTimeSeries(source, interval) {
        super.getTimeSeries(source, interval);
        let moresponses = [];
        for (let s in this.mo.responses) {
            moresponses.push(s);
        }
        let arrayOfPromises = [
            callMe("/blackbox/estimator", "POST", { system: this.mosystem, model: this.monitored_object, strategy: this.strategy }),
            callMe("/blackbox/timeseries", "POST", { system: this.mosystem, model: this.monitored_object, strategy: this.strategy, object_type: "prediction", interval: this.interval, verbose: 3 }),
            callMe("/blackbox/changepoints", "POST", { system: this.mosystem, sensors: moresponses, interval: this.interval })
        ]
        let defts = ["boxplot-", "timeseries-"];
        let content;
        for (let i in defts) {
            content = cleanArea(defts[i] + this.monitored_object + this.strategy);
            content.appendChild(new Spinner().getLoadingElement());
        }
        try {
            let err = false;
            await Promise.all(arrayOfPromises)
                .then((responses) => {
                    if (checkDebug()) console.log(responses);
                    this.estimator = responses[0];
                    if (responses[1].hasOwnProperty("data")) {
                        this.timeseries = responses[1]["data"]["predictions"];
                        Signals.addPredictionSignal(responses[1]["data"]["signals"], this.interval);
                    } else {
                        err = true;
                        for (let i in defts) {
                            content = cleanArea(defts[i] + this.monitored_object + this.strategy);
                            content.appendChild($T(responses[1].message[responses[1].message.at(-1)]));
                        }
                    }
                    if (responses[2].hasOwnProperty("data")) {
                        this.changepoints = responses[2]["data"];
                    }

                })
                .finally(() => {
                    if (!err) {
                        this.tscharts.push(defts[0] + this.monitored_object + this.strategy);
                        this.tscharts.push(defts[1] + this.monitored_object + this.strategy);

                        Promise.race([
                            this.fillMOEstimator(this.monitored_object, this.strategy, this.estimator),
                            this.fillBoxChart(defts[0] + this.monitored_object + this.strategy),
                            this.fillTimeSeriesChart(defts[1] + this.monitored_object + this.strategy)
                        ]);
                    }
                });
            return this.timeseries;
        } catch (error) {
            console.warn(error);
            for (let i in defts) {
                content = cleanArea(defts[i] + this.monitored_object + this.strategy);
                content.appendChild($T("Time Series currently not available - if you experience a longer outage please contact support-icmp@basf.com"));
            }

        }

    }

    fillTimeSeriesChart(id) {
        let content = cleanArea(id);
        if (!checkData(this.timeseries, content)) return;
        let data = this.timeseries;
        var sensor = [];
        var x = [];
        var mydata = [];
        for (var d in data) {
            let s = data[d].sensor;
            if (!sensor.includes(s)) sensor.push(s);
        }
        for (let i = 0; i < sensor.length; i++) {
            x = [];
            let y_act = [];
            let y_pred = [];
            let y_sensor = sensor[i];
            for (var d in data) {
                if (y_sensor == data[d].sensor) {
                    let timestamp = new Date(data[d].timestamp);
                    timestamp.setUTCMinutes(timestamp.getUTCMinutes() + timestamp.getTimezoneOffset() * -1);
                    x.push(timestamp.toISOString());
                    y_act.push(data[d].value_target);
                    y_pred.push(data[d].value_pred);
                }
            }
            mydata.push({ "sensor": y_sensor, "x": x, "yact": y_act, "ypred": y_pred });
        }
        var traces = [];
        for (var d in mydata) {
            traces.push({
                "x": mydata[d].x,
                "y": mydata[d].yact,
                "customdata": [],
                "name": (mydata[d].sensor + "").substring(4, mydata[d].sensor.length) + " (act)",
                "xhoverformat": "%x %X",
                "hovertemplate": "%{x}<br />%{y}",
                "mode": 'lines+markers',
                "marker": {
                    "size": 5
                }
            });
            traces.push({
                "x": mydata[d].x,
                "y": mydata[d].ypred,
                "customdata": [],
                "name": (mydata[d].sensor + "").substring(4, mydata[d].sensor.length) + " (pred)",
                "xhoverformat": "%x %X",
                "hovertemplate": "%{x}<br />%{y}",
                "mode": 'lines+markers',
                "marker": {
                    "size": 3
                }
            });
        }
        var layout = {
            showlegend: true,
            margin: {
                autoexpand: false,
                l: 80,
                b: 60,
                t: 40,
                r: 300
            },
            legend: {
                "orientation": "v",
                "entrywidthmode": "pixel",
                "entrywidth": 200
            },
            shapes: []
        };
        if (this.changepoints) {
            let pairs = [];
            if (Object.keys(this.changepoints).includes("changepoints")) {
                let i = 0;
                for (let c of this.changepoints.changepoints) {
                    layout.shapes.push({
                        type: 'line',
                        x0: c,
                        y0: 0,
                        x1: c,
                        yref: 'paper',
                        y1: 1,
                        line: {
                            color: 'grey',
                            width: 1.5,
                            dash: 'dot'
                        }
                    });
                    if (i == 0) {
                        let timestamp = new Date(data[0].timestamp);
                        timestamp.setUTCMinutes(timestamp.getUTCMinutes() + timestamp.getTimezoneOffset() * -1);
                        pairs.push([timestamp.toISOString(), c]);
                    } else
                        pairs.push([this.changepoints.changepoints[i - 1], c]);
                    if (i == this.changepoints.changepoints.length - 1) {
                        let timestamp = new Date(data[Object.keys(data).at(-1)].timestamp);
                        timestamp.setUTCMinutes(timestamp.getUTCMinutes() + timestamp.getTimezoneOffset() * -1);
                        pairs.push([c, timestamp.toISOString()]);
                    }
                    i++;
                }
                let color = ["#2c2c2c", "#404040", "#565656", "#6c6c6c", "#828282", "#9a9a9a", "#b2b2b2", "#cbcbcb"];
                let c = 0;
                for (let p of pairs) {
                    layout.shapes.push({
                        type: 'rect',
                        x0: p[0],
                        y0: 1.1,
                        x1: p[1],
                        yref: 'paper',
                        y1: 0.95,
                        fillcolor: color[c],
                        opacity: 0.4,
                        layer: "below",
                        line: {
                            color: 'grey',
                            width: 1.5,
                            dash: 'dot'
                        },
                        // label: {
                        //     text: new Date(p[0]).toLocaleString(language) + " - <br />" + p[1],
                        //     yanchor: 'middle',
                        //     textangle: 90,
                        //     xanchor: "center",
                        // }
                    });
                    c = c == color.length - 1 ? 0 : c + 1;
                }
            }
        }

        let config = new PlotlyConfig().getConfig("TimeSeries View on " + this.monitored_object + " - " + this.strategy);
        config.modeBarButtonsToRemove.concat(['zoom2d', 'lasso2d']);
        config.modeBarButtonsToAdd = [];
        config.modeBarButtonsToAdd.push(
            this.__getZButton__("ts")
        );
        Plotly.newPlot(content.id, traces, layout, config);
    }

    fillBoxChart(id, boxpoints = "suspectedoutliers") {
        let content = cleanArea(id);
        if (!checkData(this.timeseries, content)) return;
        let data = this.timeseries;
        var sensor = [];
        var mydata = [];

        for (var d in data) {
            let s = data[d].sensor;
            if (!sensor.includes(s)) sensor.push(s);
        }
        for (let i = 0; i < sensor.length; i++) {
            let y_act = [];
            let y_pred = [];
            let y_sensor = sensor[i];
            for (var d in data) {
                if (y_sensor == data[d].sensor) {
                    y_act.push(data[d].value_target);
                    y_pred.push(data[d].value_pred);
                }
            }
            mydata.push({ "sensor": y_sensor, "yact": y_act, "ypred": y_pred });
        }
        let boxdata = [];
        for (let n = 0; n < mydata.length; n++) {
            boxdata.push({
                name: mydata[n].sensor.substring(4, mydata[n].sensor.length - 4) + " (act)",
                y: mydata[n].yact,
                boxpoints: boxpoints,
                boxmean: 'sd',
                customdata: [],
                hovertemplate: "%{y}",
                type: 'box',
                alignmentgroup: mydata[n].sensor
            });
            boxdata.push({
                name: mydata[n].sensor.substring(4, mydata[n].sensor.length - 4) + " (pred)",
                y: mydata[n].ypred,
                boxpoints: boxpoints,
                customdata: [],
                hovertemplate: "%{y}",
                type: 'box',
                alignmentgroup: mydata[n].sensor
            });
        }
        var layout = {
            showlegend: false,
            margin: {
                autoexpand: false,
                l: 50,
                b: 100,
                t: 40,
                r: 70
            }
        };
        let config = new PlotlyConfig().getConfig("Box Plot View on " + this.monitored_object + " - " + this.strategy);
        config.modeBarButtonsToRemove.concat(['zoom2d', 'lasso2d']);
        config.modeBarButtonsToAdd = [];
        config.modeBarButtonsToAdd.push(
            this.__getZButton__("box")
        );

        Plotly.newPlot(content.id, boxdata, layout, config);
    }

    fillMOEstimator(modl, stratgy, data) {
        let model = modl;
        let strategy = stratgy;
        let content = cleanArea("kpis" + model + strategy);
        let evtcontent = cleanArea("evts" + model + strategy);
        if (data.hasOwnProperty("data")) {
            let scores = data.data.scoring.scores;
            let events = data.data.scoring.events;
            let weights = data.data.scoring.threshold_weights;
            let mystate = data.data.state.state;

            //console.log(model, strategy)
            let kkeys = ["state", "metric", "sensor", "score_value"];
            let knames = ["State", "Metric", "Sensor", "Value"];
            let cols = ["col-2", "col-3", "col-3", "col-4"];
            let eNames = ["Condition", "Event"];
            let ecols = ["col-8", "col-4"];

            let divcon = createElementAndClassList("div", ["container", "text-center", "mb-3", "p-0"]);
            divcon.style = "font-size: 0.9em;"
            let divhead = createElementAndClassList("div", ["row", "justify-content-center"]);
            for (let key in kkeys) {
                let divth = createElementAndClassList("div", [cols[key], "border", "border-primary", "p-0"]);
                divth.textContent = knames[key];
                divhead.appendChild(divth);
            }
            divcon.appendChild(divhead);

            let basis = [];
            let statecol = [];

            for (let score in scores) {
                let divrow = createElementAndClassList("div", ["row", "justify-content-center"]);
                let divtdstat = createElementAndClassList("div", ["col-2", "p-0"]);
                let divtdmetr = createElementAndClassList("div", ["col-3", "p-0"]);
                let divtdsens = createElementAndClassList("div", ["col-3", "p-0"]);
                let divtdval = createElementAndClassList("div", ["col-4", "p-0"]);
                let cells = [divtdstat, divtdmetr, divtdsens, divtdval];
                let scoreparts = score.split(",", 3);
                if (scoreparts[0] != "basis") {
                    for (let n = 0; n < 3; n++) {
                        cells[n].textContent =
                            kpis.findIndex((v) => { return v.name == scoreparts[n] }) >= 0 ?
                                kpis[kpis.findIndex((v) => { return v.name == scoreparts[n] })].short : scoreparts[n];
                    }
                    divtdval.textContent = isNaN(scores[score]) ? "" : Number(scores[score]).toFixed(3);
                    for (let cell of cells) divrow.appendChild(cell);
                    divcon.appendChild(divrow);
                    statecol.push(divtdstat);
                }
                else {
                    scoreparts.push(isNaN(scores[score]) ? "" : Number(scores[score]).toFixed(3).toString());
                    basis.push(scoreparts);
                }
            }
            safeAppend(content, divcon);

            let lastitem = "";
            let takelastbg = false;
            let addbg = false;
            for (let item of statecol) {
                if (item.textContent != lastitem) {
                    lastitem = item.textContent;
                    takelastbg = false;
                } else {
                    takelastbg = true;
                }
                if (!takelastbg) {
                    addbg = !addbg;
                }
                if (addbg) {
                    item.parentElement.classList.add("bg-primary-subtle");
                }
            }

            if (mystate == "active") {
                let divevt = divcon.cloneNode();
                let evthead = createElementAndClassList("div", ["row", "justify-content-center"]);
                for (let name in eNames) {
                    let divth = createElementAndClassList("div", [ecols[name], "border", "border-primary", "p-0"]);
                    divth.textContent = eNames[name];
                    evthead.appendChild(divth);
                }
                divevt.appendChild(evthead);

                for (let evt in events) {
                    let evtparts = evt.split(",", 3);
                    let divrow = createElementAndClassList("div", ["row", "justify-content-center"]);
                    let divcond = createElementAndClassList("div", ["col-8", "p-0"]);
                    let divthen = createElementAndClassList("div", ["col-4", "p-0"]);
                    let mykpi = kpis[kpis.findIndex((v) => { return v.name == evtparts[1] })].short;
                    let mythreshold = basis[basis.findIndex((v) => { return v[1] == evtparts[1] })][3];
                    // let myweight = Number(weights[Object.keys(weights)[Object.keys(weights).findIndex((v) => {
                    //     return v == "basis," + evtparts[1] + ",True"
                    // })]]);
                    divcond.innerHTML = "if <b>" + mykpi + " &lt; " + Number(mythreshold).toFixed(3) + "</b> then:";
                    divthen.textContent = events[evt];
                    divrow.append(divcond, divthen)

                    divevt.appendChild(divrow)
                }
                evtcontent.appendChild(divevt);
            }

        } else {
            let divmes = createElementAndClassList("div", "mb-2");
            divmes.textContent = data.message;
            content.appendChild(divmes);
        }
    }

    __getZButton__(type = "box") {
        return {
            name: 'toggle-z-standard',
            title: 'z-standardization or normal values',
            icon: PlotlyConfig.getZIcon(),
            toggle: true,
            attr: false,
            click: function (gd) {
                if (this.attr) {
                    for (let n = 0; n < gd.data.length; n++) {
                        gd.data[n].y = gd.data[n].customdata;
                        if (type != "box") {
                            gd.data[n].hovertemplate = "%{x}<br />%{y}";
                        }
                    }
                    this.attr = false;
                }
                else {
                    for (let n = 0; n < gd.data.length; n++) {
                        let mean, std, mean1, std1, diffmean;
                        mean = math.mean(gd.data[n].y);
                        mean = isNaN(mean) ? 0 : mean;
                        std = math.std(gd.data[n].y);
                        std = isNaN(std) || std == 0 ? 1 : std;
                        gd.data[n].customdata = gd.data[n].y;
                        if (n % 2 == 0) {
                            gd.data[n].y = gd.data[n].y.map((v) => {
                                return (v - mean) / std
                            });
                        } else {
                            mean1 = math.mean(gd.data[n - 1].customdata);
                            mean1 = isNaN(mean) ? 0 : mean1;
                            std1 = math.std(gd.data[n - 1].customdata);
                            std1 = isNaN(std1) || std1 == 0 ? std : std1;
                            diffmean = (mean - mean1) / std1;
                            gd.data[n].y = gd.data[n].y.map((v) => {
                                return ((v - mean) / std) + diffmean
                            });
                        }
                        if (type != "box") {
                            gd.data[n].hovertemplate = "%{x}<br />%{customdata}";
                        }
                    }
                    this.attr = true;
                }
                Plotly.react(gd.id, gd.data, gd.layout, gd.config);
            }
        }
    }
}
export class MeasurementTimeSeries extends MonitoredObjectTimeSeries {
    constructor(interval, moctrlmodel) {
        super(interval, moctrlmodel);
        this.timeseries;
        this.changepoints = {};
    }

    async getTimeSeries(source, interval, defts = ["resp_ts", "feat_ts"]) {
        super.getTimeSeries(source, interval);
        // MEASUREMENT ENDPOINT
        let sensors = [];
        let moresponses = [];
        let mofeatures = [];
        for (let s in this.mo.responses) {
            sensors.push(s);
            moresponses.push(s);
        }
        for (let s in this.mo.features) {
            sensors.push(s);
            mofeatures.push(s);
        }
        let content;
        for (let i in defts) {
            content = cleanArea(defts[i]);
            content.appendChild(new Spinner().getLoadingElement());
        }

        await Promise.all([
            callMe("/blackbox/measurements", "POST", { system: this.mo.system, sensors: sensors, interval: this.interval }),
            callMe("/blackbox/changepoints", "POST", { system: this.mo.system, sensors: moresponses, interval: this.interval }),
        ])
            .then((responses) => {
                this.timeseries = responses[0];
                this.changepoints = responses[1]["return code"] == 0 ? responses[1].data : false;
                this.tscharts.push(defts[0]);
                this.tscharts.push(defts[1]);
                let arrayOfPromises = [
                    this.fillTimeSeriesChart(defts[0], moresponses, true, false),
                    this.fillTimeSeriesChart(defts[1], mofeatures, true, false)
                ];
                if (defts.length > 2) {
                    this.tscharts.push(defts[2]);
                }
                Promise.all([arrayOfPromises]);
            })

        return { timeseries: this.timeseries.data, changepoints: this.changpoints };
    }
}

export class PlantPartTimeSeries extends MonitoredObjectTimeSeries {
    constructor(interval, moctrlmodel) {
        super(interval, moctrlmodel);
    }

    async getTimeSeries(source, interval, system, key, onlysignals = false) {
        if (interval) this.interval = interval;
        super.getTimeSeries(source, this.interval);
        let arrayOfPromises = [];
        arrayOfPromises.push(callMe("/blackbox/measurements", "POST", { system: system, sensors: [key], interval: this.interval }));
        arrayOfPromises.push(callMe("/blackbox/stats", "POST", { system: system, sensors: [key], interval: this.interval, onlysignals: onlysignals }));
        arrayOfPromises.push(callMe("/blackbox/changepoints", "POST", { system: system, sensors: [key], interval: this.interval }));
        let myresponse;
        await Promise.all(arrayOfPromises)
            .then((responses) => {
                this.timeseries = responses[0];
                this.statistics = responses[1];
                this.changepoints = responses[2].data;
                myresponse = responses;
            });
        return { "data": myresponse, "ppts": this };
    }


}

export class ComputedSensorTimeSeries extends MonitoredObjectTimeSeries {
    constructor(interval) {
        super(interval, null);
        this.timeseries;
        this.changepoints = {};
    }

    async getTimeSeries(system, sensors, interval = "", id = "") {
        if (interval != "") this.interval = interval;
        let arrayOfPromises = [];
        arrayOfPromises.push(callMe("/blackbox/measurements", "POST", { system: system, sensors: sensors, interval: this.interval }));
        arrayOfPromises.push(callMe("/blackbox/changepoints", "POST", { system: system, sensors: sensors, interval: this.interval }));
        let myresponse;
        await Promise.all(arrayOfPromises)
            .then((responses) => {
                this.timeseries = responses[0];
                this.changepoints = responses[1].data;
                myresponse = responses;
            })
            .finally(() => {
                this.fillTimeSeriesChart(id, sensors, true, true, undefined);
            });

        return myresponse;
    }
}

export class ScatterPlotMatrix {
    constructor(data) {
        this.svg;
        this.data = data;
    }

    async fill(id, resp, feat, sensorsid, select = "resp") {
        let data = this.data;
        // Specify the chart’s dimensions and initialize
        let splommer = $(id);
        let ul = $(sensorsid);
        let selector = $("splom-sensor-select");
        myEvent.attach(selector, "change", () => {
            let save = selector.value;
            this.init(id, sensorsid);
            selector.value = save;
            this.fill(id, resp, feat, sensorsid, selector.value);
        });
        let width = Math.round(splommer.getBoundingClientRect().width * 0.75);
        let height = width;
        let padding = 10;
        let columns = [];
        let dim = {};

        // pimp data with itself
        let i = true;
        for (let d in data) {
            let line = data[d];
            for (let key in line) {
                let item = {};
                item["id"] = key;
                item.value = line[key];
                if (i) {
                    let mykey = item.id.substring(item.id.indexOf("_") + 1, item.id.indexOf("_", item.id.indexOf("_") + 1));
                    if (mykey in SENSORMAPPING) item.species = SENSORMAPPING[mykey]["label"];
                    else 
                        if (mykey.substring(0,1) in SENSORMAPPING) item.species = SENSORMAPPING[mykey.substring(0,1)]["label"];
                        else item.species = SENSORMAPPING[" "]["label"];
                    item["category"] = item.id.substring(item.id.indexOf(".") + 1);
                    switch (select) {
                        case "resp":
                            if (!columns.includes(item.id) && resp.includes(item.id)) columns.push(item.id);
                            break;
                        case "feat":
                            if (!columns.includes(item.id) && feat.includes(item.id)) columns.push(item.id);
                            break;
                        default:
                            if (!columns.includes(item.id)) columns.push(item.id);
                            break;
                    }
                }
                if (Object.keys(dim).includes(item.id)) {
                    dim[item.id]["values"].push(item.value);
                } else {
                    dim[item.id] = {
                        "values": [item.value],
                        "species": item.species,
                        "category": item.category
                    };
                }
            };
            i = false;
        };

        columns.forEach((v) => { createListElementWithLink(ul, v, "plantpart=" + v) });

        let size = (width - (columns.length + 1) * padding) / columns.length + padding;

        // Define the horizontal scales (one for each row).
        let x = columns.map(c => d3.scaleLinear()
            .domain(d3.extent(dim[c].values))
            .rangeRound([padding / 2, size - padding / 2]))

        // Define the companion vertical scales (one for each column).
        let y = x.map(x => x.copy().range([size - padding / 2, padding / 2]));

        // Define the color scale.
        let color = d3.scaleOrdinal()
            .domain(["Level", "Temperature", "Flow", "Pressure"])
            .range(d3.schemeSet2);

        // Define the horizontal axis (it will be applied separately for each column).
        let axisx = d3.axisBottom()
            .ticks(3)
            .tickSize(size * columns.length);
        let xAxis = g => g.selectAll("g").data(x).join("g")
            .attr("transform", (d, i) => `translate(${i * size},0)`)
            .each(function (d) { return d3.select(this).call(axisx.scale(d)); })
            .call(g => g.select(".domain").remove())
            .call(g => g.selectAll(".tick line").attr("stroke", "#ddd"));

        // Define the vertical axis (it will be applied separately for each row).
        let axisy = d3.axisLeft()
            .ticks(5)
            .tickSize(-size * columns.length);
        let yAxis = g => g.selectAll("g").data(y).join("g")
            .attr("transform", (d, i) => `translate(0,${i * size})`)
            .each(function (d) { return d3.select(this).call(axisy.scale(d)); })
            .call(g => g.select(".domain").remove())
            .call(g => g.selectAll(".tick line").attr("stroke", "#ddd"));

        this.svg = d3.create("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [-padding * 3, 0, width + padding * 2, height + padding * 2]);

        this.svg.append("style")
            .text(`circle.hidden { fill: #000; fill-opacity: 1; r: 1px; }`);

        this.svg.append("g")
            .call(xAxis);

        this.svg.append("g")
            .call(yAxis);

        let cell = this.svg.append("g")
            .selectAll("g")
            .data(d3.cross(d3.range(columns.length), d3.range(columns.length)))
            .join("g")
            .attr("transform", ([i, j]) => `translate(${i * size},${j * size})`);

        cell.append("rect")
            .attr("fill", "none")
            .attr("stroke", "#aaa")
            .attr("x", padding / 2 + 0.5)
            .attr("y", padding / 2 + 0.5)
            .attr("width", size - padding)
            .attr("height", size - padding);

        cell.each(function ([i, j]) {
            d3.select(this).selectAll("circle")
                .data(d3.transpose([dim[columns[i]].values, dim[columns[j]].values]))
                .join("circle")
                .attr("cx", d => x[i](d[0]))
                .attr("cy", d => y[j](d[1]))
                .attr("fill", color(dim[columns[j]].species))
                .attr("r", 1.0);
        });
        // Ignore this line if you don't need the brushing behavior.
        //let circle = cell.selectAll("circle")
        //cell.call(brush, circle, svg, { padding, size, x, y, columns });

        this.svg.append("g")
            .style("font", "bold 10px sans-serif")
            .style("pointer-events", "none")
            .selectAll("text")
            .data(columns)
            .join("text")
            .attr("transform", (d, i) => `translate(${i * size},${i * size})`)
            .attr("x", padding)
            .attr("y", padding)
            .attr("dy", ".71em")
            .attr("id", "splom-svg")
            .text(d => d);

        this.svg.property("value", [])
        const element = swatches({ color, marginLeft: 40 });
        // Finally, call `swatches` and append it to the container        
        splommer.appendChild(element);
        splommer.appendChild(this.svg.node());
    }
    init(id, listid) {
        $("splom-sensor-select").value = "resp";
        if (this.svg) this.svg.remove();
        cleanArea(id);
        cleanArea(listid);
    }
    dispose(id, listid) {
        this.init(id, listid);
        if (this.svg) delete this.svg;
        if (this.data) delete this.data;
    }

}