import { gLoginState } from "../mymsal.mjs";
import { $, cleanArea, createElementAndClassList, createsetAttribute, injectEscape } from "../helper.mjs";
import { myEvent, navigate } from "../index.mjs";
import { callMe } from "../mybackend.mjs";
import { MonitoredObjectController, MonitoredObjectView } from "../core/monitoredobject.mjs";
import { Signals } from "../core/signals.mjs";
import { MeasurementTimeSeries } from "../core/timeseries.mjs";

String.prototype.escape = injectEscape;

function adaptDefinition(moctrl) {
    let ultime = cleanArea("MOActions");
    let intervalbtns = [{ "interval": "short", "label": "Short (3 days)" }, { "interval": "middle", "label": "Middle (12 weeks)" }, { "interval": "long", "label": "Long (12 months)" }];
    for (let i = 0; i < intervalbtns.length; i++) {
        let li = createElementAndClassList("li", ["nav-item", "d-inline"]);
        let btn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-primary", "me-2"]);
        btn.type = "button";
        btn.id = intervalbtns[i]["interval"];
        btn.textContent = intervalbtns[i]["label"];
        myEvent.attach(btn, "click", async (ev) => {
            await new MeasurementTimeSeries(intervalbtns[i]["interval"], moctrl.model)
                .getTimeSeries(moctrl.model.id, intervalbtns[i]["interval"], ["resp_ts", "feat_ts"]);
        });
        li.appendChild(btn);
        ultime.appendChild(li);
    }
}

function fixResize(recreate = false) {

    let defitems = []
    for (let v of ["periodTS", "resp_ts", "feat_ts", "metricActive"]) {
        if ($(v) !== null)
            defitems.push($(v));
    }
    for (let v of defitems) {
        try {
            if (recreate) {
                let p = { ...v };
                cleanArea(v.id);
                Plotly.newPlot(v.id, p.data, p.layout, p.config);
            } else {
                Plotly.newPlot(v.id, v.data, v.layout, v.config);
            }
        } catch (e) { console.log(e); }
    }
}

export async function startmostart(scope, system) {
    console.log("startmostart", scope, system);
    let mosystem = Number(system);
    let plant;
    for (let p in gLoginState.authorizedPlants) {
        if (gLoginState.authorizedPlants[p].System == mosystem) {
            plant = gLoginState.authorizedPlants[p];
            break;
        }
    }
    gLoginState.setCurrentPlant(plant.AreaKey);

    let preview = $("preview");
    myEvent.attach(preview, "error", () => {
        preview.setAttribute("src", "images/mos/00_Acetylene_Plant.png");
        $("previewlink").setAttribute("href", "images/mos/00_Acetylene_Plant.png");
    });
    preview.setAttribute("src", "images/mos/" + scope + ".png");
    $("previewlink").setAttribute("href", "images/mos/" + scope + ".png");
    $("scope").innerHTML = scope;
    if (plant.ShPoint !== "") {
        $("sharepoint").href = plant.ShPoint + scope;
    } else {
        $("sharepoint").classList.add("d-none");
    }
    
    createsetAttribute($("datascience"), "data", "mo=" + scope + "," + mosystem);
    myEvent.attach($("datascience"), "click", navigate);

    let moctrl = new MonitoredObjectController(undefined, mosystem);

    await Promise.all([
        callMe(
            "/blackbox/molist", "POST",
            { model: scope, type: "registration", status: "active", system: mosystem }
        ),
        callMe(
            "/blackbox/molist", "POST",
            { model: scope, type: "registration", status: "deployed", system: mosystem }
        ),
        moctrl.loadMonitoredObjectConfig(system + "-config-" + scope + ".json", false)
    ])
        .then((responses) => {
            let registration = responses[0];
            let alternatives = responses[1]
            // _ = responses[2];

            adaptDefinition(moctrl);
            let ts = new MeasurementTimeSeries("short", moctrl.model);
            ts.setMOSystem(mosystem);
            let strategy;
            if (registration["return code"] == 0) {
                strategy = registration.data[0].strategy;
                let active = $("active");
                createsetAttribute(active, "data", "mo=" + scope + "," + mosystem);
                active.innerHTML = String(strategy).escape();
                myEvent.attach(active, "click", navigate);
            }
            if (alternatives["return code"] == 0)
                Object.keys(alternatives.data).forEach((key) => {
                    let v = alternatives.data[key];
                    if (v.monitored_object == scope) {
                        let link = createElementAndClassList("a", ["link-underline-secondary", "d-block"]);
                        link.href = "#";
                        createsetAttribute(link, "data", "mo=" + scope + "," + mosystem);
                        link.innerHTML = String(v.strategy).escape();
                        myEvent.attach(link, "click", navigate);
                        $("alternatives").appendChild(link);
                    }
                });
            Signals._signals = [];
            return [
                new Signals(mosystem, moctrl.model, "mostart", strategy, "all", fixResize),
                MonitoredObjectView.fillMODefinition(moctrl.model, fixResize),
                ts.getTimeSeries(moctrl.model.id, "short", ["resp_ts", "feat_ts"])
            ];
        })
        .then((arrayOfPromises) => {
            Promise.all(arrayOfPromises)
        });
    window.addEventListener("resize", fixResize);
}

export function destroymostart() {
    window.removeEventListener("resize", fixResize);
    let btns = ["short", "middle", "long"];
    btns.forEach((v) => {
        myEvent.remove($(v), "click");
    })

    myEvent.remove($("datascience"), "click");

    for (let v of ["periodTS", "resp_ts", "feat_ts", "metricActive"]) {
        if ($(v)) Plotly.purge(v);
    }
}