import { $, cleanArea, createElementAndClassList, createsetAttribute } from "./helper.mjs";
import { language, navigate } from "./index.mjs";
import { callMe } from "./mybackend.mjs";
import { gLoginState, userjson } from "./mymsal.mjs";

export class Message {
    constructor(timestamp = '', system = 10, category = "", author = "", msg = "", object = "", state = "initial") {
        this.timestamp = timestamp;
        this.timeisostring = new Date(timestamp);
        this.timeisostring.setUTCMinutes(this.timeisostring.getUTCMinutes()+this.timeisostring.getTimezoneOffset()*-1);
        this.timeisostring = this.timeisostring.toISOString();
        this.timestring = new Date(timestamp);
        this.timestring.setUTCMinutes(this.timestring.getUTCMinutes()+this.timestring.getTimezoneOffset()*-1);
        this.timestring = this.timestring.toLocaleString(language);
        this.system = system;
        this.category = category;
        this.author = author;
        this.msg = msg;
        this.object = object;
        this.state = state;
        this.links = [];
    }
}

class MessageFactory {
    static fromJSONObject(object) {
        return new Message(object["timestamp"], object["system_id"], object["category"], object["author"], object["long_text"], object["object"], object["state"]);
    }

    static fromObject(object) {
        return new Message(object["timestamp"], object["system"], object["category"], object["author"], object["msg"], object["links"], object["state"]);
    }
}

export class MessageBroker {
    static _singleton = new MessageBroker();
    static _timeout_id;
    static _msgsubs = "";

    static instance() {
        if (this._singleton == null) {
            this._singleton = new MessageBroker();
        }
        return this._singleton;
    }

    constructor() {
        this.queue = [];
        $("messages").addEventListener("hide.bs.offcanvas", () => {
            this.queue.forEach((item) => {
                if (item.state == "initial") {
                    callMe("/msg/update/state", "POST", {
                        system: item.system,
                        timestamp: item.timestamp,
                        category: item.category,
                        user: userjson.userPrincipalName,
                        state: "viewed"
                    })
                        .then(() => {
                            this.runOnce();
                        });
                }
            })
        });
    }

    setMessageSubScriptions(msgsubs) {
        this._msgsubs = msgsubs;
        return this;
    }

    async getMessages() {
        if (gLoginState.getLoginState()) {
            let authorizedPlants = gLoginState.getAuthorizedPlants();
            let mysystems = [];
            for (let p in authorizedPlants) {
                mysystems.push(authorizedPlants[p]["System"])
            }
            let limit = new Date(new Date() - 48 * 3600000).toISOString();
            let response = await callMe("/msg/read", "POST", { user: userjson.userPrincipalName, timelow: limit });
            if (response) {
                if (response["data"]) {
                    let data = Object.values(response.data);
                    this.queue = [];
                    data.forEach((item, index) => {
                        item = MessageFactory.fromJSONObject(item);
                        if (item.object !== "") {
                            item.links.push("mostart=" + item.object)
                        }
                        let mySys = "";
                        for (let p in authorizedPlants) {
                            if (authorizedPlants[p].System == item.system) {
                                mySys = authorizedPlants[p];
                                break;
                            }
                        }
                        if (Array.isArray(item.links)) {
                            item.links.push("plant=" + mySys.AreaKey);
                        } else {
                            item.links = [].push("plant=" + mySys.AreaKey);
                        }
                        this.queue.push(item);
                    });
                    this.queue.sort((x, y) => {
                        return new Date(y.timestamp) - new Date(x.timestamp);
                    });
                    if (this._msgsubs != "") {
                        let subs = this._msgsubs.split(",");
                        subs = subs.map((s) => { return s.trim(); });
                        let all = ["MO", "Strategy", "Ingest", "Ingest_MO"];
                        let out = all.filter(d => !subs.includes(d));
                        let newqueue = [];
                        this.queue.forEach((msg, ind) => {
                            let decide = true;
                            out.forEach((out) => {
                                if (decide) {
                                    switch (out) {
                                        case "MO":
                                            if (String(msg.category).endsWith("Monitored Object"))
                                                decide = false;
                                            break;
                                        case "Strategy":
                                            if (String(msg.category).endsWith("Strategy"))
                                                decide = false;
                                            break;
                                        case "Ingest":
                                            if (String(msg.category).startsWith("Ingest") && msg.object == "")
                                                decide = false;
                                            break;
                                        case "Ingest_MO":
                                            if (String(msg.category).startsWith("Ingest") && msg.object != "")
                                                decide = false;
                                            break;
                                    }
                                }
                            });
                            if (decide) {
                                newqueue.push(msg);
                            }
                        })
                        this.queue = newqueue;
                    }
                    return this.queue;
                }
                else console.info("no data");
            } else return {}
        }
    }

    fillMessageBoard(msgs) {
        let container = cleanArea("msglist");
        let initial = 0;
        let icon = $("msgicon");
        let badge = null;
        try {
            badge = $("msgbadge");
            icon.classList.remove("active");
            if (icon.contains(badge)) { icon.removeChild(badge); }
        } catch (e) { console.log(e); }
        if (msgs === undefined) {
            container.textContent = "Currently no messages or all messages dismissed";
            return false;
        }
        for (let m of msgs) {
            let card = createElementAndClassList("div", ["card", "mb-3"]);
            let bell;
            let claim = "";
            switch (m.state) {
                case "initial":
                    card.classList.add("text-bg-light");
                    initial = initial + 1;
                    bell = createElementAndClassList("i", ["fa-solid", "fa-bell"]);
                    claim = "new!";
                    break;
                case "viewed":
                    card.classList.add("border-light");
                    bell = createElementAndClassList("i", ["fa-regular", "fa-bell"]);
                    break;
                case "dismissed":
                    card.classList.add("border-light");
                    bell = createElementAndClassList("i", ["fa-regular", "fa-bell-slash"]);
                    break;
                case "deleted":
                    card.classList.add("border-light");
                    bell = createElementAndClassList("i", ["fa-solid", "fa-bell-slash"]);
                    break;
            }
            let cardheader = createElementAndClassList("div", ["card-header", "d-flex", "justify-content-between", "align-items-center"]);
            let subtitle = createElementAndClassList("span", ["card-subtitle", "fw-light"]);
            let spanner = createElementAndClassList("span", "icon-link");
            spanner.appendChild(bell);
            spanner.appendChild(document.createTextNode(claim + " " + m.timestring));
            subtitle.appendChild(spanner);
            let delbtn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-dark"]);
            delbtn.type = "button"
            delbtn.appendChild(createElementAndClassList("i", ["fa-solid", "fa-trash-can", "fa-xs"]));
            createsetAttribute(delbtn, "data", JSON.stringify(m));
            delbtn.addEventListener("click", (ev) => {
                let btn = ev.target.nodeName == "BUTTON" ? ev.srcElement : ev.target.parentElement
                let message = MessageFactory.fromObject(JSON.parse(btn.getAttribute("data")));
                callMe("/msg/update/state", "POST", {
                    system: message.system,
                    imestamp: message.timestamp,
                    category: message.category,
                    user: userjson.userPrincipalName,
                    state: "dismissed"
                })
                    .then((response) => {
                        console.log(response);
                        this.runOnce();
                    });
            });
            cardheader.appendChild(subtitle);
            cardheader.appendChild(delbtn);

            let cardbody = createElementAndClassList("div", "card-body");
            let title = createElementAndClassList("h5", "card-title");
            title.textContent = m.category;
            let cardtext = createElementAndClassList("p", "card-text");
            cardtext.textContent = m.msg;
            cardbody.appendChild(title)
            cardbody.appendChild(cardtext);
            for (let link of m.links) {
                let alink = createElementAndClassList("a", "card-link");
                alink.href = "#";
                createsetAttribute(alink, "data", link + "," + m.system);
                alink.textContent = String(link).split("=")[1];
                alink.addEventListener("click", navigate);
                cardbody.appendChild(alink);
            }

            let cardfooter = createElementAndClassList("div", "card-footer");
            cardfooter.textContent = m.author;

            card.appendChild(cardheader);
            card.appendChild(cardbody);
            card.appendChild(cardfooter);
            container.appendChild(card);
        }
        if (badge == null) {
            badge = createElementAndClassList("span", ["badge", "text-bg-secondary"]);
            badge.id = "msgbadge";
        }
        if (initial > 0) {
            icon.classList.add("active");
            badge.textContent = initial;
            icon.appendChild(badge);
        } else {
            icon.classList.remove("active");
            if (icon.contains(badge)) { icon.removeChild(badge); }
        }
    }

    runOnce(conti=false) {
        this.getMessages()
            .then((msgs) => {
                this.fillMessageBoard(msgs);
            })
            .finally(()=>{
                if (conti) {
                    this.run();
                }
            });
    }

    run() {
        MessageBroker._timeout_id = setTimeout(() => {
            this.runOnce(true);
        }, 35000);
    }
}