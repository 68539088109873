import { myEvent, navigate, SYSTEMDATA } from "../index.mjs";
import { $, $T, cleanArea, createElementAndClassList, createsetAttribute } from "../helper.mjs";
import { gLoginState } from "../mymsal.mjs";
import { callMe } from "../mybackend.mjs";

function fillSections(data) {
    $("plantname").textContent = data[0].parent;

    let accordion = cleanArea("accordionPlantSections");

    for (var linenumber in data) {
        let d = data[linenumber];
        let accitem = createElementAndClassList("div", "accordion-item");
        accitem.id = "accitem-" + d.obj_id;
        let header = createElementAndClassList("h4", "accordion-header");

        let buttonnav = createElementAndClassList("button", "accordion-button");
        buttonnav.type = "button";
        let tempid = "panel-" + d.obj_id;
        buttonnav.id = "button-" + d.obj_id;
        createsetAttribute(buttonnav, "data-bs-toggle", "collapse");
        createsetAttribute(buttonnav, "data-bs-target", "#" + tempid);
        createsetAttribute(buttonnav, "aria-expanded", "true");
        createsetAttribute(buttonnav, "aria-controls", tempid);
        let puzzle = createElementAndClassList("i", ["fa-solid", "fa-cheese"]);
        let title = document.createElement("span");
        title.appendChild($T("\xa0" + d.obj_id + "\xa0-\xa0" + d.objname));
        buttonnav.appendChild(puzzle);
        buttonnav.appendChild(title);

        header.appendChild(buttonnav);
        accitem.appendChild(header);

        let panelItem = createElementAndClassList("div", ["accordion-collapse", "collapse", "show"]);
        panelItem.id = tempid;
        let accBody = createElementAndClassList("div", "accordion-body");
        let bodyRow = createElementAndClassList("div", ["row", "row-cols-1", "row-cols-md-2", "row-cols-lg-3", "row-cols-xl-4", "row-cols-xxl-5", "g-4"]);
        bodyRow.id = "content-" + d.obj_id;
        accBody.appendChild(bodyRow);

        panelItem.appendChild(accBody);
        accitem.appendChild(panelItem);

        accordion.appendChild(accitem);
    }
}

function fillComputedSensors(sensors, assets) {
    Object.keys(sensors).forEach(s => {
        let sensor = sensors[s];
        let name = String(sensor.short_name).escape();
        let assetString = name.substring(name.indexOf("_", 4)+1, name.indexOf("_", 7)).escape();
        let fits = assets.filter((v) => {
            if (String(v.obj_id).startsWith(assetString)) return v
        });
        if (fits.length == 0) {
            assetString = assetString.slice(0, -1);
            fits = assets.filter((v) => {
                if (String(v.obj_id).startsWith(assetString)) return v
            });
        }
        let parent = "";
        if (fits.length == 0) parent = $("content-" + "Other");
        else parent = $("content-" + fits[0].parent);

        let card = createElementAndClassList("div", ["card"]);
        let cardBody = createElementAndClassList("div", ["card-body", "d-flex", "justify-content-between"]);
        cardBody.id = name;
        let cardTitle = createElementAndClassList("h5", ["card-title", "icon-link"]);
        let link = document.createElement("a");
        link.href = "#";
        createsetAttribute(link, "data", "cs=" + name + "," + gLoginState.getCurrentPlant().System);
        link.append(
            $T(name),
        );
        myEvent.attach(link, "click", navigate);
        cardTitle.appendChild(link);
        cardBody.appendChild(cardTitle);
        if (fits.length !== 0) {
            let icon = createElementAndClassList("i", ["fa-solid", "fa-shapes"]);
            icon.id = "icon-" + name;
            if (!$("icon-" + name)) cardBody.appendChild(icon);
        }
        card.appendChild(cardBody);
        parent.appendChild(card);
    });
}

function getFilteredPlantData(plant, filter) {
    var result = SYSTEMDATA;
    var data = [];
    for (let i = 0; i < result.length; i++) {
        let line = result[i];
        if (line["typeidname"] !== filter) {
            continue;
        }
        if (line["System"] == plant) {
            data.push(line);
        }
    }
    return data;
}

export async function getComputedSensors(system) {
    let result = {};
    await Promise.all([callMe("/blackbox/info/sensors", "POST", {
        computed: true, system: system
    })])
        .then((responses) => {
            result = responses[0]["data"];
        });
    
    return result; 
}

export async function startcompsens(scope) {
    console.log("startcompsens", scope);
    gLoginState.setCurrentPlant(scope);
    let plant = gLoginState.getCurrentPlant();
    let system = getFilteredPlantData(plant.AreaKey, "Section");
    fillSections(system);
    let assets = getFilteredPlantData(plant.AreaKey, "Equipment");
    let compSens = await getComputedSensors(plant.System);
    fillComputedSensors(compSens, assets);

    createsetAttribute($("g_assets"), "data", "asset="+plant.AreaKey);
    myEvent.attach($("g_assets"), "click", navigate);
    createsetAttribute($("g_plant"), "data", "plant="+plant.AreaKey);
    myEvent.attach($("g_plant"), "click", navigate);

    Promise.all([
        callMe("/blackbox/molist", "POST", { model: "", type: "definition", state: null, system: plant.System }),
    ])
        .then((responses) => {
            let moList = responses[0]["data"];
            Object.keys(compSens).forEach((id) =>{
                let sensor = compSens[id];
                let name = String(sensor.short_name).escape();
                let fit = [];
                Object.keys(moList).forEach((id)=>{
                    let mo = moList[id];
                    let decide = false;
                    if ("id" in mo) {
                        if (Object.keys(mo.features).includes(name)) decide = true;
                        if (Object.keys(mo.responses).includes(name)) decide = true;
                        if (decide) fit.push(mo);
                    }
                });
                let parent = "";
                if (fit.length !== 0) {
                    parent = $(name);
                    if (parent !== "") {
                        let icon = createElementAndClassList("i", ["fa-solid", "fa-sitemap"]);
                        icon.id = "icon-mo-" + name;
                        if (!$("icon-mo-" + name)) parent.appendChild(icon);
                    }
                }
                
            })
            Object.keys(moList).forEach((id)=>{
                let mo = moList[id];
                if ("type" in mo && mo.type == "monitored object") {
                    let idx = String(mo.id).indexOf("_");
                    let short = "";
                    if (idx > 0) short = mo.id.substring(0, idx).escape();
                    else short = String(mo.id).escape();
                    let asset = $(short);
                    if (asset) {
                        let icon = createElementAndClassList("i", ["fa-solid", "fa-sitemap"]);
                        icon.id = "icon-" + short;
                        if (!$("icon-" + short)) asset.appendChild(icon);
                    }
                }
            });
        })
}

export function destroycompsens() {

}