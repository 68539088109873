import { myEvent, navigate, SYSTEMDATA } from "../index.mjs";
import { $, $E, $T, cleanArea, createElementAndClassList, createsetAttribute } from "../helper.mjs";
import { callMe } from "../mybackend.mjs";
import { gLoginState } from "../mymsal.mjs";
import { MonitoredObjectController, MonitoredObjectView } from "../core/monitoredobject.mjs";

let images = {
    "initial": ["fa-regular", "fa-circle"],
    "trained": ["fa-solid", "fa-circle-half-stroke"],
    "tested": ["fa-solid", "fa-circle"],
    "deployed": ["fa-solid", "fa-circle-pause"],
    "active": ["fa-solid", "fa-circle-play"]
};

function fillSections(data) {
    $("plantname").textContent = data[0].parent;

    let accordion = cleanArea("accordionPlantSections");

    for (var linenumber in data) {
        let d = data[linenumber];
        let accitem = createElementAndClassList("div", "accordion-item");
        accitem.id = "accitem-" + d.obj_id;
        let header = createElementAndClassList("h4", "accordion-header");

        let buttonnav = createElementAndClassList("button", "accordion-button");
        buttonnav.type = "button";
        let tempid = "panel-" + d.obj_id;
        buttonnav.id = "button-" + d.obj_id;
        createsetAttribute(buttonnav, "data-bs-toggle", "collapse");
        createsetAttribute(buttonnav, "data-bs-target", "#" + tempid);
        createsetAttribute(buttonnav, "aria-expanded", "false");
        createsetAttribute(buttonnav, "aria-controls", tempid);
        let puzzle = createElementAndClassList("i", ["fa-solid", "fa-cheese"]);
        let title = $E("span");
        title.appendChild($T("\xa0" + d.obj_id + "\xa0-\xa0" + d.objname));
        buttonnav.appendChild(puzzle);
        buttonnav.appendChild(title);

        header.appendChild(buttonnav);
        accitem.appendChild(header);

        let panelitem = createElementAndClassList("div", ["accordion-collapse", "collapse"]);
        panelitem.id = tempid;
        let accbody = createElementAndClassList("div", "accordion-body");
        accbody.textContent = "No monitored objects found for active filter settings.";
        accbody.id = "content-" + d.obj_id;

        panelitem.appendChild(accbody);
        accitem.appendChild(panelitem);

        accordion.appendChild(accitem);
    }
}

function fillActiveModels(system, data, type = "registration") {
    let sections = [];
    for (var linenumber in system) {
        let s = system[linenumber];
        sections.push(s.obj_id);
    }
    let myData = {};
    for (var ln in data.data) {
        let d = data.data[ln];
        if (type == "definition") {
            if (d.hasOwnProperty("type") && d.type == "monitored object") {
                myData[ln] = d;
            }
        }
        else {
            myData = structuredClone(data.data);
        }
    }
    for (var j = 0; j < sections.length; j++) {
        let s = sections[j];
        let models = [];
        for (var ln in myData) {
            let d = myData[ln];
            let idx;
            let start = s.substring(0, 2) * 1;
            let end = j + 1 < sections.length ? sections[j + 1].substring(0, 2) * 1 : 99;
            let numb;
            if (type == "registration") {
                idx = d.monitored_object.indexOf(s.substring(2, 4));
                numb = isNaN(Number(d.monitored_object.substring(1, 3))) ?
                    isNaN(Number(d.monitored_object.substring(1, 2))) ?
                        999 :
                        Number(d.monitored_object.substring(1, 2)) :
                    Number(d.monitored_object.substring(1, 3));
            } else {
                idx = d.id.indexOf(s.substring(2, 4));
                numb = isNaN(Number(d.id.substring(1, 3))) ?
                    isNaN(Number(d.id.substring(1, 2))) ?
                        999 :
                        Number(d.id.substring(1, 2)) :
                    Number(d.id.substring(1, 3));
            }

            if ((idx >= 0 && idx < 3) || (numb >= start && numb < end)) models.push(d);
        }
        let accbody = cleanArea("content-" + s);
        if (models.length > 0) {
            let divrowcol = createElementAndClassList("div", ["row", "row-cols-1", "row-cols-md-2", "row-cols-lg-3", "row-cols-xl-4", "g-4"]);
            for (var m = 0; m < models.length; m++) {
                let divcol = createElementAndClassList("div", "col");
                let divcard;
                //if (m == 0) divcard = createElementAndClassList("div", ["card", "h-100", "text-bg-warning"]);
                //else 
                divcard = createElementAndClassList("div", ["card", "h-100"]);
                divcard.id = type == "registration" ? models[m].monitored_object + "-" + models[m].strategy : models[m].id;
                let divbody = createElementAndClassList("div", ["card-body", "d-flex", "justify-content-between"]);
                divbody.id = type == "registration" ? models[m].monitored_object + "-" + models[m].strategy + "-body" : models[m].id + "-body";
                let head5 = createElementAndClassList("h5", ["card-title", "icon-link"]);
                head5.id = type == "registration" ? models[m].monitored_object + "-" + models[m].strategy + "-title" : models[m].id + "-title";
                let link = $E("a");
                link.href = "#";
                if (type == "registration") {
                    createsetAttribute(link, "data", "mo=" + models[m].monitored_object + "," + gLoginState.currentPlant.System);
                    link.textContent = models[m].monitored_object + "-" + models[m].strategy;
                } else {
                    createsetAttribute(link, "data", "mostart=" + models[m].id + "," + gLoginState.currentPlant.System);
                    link.textContent = models[m].name;
                }
                link.addEventListener("click", navigate);
                head5.appendChild(link);
                divbody.appendChild(head5);

                let right = $E("div");
                let rights = [{ "icon": "fa-database", "short": "-dq", "title": "Data Quality Signals" }];

                if (type == "registration") {
                    rights.push({ "icon": "fa-chess-rook", "short": "-md", "title": "Model Signals" });
                }
                rights.forEach((elem) => {
                    let div = createElementAndClassList("div", "minwidth4em");
                    let img = createElementAndClassList("i", ["fa-solid", elem.icon]);
                    img.title = elem.title;
                    let cnt = $E("span");
                    cnt.id = type == "registration" ?
                        models[m].monitored_object + "-" + models[m].strategy + elem.short :
                        models[m].id + elem.short;
                    elem.short == "-md" ? cnt.appendChild($T("0")) : cnt.appendChild($T("?"));
                    div.append(img, $T(" : "), cnt);
                    right.appendChild(div);
                });
                divbody.appendChild(right);
                divcard.appendChild(divbody);
                divcol.appendChild(divcard);
                divrowcol.appendChild(divcol);
            }
            if (divrowcol.firstElementChild) {
                accbody.parentElement.classList.add("show");
                accbody.appendChild(divrowcol);
                let buttonnav = $("button-" + s);
                buttonnav.appendChild($T("\xa0(\xa0"));
                let chess;
                if (type == "registration") chess = createElementAndClassList("i", ["fa-solid", "fa-chess"]);
                else chess = createElementAndClassList("i", ["fa-solid", "fa-sitemap"]);
                createsetAttribute(chess, "data-bs-toggle", "tooltip")
                createsetAttribute(chess, "data-bs-title", models.length + "\xa0Models");
                buttonnav.appendChild(chess);
                buttonnav.appendChild($T("\xa0" + models.length + "\xa0)"));
            }
        } else {
            let accitem = $("accitem-" + s);
            accitem.classList.add("d-none");
            accbody.textContent = "No monitored objects found for active filter settings";
        }
    }
}

function getPlantSystemData(parent) {
    var result = SYSTEMDATA;
    var data = [];
    for (let i = 0; i < result.length; i++) {
        let line = result[i];
        if (line["typeidname"] !== "Section") {
            continue;
        }
        if (line["parent"] == parent) {
            data.push(line);
        }
    }
    return data;
}

export async function startplant(scope) {
    console.log("startplant", scope);
    gLoginState.setCurrentPlant(scope);
    let plant = gLoginState.getCurrentPlant();
    let system = getPlantSystemData(plant.AreaKey);
    fillSections(system);
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

    
    createsetAttribute($("g_assets"), "data", "asset="+plant.AreaKey);
    myEvent.attach($("g_assets"), "click", navigate);
    createsetAttribute($("g_compsens"), "data", "compsens="+plant.AreaKey);
    myEvent.attach($("g_compsens"), "click", navigate);

    let filters = ["f_showmo", "f_showmodels", "f_showactivemodels"];
    filters.forEach(async (element) => {
        myEvent.attach($(element), "click", (ev) => {
            let eventid = ev.currentTarget.id;
            let state = eventid == "f_showactivemodels" ? "active" : "";
            let type = eventid == "f_showmo" ? "definition" : "registration";
            let myMoCtrl;
            filters.forEach((f) => $(f).classList.remove("active"));
            $(eventid).classList.add("active");
            let popover = bootstrap.Popover.getInstance("#" + element);
            popover.show();
            Promise.all([
                callMe("/blackbox/molist", "POST", { model: "", type: "definition", status: state, system: plant.System }),
                callMe("/blackbox/molist", "POST", { model: "", type: "registration", status: state, system: plant.System }),
                callMe("/blob/container/contents", "POST", { account: gLoginState.currentEnv.modelstorage })
            ])
                .then((responses) => {
                    let [definition, registration, blobs] = [responses[0], responses[1], responses[2]];
                    if (system !== "") {
                        myMoCtrl = new MonitoredObjectController(blobs, plant.System);
                        myMoCtrl.registerListEntries(SYSTEMDATA);
                        fillSections(system);
                        if (type == "definition")
                            if (definition !== "") fillActiveModels(system, definition, "definition");
                            else console.warn("no def!!!");
                        else
                            if (registration !== "") fillActiveModels(system, registration, "registration");
                            else console.warn("no reg!!!");
                    };
                    if (myMoCtrl.cfgBlobs !== "") MonitoredObjectView.filterBlobsToPeriods(myMoCtrl.cfgBlobs);
                    return { definition: definition, registration: registration, type: type };
                })
                .then(async (next) => {
                    let [signals, dqmosignals, dqsignals]  = [[], [], []];
                    let [models, dqmodels]  = [undefined, undefined];
                    if (next.type == "definition") {
                        models = next.definition.data;
                        for (let m in models) {
                            let model = models[m];
                            if (model.hasOwnProperty("features")) {
                                dqmosignals.push(callMe("/blackbox/stats", "POST", {
                                    system: plant.System,
                                    sensors: Object.keys(model.features).concat(Object.keys(model.responses)),
                                    interval: "short",
                                    onlysignals: true
                                })
                                    .then((signal) => {
                                        let cnt = cleanArea(model.id + "-dq");
                                        let [dqsum, count] = [0, 0];
                                        if (Object.hasOwn(signal, "data")) {
                                            Object.keys(signal.data.signals).forEach((key) => {
                                                if (signal.data.signals[key].hasOwnProperty("signal")) {
                                                    dqsum += signal.data.signals[key]["signal"];
                                                    count += signal.data.signals[key]["signal"];
                                                }
                                                else count += 1;
                                            });
                                            if (dqsum > 2) $(model.id).classList.add("bg-info");
                                            else if (dqsum > 0) $(model.id).classList.add("bg-info-subtle");
                                            cnt.appendChild($T(dqsum + "/" + count));
                                            if (signal["return code"] > 0) {
                                                let bolt = createElementAndClassList("i", ["fa-solid", "fa-bolt"]);
                                                createsetAttribute(bolt, "title", signal["error"])
                                                cnt.appendChild(bolt);
                                            }
                                        }
                                        else cnt.appendChild(createElementAndClassList("i", ["fa-solid", "fa-bolt"]));
                                    })
                                );
                            }
                        }
                    } else {
                        models = next.registration.data;
                        dqmodels = next.definition.data;
                        for (let m in models) {
                            let model = models[m];
                            model.monitored_object = String(model.monitored_object).escape();
                            model.strategy = String(model.strategy).escape();
                            let img = createElementAndClassList("i", images[model.object_state]);
                            let title = $(model.monitored_object + "-" + model.strategy + "-title")
                            title.insertBefore(img, title.firstChild);
                            if (model.object_state == "active" || model.object_state == "deployed") {
                                signals.push(
                                    callMe("/blackbox/scoring", "POST", {
                                        model: model.monitored_object, strategy: model.strategy, system: plant.System, interval: "short"
                                    })
                                        .then((signal) => { 
                                            let cnt = cleanArea(model.monitored_object+ "-" + model.strategy + "-md");
                                            if (Object.hasOwn(signal, "data")) {
                                                if (signal.data.signal > 0) {
                                                    let stratcard = $(signal.details.model + "-" + signal.details.strategy);
                                                    if (stratcard.classList.contains("bg-info")) stratcard.classList.remove("bg-info");
                                                    if (stratcard.classList.contains("bg-info-subtle")) stratcard.classList.remove("bg-info-subtle");
                                                    stratcard.classList.add("bg-warning");

                                                    cnt.appendChild($T(String(signal.data.signal)));
                                                }
                                                if (signal["return code"] > 0) {
                                                    let bolt = createElementAndClassList("i", ["fa-solid", "fa-bolt"]);
                                                    createsetAttribute(bolt, "title", signal["error"])
                                                    cnt.appendChild(bolt);
                                                }
                                            }
                                            else cnt.appendChild(createElementAndClassList("i", ["fa-solid", "fa-bolt"]));
                                        }));
                            }
                            let dqmodel = Object.values(dqmodels).find((v) => v.id == model.monitored_object);
                            if (dqmodel)
                                if (dqmodel.hasOwnProperty("features")) {
 
                                    dqsignals.push(callMe("/blackbox/stats", "POST", {
                                        system: plant.System,
                                        sensors: Object.keys(dqmodel.features).concat(Object.keys(dqmodel.responses)),
                                        interval: "short",
                                        onlysignals: true
                                    })
                                        .then((signal) => {
                                            let cnt = cleanArea(model.monitored_object + "-" + model.strategy + "-dq");
                                            let [dqsum, count] = [0, 0];
                                            if (Object.hasOwn(signal, "data")) {
                                                Object.keys(signal.data.signals).forEach((key) => {
                                                    if (signal.data.signals[key].hasOwnProperty("signal")) {
                                                        dqsum += signal.data.signals[key]["signal"];
                                                        count += signal.data.signals[key]["signal"];
                                                    }
                                                    else count += 1;
                                                });
                                                if (!$(model.monitored_object + "-" + model.strategy).classList.contains("bg-warning"))
                                                    if (dqsum > 2) $(model.monitored_object + "-" + model.strategy).classList.add("bg-info");
                                                    else if (dqsum > 0) $(model.monitored_object + "-" + model.strategy).classList.add("bg-info-subtle");

                                                cnt.appendChild($T(dqsum + "/" + count));
                                                if (signal["return code"] > 0) {
                                                    let bolt = createElementAndClassList("i", ["fa-solid", "fa-bolt"]);
                                                    createsetAttribute(bolt, "title", signal["error"])
                                                    cnt.appendChild(bolt);
                                                }
                                            }
                                            else cnt.appendChild(createElementAndClassList("i", ["fa-solid", "fa-bolt"]));
                                        })
                                    );
                                }
                        };
                    }
                    try {
                        await Promise.all(dqmosignals.concat(dqsignals).concat(signals));
                    } catch (error) {
                        console.warn("potential site change, async data from previous step cannot arrive");
                        console.info(error);
                    }
                })
                .finally(() => {
                    popover.hide();
                });
        });
    });
    $("f_showmo").click();
}

export function destroyplant() {
    ["f_showmo", "f_showmodels", "f_showactivemodels"].forEach(async (element) => {
        myEvent.remove($(element), "click");
    });
}
