import { startwelcome } from "./fragments/welcome.mjs";
import { loadFragment, navigate, myEvent, initSystemData, resetSystemData, addComputedSensors2System } from "./index.mjs";
import { callMe } from "./mybackend.mjs";
import { $, $T, checkDebug, cleanArea, createComplexLink, createElementAndClassList, createsetAttribute, Spinner } from "./helper.mjs";
import { MessageBroker } from "./messages.mjs";
import { GROUP2PLANT, PLANTS } from "./core/plant.mjs";

let domain = window.location.hostname == "localhost" ? "localhost:8080" : window.location.hostname;

export let msalInstance;
export let homeAccount;
export let userjson;
export let usersettings;
export let usergroups;
export let admingroups;
export let isAdmin;
export let currentPlant;

export class BackendMsalCredential {

    async getToken(scopes, msal) {
        var client = msal;
        if (typeof (client) == "undefined") client = msalInstance;
        var request = {
            scopes: Array.isArray(scopes) ? scopes : [scopes]
        };
        let response = "";
        response = await client.acquireTokenSilent(request);
        this.token = response == "" ? "" : response.idToken;
        return {
            token: this.token,
            expiresOnTimestamp: response == "" ? "" : response.expiresOn.getTime()
        };
    }

    async refreshToken(scopes, msal) {
        var client = msal;
        if (typeof (client) == "undefined") client = msalInstance;
        var request = {
            scopes: Array.isArray(scopes) ? scopes : [scopes],
            forceRefresh: true,
            refreshTokenExpirationOffsetSeconds: 7200 // 2 hours * 60 minutes * 60 seconds = 7200 seconds
        };
        let response = "";
        response = await client.acquireTokenSilent(request);
        this.token = response == "" ? "" : response.idToken;
        return {
            token: this.token,
            expiresOnTimestamp: response == "" ? "" : response.expiresOn.getTime()
        };
    }
}

class Backend {
    constructor() {
        this.connection = new BackendMsalCredential();
    }
    async getHeader(token = true, scopes = "") {
        var headers = new Headers();
        if (token) {
            if (!this.tiktok) this.tiktok = await this.connection.refreshToken(scopes, msalInstance);
            else if (this.tiktok.expiresOnTimestamp > Date.now() - 5400) this.tiktok = await this.connection.refreshToken(scopes, msalInstance);
            headers.append("Authorization", "Bearer " + this.tiktok.token);
        }
        return headers;
    }
}

export let Server = new Backend();

class loginState {

    constructor() {
        this.loginstate = false;
        this.authorizedPlants = {};
        this.currentPlant = {};
        this.currentEnv = {};
    }
    setLoginState(state, users, admins) {
        this.loginstate = state;
        if (this.loginstate) {
            this.checkPlantAuthorization(users);
            this.checkPlantAuthorization(admins);

        } else this.authorizedPlants = {};
    }
    getLoginState() {
        return this.loginstate;
    }
    getAuthorizedPlants() {
        return this.authorizedPlants;
    }

    checkPlantAuthorization(groups) {
        for (let g in groups) {
            if (Object.keys(GROUP2PLANT).includes(groups[g]))
                if (!Object.keys(this.authorizedPlants).includes(PLANTS[GROUP2PLANT[groups[g]]]))
                    this.authorizedPlants[GROUP2PLANT[groups[g]]] = PLANTS[GROUP2PLANT[groups[g]]];
        }
    }

    getCurrentPlant() {
        return this.currentPlant;
    }

    setCurrentPlant(key) {
        try {
            this.currentPlant = this.authorizedPlants[key];
        } catch (e) {
            console.warn(key, e);
            this.currentPlant = {};
        }
    }
}

export let gLoginState = new loginState();

const msalConfig = {
    auth: {
        clientId: '7280d0b1-c0af-451d-b7c4-8909d18ba667',
        authority: "https://login.microsoftonline.com/ecaa386b-c8df-4ce0-ad01-740cbdb5ba55",
        knownAuthorities: [],
        redirectUri: window.location.protocol + "//" + domain,
        mainWindowRedirectUri: window.location.protocol + "//" + window.location.hostname,
        navigateToLoginRequestUrl: true
    }
}

export async function login() {
    var loginRequest = {
        scopes: ['user.read', 'GroupMember.Read.All']
    };

    $("loginq").append(
        document.createElement("br"),
        new Spinner().getLoadingElement()
    );

    createsetAttribute($("loginbw"), "disabled", true);

    try {
        msalInstance = new msal.PublicClientApplication(msalConfig);
        await msalInstance.initialize();
        let loginResponse = await msalInstance.loginPopup(loginRequest);
        homeAccount = loginResponse.account;
        var tokenRequest = {
            scopes: ['User.Read'],
            account: homeAccount
        };
        let tokenResponse = await msalInstance.acquireTokenSilent(tokenRequest);
        msalInstance.setActiveAccount(tokenResponse.account);

        let userresponse = await fetch("https://graph.microsoft.com/v1.0/me", {
            headers: {
                'Authorization': `Bearer ${tokenResponse.accessToken}`
            }
        });
        userjson = await userresponse.json();
        if (checkDebug()) console.log(userjson);
        isAdmin = false;
        usergroups = [];
        admingroups = [];

        let groupsresponse = await fetch("https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group", {
            headers: {
                'Authorization': `Bearer ${tokenResponse.accessToken}`
            }
        });
        let groups = await groupsresponse.json();
        let page = true;

        while (page) {
            for (let g in groups.value) {
                let dName = groups.value[g].displayName;
                if (dName.indexOf("EDLPD") == 0 && dName.indexOf("Viewer") > 0) usergroups.push(dName);
                if (dName.indexOf("EDLPD") == 0 && dName.indexOf("Admin") > 0) admingroups.push(dName);
                if (dName.indexOf("SA-FUN-SA-TGU") == 0) usergroups.push(dName);
                if (dName.indexOf("Production AI in SA") == 0) admingroups.push(dName);
                if (dName == "PMBB_DEVELOPER" || dName == "PMBB_DEVELOPER_ADMINISTRATION") {
                    isAdmin = true;
                    usergroups.push("SA-FUN-SA-TGU");
                    admingroups.push("Production AI in SA");
                }
            }
            if (groups["@odata.nextLink"]) {
                groupsresponse = await fetch(groups["@odata.nextLink"], { headers: { 'Authorization': `Bearer ${tokenResponse.accessToken}` } });
                groups = await groupsresponse.json();
            } else page = false;
        }
        usergroups = usergroups.sort();
        admingroups = admingroups.sort();
    } catch (err) {
        console.log(err);
        $("loginq").append(
            document.createElement("br"),
            $T(err.message)
        );
    }

    gLoginState.setLoginState(true, usergroups, admingroups);
    await callMe("/config/storagenames", "GET", null)
        .then((myenv) => {
            if (checkDebug()) console.log(myenv);
            gLoginState.currentEnv = { modelstorage: "icmpwe" + myenv.env + "modelsstorage", ingeststorage: "icmpwe" + myenv.env + "ingeststorage" }
        });

    await callMe("/blackbox/usersettings/select", "POST", { "user": userjson.userPrincipalName })
        .then((result) => {
            if ("data" in result) {
                usersettings = result.data[0];
            } else {
                usersettings = {
                    "default_object_visual": "box",
                    "msg_subscriptions": "MO,Strategy,Ingest,Ingest_MO",
                    "start": "welcome"
                };
            }
        })

    $("loginb").classList.add("disabled");
    $("logoutb").classList.remove("disabled");
    $("user").classList.remove("disabled");
    $("usersettings").classList.remove("disabled");
    $("searchglobal").removeAttribute("disabled");

    // make multiplant enablement here - check with authorization
    let myplants = gLoginState.getAuthorizedPlants();
    let plantMenu = $("menu_plant");
    for (let p in myplants) {
        if (myplants[p].MenuId !== undefined) {
            let li = document.createElement("li");
            let link = createComplexLink("dropdown-item", undefined, $T(myplants[p].Menu + " " + "\u00BB"), "plant=" + myplants[p].AreaKey);
            li.appendChild(link);
            let subUl = createElementAndClassList("ul", ["dropdown-menu", "dropdown-submenu"]);
            [
                {"key": "plant", "name": "Monitored Objects", "icon": ["fa-solid", "fa-sitemap"]}, 
                {"key": "asset", "name": "Asset", "icon": ["fa-solid", "fa-shapes"]}, 
                {"key": "compsens", "name": "Computed Sensors", "icon": ["fa-solid", "fa-square-root-variable"]}
            ].forEach((b)=>{
                let li = document.createElement("li");
                let icon = createElementAndClassList("i", b["icon"]);
                let link = createComplexLink(["dropdown-item", "icon-link"], icon, $T(b["name"]), b["key"]+"="+myplants[p].AreaKey);
                li.appendChild(link);
                subUl.appendChild(li);
            });
            li.appendChild(subUl);
            plantMenu.appendChild(li);
        }
    }
    let lihr = document.createElement("li");
    lihr.appendChild(createElementAndClassList("hr", "dropdown-divider"));
    plantMenu.appendChild(lihr);
    //<li><a class="dropdown-item disabled" href="#" id="multiplant">All Plants Overview</a></li>
    let limulti = document.createElement("li");
    let link = createElementAndClassList("a", "dropdown-item");
    link.href = "#";
    link.id = "multiplant"
    link.textContent = "All Plants Overview";
    myEvent.attach(link, "click", loadFragment);
    limulti.appendChild(link);
    plantMenu.appendChild(limulti);

    let systems = [];
    for (let p in myplants) {
        try {
            if (myplants[p].Path !== undefined) {
                if (!systems.includes(myplants[p].Path)) systems.push(myplants[p]);
            }
        } catch (e) { console.warn(myplants[p].AreaKey + "\n" + e); }
    }
    let _ = await initSystemData(systems);
    systems.forEach(async (sys) => {
        await addComputedSensors2System(sys.System, sys.AreaKey);
    });

    if (isAdmin) {
        $("a_dataing").classList.remove("disabled");
        $("a_model").classList.remove("disabled");
    }

    // Done - now start messaging
    MessageBroker.instance()
        .setMessageSubScriptions(usersettings["msg_subscriptions"])
        .runOnce(true);

    // ... and welcome user
    if (usersettings.start) {
        switch (usersettings.start) {
            case "welcome":
                startwelcome();
                break;
            case "plant":
                navigate(null, "plant=" + Object.values(myplants)[0].AreaKey);
                break;
            case "multiplant":
                loadFragment("multiplant", "", "");
                break;
            default:
                startwelcome();
                break;
        }
    }

}

export async function logout() {
    let myplants = gLoginState.getAuthorizedPlants();
    gLoginState.setLoginState(false);

    let _ = cleanArea("content");

    let home = $("welcome");
    myEvent.remove(home, "click");
    home.classList.add("disabled");


    if (isAdmin) {
        $("a_dataing").classList.add("disabled");
        $("a_model").classList.add("disabled");
    }
    isAdmin = false;

    createsetAttribute($("searchglobal"), "disabled", true);

    for (let p in myplants) {
        if (myplants[p].MenuId !== undefined) {
            myEvent.remove($(myplants[p].MenuId), "click");
        }
    }

    myEvent.remove($("multiplant"), "click");
    let menuplant = $("menu_plant");
    while (menuplant.hasChildNodes()) {
        menuplant.removeChild(menuplant.firstChild);
    }

    $("loginb").classList.remove("invisible");
    $("logoutb").classList.add("invisible");
    $("user").classList.add("disabled");
    $("usersettings").classList.add("disabled");

    resetSystemData();

    await msalInstance.logoutPopup({
        account: homeAccount,
        mainWindowRedirectUri: window.location.protocol + "//" + window.location.hostname
    });
}

$("loginb").addEventListener("click", login);
$("logoutb").addEventListener("click", logout);