import { $, checkDebug } from "../helper.mjs"
import { callMe } from "../mybackend.mjs";
import { gLoginState } from "../mymsal.mjs";
import { PlantPartTimeSeries } from "../core/timeseries.mjs";
import { PlantObject } from "../core/plant.mjs";

let plantPartTS = new PlantPartTimeSeries("short");

async function process(arrayOfPromises, myself) {
    let responses = await Promise.all(arrayOfPromises);
    let arrayOfPromises2 = [];
    let me;
    if (myself == undefined)
        me = PlantObject.instance(
                $("plantpartname").textContent, 
                $("plantpartkey").value, 
                process
        );
    else
        me = myself;

    arrayOfPromises2.push(me.showMOs(responses[0]["data"]));
    let stats;
    if (me.type == "Sensor") {
        if (responses[0].data.length == 3) {
            //this.timeseries = responses[0].data[0];
            stats = responses[0].data[1];
            //this.changepoints = responses[0].data[2].data;
            arrayOfPromises2.push(responses[0].ppts.fillTimeSeriesChart("actuals_chart", [me.key], false, true, me));
        } else {
            //this.timeseries = responses[1].data[0];
            stats = responses[1].data[1];
            //this.changepoints = responses[1].data[2].data;
            arrayOfPromises2.push(responses[1].ppts.fillTimeSeriesChart("actuals_chart", [me.key], false, true, me));
        }
        arrayOfPromises2.push(me.fillStats(stats[1], stats[0]));
        arrayOfPromises2.push(me.fillSignals(stats[1].data));
    }

    await Promise.race(arrayOfPromises2)
        .then(async (winner) => {
            let response = winner;
            if (checkDebug()) console.log("race win", response);
        });
    return;
}

function plantPartFixResize() {
    try {
        let actualTS = $("actuals_chart");
        Plotly.react(actualTS.id, actualTS.data, actualTS.layout, actualTS.config);

    } catch (e) { console.log(e); }
}

export async function startplantpart(scope) {

    console.log("startplantpart", scope);
    let split = scope.split(" - ");
    if (checkDebug()) console.log(split);
    let key = "";
    let name = "";
    if (split.length >= 2) {
        key = scope.substring(0, scope.indexOf(" "));
        name = scope.substring(scope.indexOf(" - ") + 3);
    } else key = scope;

    $("plantpartname").textContent = name;
    $("plantpartkey").value = key;

    let me = PlantObject.instance(name, key, process);
    let system = gLoginState.currentPlant.System;

    window.addEventListener("resize", plantPartFixResize);

    let arrayOfPromises = [
        callMe("/blackbox/molist", "POST", { model: "", type: "definition", state: null, system: system })
    ];
    if (me.type == "Sensor") {
        arrayOfPromises.push(plantPartTS.getTimeSeries(undefined, "short", system, me.key));
    }

    await process(arrayOfPromises, me);
}

export function destroyplantpart() {
    window.removeEventListener("resize", plantPartFixResize);
    let act_chart = $("actuals_chart");
    if (act_chart) Plotly.purge(act_chart);
}