import { navigate, myEvent } from "../index.mjs";
import { $, $T, $E, createElementAndClassList, Progress, createsetAttribute, cleanArea, checkDebug, checkData, PlotlyConfig, injectEscape, ListTools } from "../helper.mjs";
import { callMe } from "../mybackend.mjs";
import { gLoginState, userjson } from "../mymsal.mjs";
import { getFilteredPlantData } from "../fragments/asset.mjs";

String.prototype.escape = injectEscape;
export class Status {
    static labels = ["initial", "trained", "tested", "deployed", "active"];
    static images = [["fa-regular", "fa-circle"], ["fa-solid", "fa-circle-half-stroke"], ["fa-solid", "fa-circle"], ["fa-solid", "fa-circle-pause"], ["fa-solid", "fa-circle-play"]];
    constructor() {
    }

    static fillStateList(myselect, defaultstate = "") {
        for (let s of Status.labels) {
            let opt = $E("option");
            opt.value = s;
            opt.textContent = s;
            opt.selected = defaultstate == s ? "selected" : null;
            myselect.appendChild(opt);
        }
    }

    static createStateView(element, object_state) {
        for (var i = 0; i < Status.labels.length; i++) {
            let divscard = createElementAndClassList("div", ["card", "text-center"]);
            if (Status.labels[i] == object_state) divscard.classList.add("text-bg-primary");
            else divscard.classList.add("text-secondary", "border-light");

            let divscarhead = createElementAndClassList("div", ["card-header"]);
            let img = createElementAndClassList("i", Status.images[i]);
            img.classList.add("fa-lg");

            let divscarbody = createElementAndClassList("div", ["card-body", "p-0"]);
            let spanstat = createElementAndClassList("div", ["card-text", "fs-6"]);
            spanstat.textContent = Status.labels[i];

            divscarbody.appendChild(spanstat);
            divscarhead.appendChild(img);

            divscard.appendChild(divscarhead);
            divscard.appendChild(divscarbody);
            element.appendChild(divscard);
        }
    }
}

export class MonitoredObjectModel {
    constructor(system) {
        this.id = "";
        this.name = "";
        this.type = "monitored object";
        this.periodfile = "";
        this.periods = {};
        this.responses = {};
        this.features = {};
        this.system = system;
        this.modefault = {
            "id": "",
            "name": "",
            "type": this.type,
            "responses": {},
            "features": {}
        };
    }

    setid(id) {
        if (id) this.id = id;
        else this.id = "";
    }
}

export class MonitoredObjectView {
    static filterListEntries(doselect, dofilter) {
        var keyword = $(dofilter).value;
        var select = doselect;
        for (var i = 0; i < select.length; i++) {
            var txt = select.options[i].text;
            if (!txt.includes(keyword)) {
                createsetAttribute(select.options[i], 'disabled', 'disabled');
                select.options[i].classList.add("d-none");
            } else {
                select.options[i].removeAttribute('disabled');
                select.options[i].classList.remove("d-none");
            }
        }
    }

    static async filterBlobsToPeriods(blobs) {
        let periodbody = cleanArea("periodrows")
        for (const blob of blobs) {
            if (blob.Blob.indexOf("config_periods") >= 0 && blob.Blob.substring(0, blob.Blob.indexOf("-")) == String(gLoginState.currentPlant.System)) {
                const b = blob.Blob;
                let tr = $E("tr");
                let tdselect = $E("td");
                let periodname = b.substring(b.indexOf("-") + 16, b.indexOf("."));
                let divselect = createElementAndClassList("div", "form-check");
                let input = createElementAndClassList("input", "form-check-input");
                input.type = "radio";
                createsetAttribute(input, "name", "flexPeriodRadio");
                createsetAttribute(input, "id", b);
                createsetAttribute(input, "value", periodname);
                myEvent.attach(input, "click", async () => {
                    let sumlink = cleanArea("periodsum");
                    if (sumlink.hasAttribute("href")) sumlink.href = "#";
                    else createsetAttribute(sumlink, "href", "#");
                    sumlink.appendChild($T(b));
                    myEvent.attach(sumlink, "click", () => { MonitoredObjectView.createPeriodModal(b);});
                });
                let label = createElementAndClassList("label", "form-check-label");
                createsetAttribute(label, "for", b);
                label.appendChild($T(periodname));
                divselect.appendChild(input)
                divselect.appendChild(label);
                tdselect.appendChild(divselect);
                let tdview = $E("td");
                let icon = createElementAndClassList("i", ["fa-regular", "fa-file-code"]);
                let link = createElementAndClassList("a", "icon-link");
                createsetAttribute(link, "id", b + "-link");
                link.href = "#";
                myEvent.attach(link, "click", async () => { MonitoredObjectView.createPeriodModal(b); });
                link.appendChild(icon);
                tdview.appendChild(link);
                tr.appendChild(tdselect)
                tr.appendChild(tdview);
                periodbody.appendChild(tr);
            }
        }
    }

    static fillMODefinition(modef, fixResize) {
        // section filling, creates complete collapse content
        $("moid").innerHTML = String(modef.id).escape();
        $("moname").innerHTML = String(modef.name).escape();
        for (var resp in modef.responses) {
            let tr = $E("tr");
    
            let tdid = $E("td");
            tdid.textContent = modef.responses[resp].name[0];
            let tdname = $E("td");
            tdname.textContent = modef.responses[resp].name[1];
            let tduom = $E("td");
            tduom.textContent = modef.responses[resp].uom;
    
            let tdlink = $E("td");
            let link = createElementAndClassList("a", "link");
            link.href = "#";
            let icon = createElementAndClassList("i", ["fa-solid", "fa-magnifying-glass"]);
            if (modef.responses[resp].name[0].endsWith(".CS")) {
                createsetAttribute(icon, "data", "cs=" + modef.responses[resp].name[0] + "," + modef.system);
            } else {
                createsetAttribute(icon, "data", "plantpart=" + modef.responses[resp].name[0] + " - " + modef.responses[resp].name[1]);
            }
            icon.addEventListener("click", navigate);
            link.appendChild(icon);
            tdlink.appendChild(link),
    
                tr.append(tdid, tdname, tduom, tdlink);
            $("tab-resp").appendChild(tr);
        }
        for (var feat in modef.features) {
            let tr = $E("tr");
    
            let tdid = $E("td");
            tdid.textContent = modef.features[feat].name[0];
            let tdname = $E("td");
            tdname.textContent = modef.features[feat].name[1];
            let tduom = $E("td");
            tduom.textContent = modef.features[feat].uom;
    
            let tdlink = $E("td");
            let link = createElementAndClassList("a", "link");
            link.href = "#";
            let icon = createElementAndClassList("i", ["fa-solid", "fa-magnifying-glass"]);
            if (modef.features[feat].name[0].endsWith(".CS")) {
                createsetAttribute(icon, "data", "cs=" + modef.features[feat].name[0] + "," + modef.system);
            } else {
                createsetAttribute(icon, "data", "plantpart=" + modef.features[feat].name[0] + " - " + modef.features[feat].name[1]);
            }
            icon.addEventListener("click", navigate);
            link.appendChild(icon);
            tdlink.appendChild(link),
    
                tr.append(tdid, tdname, tduom, tdlink);
            $("tab-feat").appendChild(tr);
        }
        for (var period in modef.periods) {
            let tr = $E("tr");
    
            let divname = $E("td");
            divname.textContent = modef.periods[period].name;
            let divstart = $E("td");
            divstart.textContent = modef.periods[period].start_date;
            let divend = $E("td");
            divend.textContent = modef.periods[period].end_date;
            let divtype = $E("td");
            divtype.textContent = modef.periods[period].type;
            let divstates = $E("td");
            divstates.textContent = modef.periods[period].state.toString();
    
            tr.append(divname, divstart, divend, divtype, divstates);
    
            $("tab-period").appendChild(tr);
        }
        this.__fillPeriodTS("periodTS", modef.periods, modef.name);
        Array.from($("nav-tab-def").getElementsByTagName("button")).forEach((elem) => {
            myEvent.attach(elem, "click", () => { fixResize(); });
        });
    }

    static __fillPeriodTS(id, data, monitored_object) {
        let content = cleanArea(id);
        if (!checkData(data, content)) return;
        var traces = [];
        for (var d in data) {
            let x = [];
            x.push(data[d].start_date);
            x.push(data[d].end_date);
            let y = [];
            y.push(1);
            y.push(1);
            traces.push({
                "x": x,
                "y": y,
                "fill": "tozeroy",
                "type": "scatter",
                "name": data[d].name,
                "xhoverformat": "%x %X",
                "hovertemplate": "%{x}",
            });
        }
        var layout = {
            showlegend: false,
            margin: {
                autoexpand: false,
                l: 40,
                b: 60,
                t: 60,
                r: 60
            },
            yaxis: {
                ticks: "",
                showticklabels: false
            },
            hoverlabel: { namelength: 12 },
            modebar: {
                position: {
                    margin: { r: 70 }
                }
            }
        };
        Plotly.newPlot(content.id, traces, layout, new PlotlyConfig().getConfig("Periods time series for " + monitored_object));
    }
    
    static async createPeriodModal(blob) {
        let modal = createElementAndClassList("div", ["modal", "fade"]);
        createsetAttribute(modal, "tabindex", "-1");
        createsetAttribute(modal, "aria-labelledby", "modalLabel");
        createsetAttribute(modal, "aria-hidden", "false");
        createsetAttribute(modal, "data-bs-backdrop", "true");
        modal.id = "periodModal";
        let mdialog = createElementAndClassList("div", ["modal-dialog", "modal-dialog-scrollable", "modal-lg"]);
        let mcontent = createElementAndClassList("div", "modal-content");
        let mheader = createElementAndClassList("div", "modal-header");
        let mtitle = createElementAndClassList("h5", "modal-title");
        mtitle.id = "modalLabel";
        mtitle.textContent = blob;
        let mtclose = createElementAndClassList("button", "btn-close");
        mtclose.type = "button"
        createsetAttribute(mtclose, "data-bs-dismiss", "modal");
        createsetAttribute(mtclose, "aria-label", "Close");
        mheader.append(mtitle, mtclose);
        let mbody = createElementAndClassList("div", "modal-body");
        let code = $E("pre");
        let response = await callMe("/blob/downloadAsString", "POST",
            { account: gLoginState.currentEnv.modelstorage, container: "config-files", blobname: blob });
        if (checkDebug()) console.log(response);
        code.innerHTML = String(response.result).escape();
        mbody.appendChild(code);
        mcontent.append(mheader, mbody);
        mdialog.appendChild(mcontent);
        modal.appendChild(mdialog);

        let content = $("content");
        content.appendChild(modal);
        const myModal = new bootstrap.Modal(modal, {})
        myModal.show();
    }

    static fillEditMO(ctrl, model) {
        //Title
        $("offRightMOLabel").innerHTML = "Edit Monitored Object " + model.id;
        //Name
        let moname = $("mo-name");
        let ucname = $("uc-name");
        // myEvent.remove(moname, "change");
        // myEvent.remove(ucname, "change");
        moname.value = model.id;
        ucname.value = model.name.substring(0, model.name.indexOf(model.id) - 1);
        $("mo-name-sum").value = moname.value
        $("uc-name-sum").value = ucname.value;

        //Periods
        $("periodTable").classList.add("d-none");
        $("periodIntroCreate").classList.add("d-none");
        $("periodIntroEdit").classList.remove("d-none");
        let decision = document.getElementsByName("period-decide");
        decision.forEach((v) => {
            myEvent.remove(v, "click");
            myEvent.attach(v, "click", (ev) => {
                if (ev.target.id == "new") {
                    $("periodTable").classList.remove("d-none");
                    let periodsum = $("periodsum");
                    periodsum.href = "#";
                    periodsum.textContent = "";
                    myEvent.remove(periodsum, "click");
                    periodsum.classList.remove("d-none");
                    $("modalToggler").remove();
                } else {
                    $("periodTable").classList.add("d-none");
                    let periodsum = $("periodsum");
                    periodsum.classList.add("d-none");

                    if ($("modalToggler")) $("modalToggler").remove();
                    myEvent.remove($("modalToggler"), "click");
                    let mbutton = createElementAndClassList("button", ["btn", "btn-outline-primary"]);
                    mbutton.textContent = "Keep periods from existing Monitored Object";
                    mbutton.id = "modalToggler";
                    periodsum.parentElement.appendChild(mbutton);

                    if ($("periodModal")) $("periodModal").remove();
                    myEvent.remove(mbutton, "click");
                    myEvent.attach(mbutton, "click", () => {
                        let modal = createElementAndClassList("div", ["modal", "fade"]);
                        createsetAttribute(modal, "tabindex", "-1");
                        createsetAttribute(modal, "aria-labelledby", "modalLabel");
                        createsetAttribute(modal, "aria-hidden", "false");
                        createsetAttribute(modal, "data-bs-backdrop", "true");
                        modal.id = "periodModal";
                        let mdialog = createElementAndClassList("div", ["modal-dialog", "modal-dialog-scrollable", "modal-lg"]);
                        let mcontent = createElementAndClassList("div", "modal-content");
                        let mheader = createElementAndClassList("div", "modal-header");
                        let mtitle = createElementAndClassList("h5", "modal-title");
                        mtitle.id = "modalLabel";
                        mtitle.textContent = "Periods for " + model.id;
                        let mtclose = createElementAndClassList("button", "btn-close");
                        mtclose.type = "button"
                        createsetAttribute(mtclose, "data-bs-dismiss", "modal");
                        createsetAttribute(mtclose, "aria-label", "Close");
                        mheader.append(mtitle, mtclose);
                        let mbody = createElementAndClassList("div", "modal-body");
                        let code = $E("pre");
                        code.innerHTML = JSON.stringify(model.periods, null, 3);
                        mbody.appendChild(code);
                        mcontent.append(mheader, mbody);
                        mdialog.appendChild(mcontent);
                        modal.appendChild(mdialog);
                
                        let content = $("content");
                        content.appendChild(modal);
                        const myModal = new bootstrap.Modal(modal, {})
                        myModal.show();
                    });
                }
            });
        });
        $("keep").click();

        myEvent.attach($("resp_filter"), "keyup", () => { MonitoredObjectView.filterListEntries(resp_select, "resp_filter"); });
        myEvent.attach($("feat_filter"), "keyup", () => { MonitoredObjectView.filterListEntries(feat_select, "feat_filter"); });
        $("savemo").removeAttribute("disabled");
        myEvent.remove($("savemo"), "click");
        myEvent.attach($("savemo"), "click", () => { ctrl.saveMonitoredObject(); });
    }

    static fillCreateMO(ctrl) {
        //Title
        $("offRightMOLabel").innerHTML = "Create Monitored Object";
        $("mo-name").value = "";
        $("uc-name").value = "";
        $("mo-name-sum").value = "";
        $("uc-name-sum").value = "";

        //Periods
        $("periodTable").classList.remove("d-none");
        $("periodIntroCreate").classList.remove("d-none");
        $("periodIntroEdit").classList.add("d-none");
        let decision = document.getElementsByName("period-decide");
        decision.forEach((v) => {
            myEvent.remove(v, "click");
        });

        myEvent.attach($("mo-name"), "change", () => { $("mo-name-sum").value = $("mo-name").value });
        myEvent.attach($("uc-name"), "change", () => { $("uc-name-sum").value = $("uc-name").value });
        myEvent.attach($("resp_filter"), "keyup", () => { MonitoredObjectView.filterListEntries($("response_sel"), "resp_filter"); });
        myEvent.attach($("feat_filter"), "keyup", () => { MonitoredObjectView.filterListEntries($("feature_sel"), "feat_filter"); });
        $("savemo").removeAttribute("disabled");
        myEvent.remove($("savemo"), "click");
        myEvent.attach($("savemo"), "click", () => { ctrl.saveMonitoredObject(); });
    }
}
export class MonitoredObjectController {
    constructor(blobs, system) {
        this.model = new MonitoredObjectModel(system);
        this.cfgBlobs = blobs;
        this.lt = new ListTools();
    }

    async loadMonitoredObjectConfig(id, doreturn=true) {
        //correct Download
        let moconfig = await callMe("/blob/downloadAsString", "POST",
            { account: gLoginState.currentEnv.modelstorage, container: "config-files", blobname: id },
            false, true, true);
        moconfig = JSON.parse(moconfig.result);
        if (checkDebug()) console.log(moconfig);
        this.model.id = moconfig.id;
        this.model.name = moconfig.name;
        this.model.responses = moconfig.responses;
        this.model.features = moconfig.features;
        this.model.periods = moconfig.periods;
        if (doreturn) return this.model;
        else return true;
    }

    prepareEditMO() {
        //Name
        //not relevant? Archive/Version!

        //Responses
        this.lt.setConfig(cleanArea("response_list"),cleanArea("response_list_sum"), this.model.responses, undefined);
        for (let v in this.model.responses) {
            this.lt.addSensor(false, { srcElement: { text: v + " - " + this.model.responses[v]["name"][1], value: v } });
        };
        //Features
        this.lt.setConfig(cleanArea("feature_list"),cleanArea("feature_list_sum"), this.model.features, undefined);
        for (let v in this.model.features) {
            this.lt.addSensor(false, { srcElement: { text: v + " - " + this.model.features[v]["name"][1], value: v } });
        };
        //Periods
        //Not relevant

        //Summary

        //View Adaptions
        if (checkDebug()) console.log(this.model.periods);
        MonitoredObjectView.fillEditMO(this, this.model);
    }

    async saveMonitoredObject() {
        let title = $("offRightMOLabel").innerHTML;
        let cat_decider = title.substring(0, title.indexOf(" "));
        this.model.id = String($("mo-name-sum").value).escape();
        this.model.name = String($("uc-name-sum").value).escape() + " " + this.model.id;
        this.model.system = gLoginState.currentPlant.System;

        let buttonparent = $("savemo").parentElement;
        let prog = new Progress();
        prog.updateValue("Create Monitored Object Configuration...", 0);
        let spin = prog.getLoadingElement();
        buttonparent.appendChild(spin);

        createsetAttribute($("savemo"), "disabled", "disabled");
        createsetAttribute($("loadsamples"), "disabled", "disabled");
        createsetAttribute($("loadactuals"), "disabled", "disabled");

        let myMo = structuredClone(this.model.modefault);

        myMo["id"] = this.model.id;
        myMo["name"] = String(this.model.name).escape();
        myMo["responses"] = this.model.responses;
        myMo["features"] = this.model.features;
        prog.updateValue("Create Monitored Object Configuration...Done.", 10);

        prog.updateValue("Include periods...", 20);
        if ($("periodsum").textContent == "") {
            myMo["periods"] = this.model.periods;
        } else {
            let periodresponse = await callMe("/blob/downloadAsString", "POST", {
                account: gLoginState.currentEnv.modelstorage,
                container: "config-files",
                blobname: $("periodsum").textContent
            });
            Object.assign(myMo, JSON.parse(periodresponse.result));
        }
        prog.updateValue("Include periods...Done.", 20);
        console.info(myMo);
        prog.updateValue("Upload Monitored Object Configuration...", 30);
        let blobURL = await callMe("/blob/upload/MOConfig", "POST",
            { account: gLoginState.currentEnv.modelstorage, system: this.model.system, model: this.model.id, content: myMo });
        if (checkDebug()) console.info("blobUrl", blobURL);
        prog.updateValue("Upload Monitored Object Configuration...Done.", 30);
        prog.updateValue("Build Monitored Object...", 40);
        let result = await callMe("/blackbox/model/build", "POST", { system: this.model.system, model: this.model.id });
        console.info("build", result);
        prog.updateValue("Build Monitored Object...Done.", 50);
        // trigger timeseries load
        if ($("loadsamples").checked) {
            prog.updateValue("Trigger ingest of training samples ...", 60);
            let trigger = await callMe("/ingest/triggerExport", "POST", { model: this.model.id, container: "training-samples" });
            console.info("trigger timeseries", trigger);
            prog.updateValue("Trigger ingest of training samples ...Done.", 60);
        }
        if ($("loadactuals").checked) {
            prog.updateValue("Trigger ingest of actuals ...", 70);
            let trigger = await callMe("/ingest/triggerExport", "POST", { model: this.model.id, container: "actuals" });
            console.info("trigger timeseries", trigger);
            prog.updateValue("Trigger ingest of actuals data ...Done.", 70);
        }
        prog.updateValue("Finalize Monitored Object creation...", 80);
        const verb = cat_decider == "Edit" ? "changed" : "created"; 
        let msgresponse = await callMe("/msg/insert", "POST", {
            system: this.model.system,
            timestamp: new Date().toISOString(),
            category: cat_decider + " Monitored Object",
            author: userjson.userPrincipalName,
            msg: "Monitored Object " + this.model.name + " was" + verb + ".",
            object: this.model.id
        });
        console.info(msgresponse);
        prog.updateValue("Clean-up...", 90);
        this.model.responses = {};
        this.model.features = {};
        let lists = ["response_list", "response_list_sum", "feature_list", "feature_list_sum"];
        for (let item in lists) {
            let content = $(lists[item]);
            if (content) while (content.hasChildNodes()) {
                if (content.firstElementChild) content.removeChild(content.firstElementChild);
                if (content.firstChild) content.firstChild.remove();
            }
        };
        
        prog.updateValue("Clean-up...Done.", 100);
        $("createmoclose").click();
        $("savemo").removeAttribute("disabled");
        $("loadsamples").removeAttribute("disabled");
        $("loadactuals").removeAttribute("disabled");
        buttonparent.removeChild(spin);
        navigate(null, "mo=" + this.model.id + "," + this.model.system);
    }
    
    addMOElemenToList(select, list, list_sum, value, text, dict) {
        let opt = createElementAndClassList("option", "fs-6");
        opt.value = value;
        opt.text = text;
        
        opt.addEventListener("click", (opt2) => {
            this.lt.setConfig(list, list_sum, dict, opt2)
            this.lt.addSensor();
            if (checkDebug()) console.log(opt2, dict);
        });
        select.appendChild(opt);
    }

    registerListEntries(data) {
        let resp_select = cleanArea("response_sel");
        let resp_list = cleanArea("response_list");
        let resp_list_sum = cleanArea("response_list_sum")

        let feat_select = cleanArea("feature_sel");
        let feat_list = cleanArea("feature_list");
        let feat_list_sum = cleanArea("feature_list_sum");

        let resp_dict = this.model.responses;
        let feat_dict = this.model.features;
        if (checkDebug()) console.log(this.model);
        let sensors = getFilteredPlantData(gLoginState.currentPlant.AreaKey, "Sensor");
        sensors.forEach((sensor) => {
            this.addMOElemenToList(resp_select, resp_list, resp_list_sum, sensor.obj_id, sensor.obj_id + " - " + sensor.objname, resp_dict);
            this.addMOElemenToList(feat_select, feat_list, feat_list_sum, sensor.obj_id, sensor.obj_id + " - " + sensor.objname, feat_dict);
        });

        MonitoredObjectView.fillCreateMO(this);
    }

}