import { $ } from "../helper.mjs";
import { myEvent } from "../index.mjs";
import { MessageBroker } from "../messages.mjs";
import { callMe } from "../mybackend.mjs";
import { gLoginState, userjson, usersettings } from "../mymsal.mjs";
import { initUser, setUser } from "./user.mjs";

const settingsids = {
    "start": "set_start",
    "default_object_visual": "set_chart"
};
const msgids = {
    "MO": "msg_mo",
    "Strategy": "msg_strategy",
    "Ingest": "msg_ingest_reg",
    "Ingest_MO": "msg_ingest_idv"
};
const ids = { ...settingsids, ...msgids };

export async function startusersettings() {
    console.log("startusersettings");

    if (gLoginState.loginstate) {
        setUser();
        Object.keys(settingsids).forEach((v) => {
            $(settingsids[v]).value = usersettings[v];
        });
        let msg_subs = String(usersettings["msg_subscriptions"]).split(",");
        msg_subs = msg_subs.map((msg) => { return msg.trim() });
        Object.keys(msgids).forEach((v) => {
            if (msg_subs.find((val) => { return val == v })) {
                $(msgids[v]).checked = true;
            } else {
                $(msgids[v]).checked = false;
            }
        });

        Object.keys(ids).forEach((v) => {
            myEvent.attach($(ids[v]), "change", async () => {
                usersettings["start"] = $(settingsids["start"]).value;
                usersettings["default_object_visual"] = $(settingsids["default_object_visual"]).value;
                let msgTrue = [];
                Object.keys(msgids).forEach((v) => {
                    if ($(msgids[v]).checked == true) msgTrue.push(v);
                });
                usersettings["msg_subscriptions"] = msgTrue.join();
                await callMe("/blackbox/usersettings/modify", "POST", {
                    "user": userjson.userPrincipalName,
                    "settings": JSON.stringify(usersettings)
                })
                    .then((response) => {
                        if (response["return code"] == 0) {
                            MessageBroker.instance()
                                .setMessageSubScriptions(usersettings["msg_subscriptions"])
                                .runOnce();
                            $(ids[v]).classList.add("is-valid");
                            setTimeout(() => {
                                $(ids[v]).classList.remove("is-valid");
                            }, 3000);
                        }
                    })
            });
        });
    }
}

export function destroyusersettings() {
    initUser();
    Object.keys(ids).forEach((v) => {
        myEvent.remove($(ids[v]), "change")
    });
}
