import { $, createElementAndClassList, createsetAttribute, Progress, cleanArea, checkData, prettyJsonPrint, checkDebug, TrainError, $T } from "../helper.mjs";
import { myEvent, navigate } from "../index.mjs";
import { callMe } from "../mybackend.mjs";
import { userjson } from "../mymsal.mjs";
import { Status } from "./monitoredobject.mjs";

export let kpis = [
    { "id": "kpi_r2", "name": "r2", "factor": 1.0, "event": "deactivate", "short": "r2" },
    { "id": "kpi_mse", "name": "neg_mean_squared_error", "factor": 1.2, "event": "deactivate", "short": "mse" },
    { "id": "kpi_mae", "name": "neg_mean_absolute_error", "factor": 1.2, "event": "deactivate", "short": "mae" }
];

// let re = [
//     { "id": "re_r2", "name": "r2", "factor": 1.0, "event": "deactivate", "short": "r2", "default": 0 },
//     { "id": "re_mse", "name": "neg_mean_squared_error", "factor": 1.2, "event": "deactivate", "short": "mse", "default": -2 },
//     { "id": "re_mae", "name": "neg_mean_absolute_error", "factor": 1.2, "event": "deactivate", "short": "mae", "default": -1 }
// ];
let kpistate = ["train"]; //, "test", "activate"

export class Strategy {
    static __paramdef__ = {
        "2=MARSBayesSearchCV": [
            "MARSBayesSearchCV__cv : gap=12",
            "MARSBayesSearchCV__cv : n_splits=3"
        ],
        "2=RidgeBayesSearchCV": [
            "RidgeBayesSearchCV__cv : gap=12",
            "RidgeBayesSearchCV__cv : n_splits=3"
        ],
        "2=MARSMultioutBayesSearchCV": [
            "MARSMultioutBayesSearchCV__cv : gap=12",
            "MARSMultioutBayesSearchCV__cv : n_splits=3"
        ],
        "2=LFRBayesSearchCV": [
            "LFRBayesSearchCV__cv : gap=12",
            "LFRBayesSearchCV__cv : n_splits=3",
            "LFRBayesSearchCV__search_spaces__n_estimators : low=20",
            "LFRBayesSearchCV__search_spaces__n_estimators : high=30",
            "LFRBayesSearchCV__search_spaces__n_estimators : dtype=integer",
            "LFRBayesSearchCV__search_spaces__max_depth : low=3",
            "LFRBayesSearchCV__search_spaces__max_depth : high=5",
            "LFRBayesSearchCV__search_spaces__max_depth : dtype=integer",
            "LFRBayesSearchCV__search_spaces__min_samples_split : low=2",
            "LFRBayesSearchCV__search_spaces__min_samples_split : high=5",
            "LFRBayesSearchCV__search_spaces__min_samples_split : dtype=integer",
            "LFRBayesSearchCV__search_spaces__min_samples_leaf : low=2",
            "LFRBayesSearchCV__search_spaces__min_samples_leaf : high=3",
            "LFRBayesSearchCV__search_spaces__min_samples_leaf : dtype=integer",
            "LFRBayesSearchCV : random_state=42",
            "LFRBayesSearchCV : verbose=2"
        ],
        "3=MARSBayesSearchCV": [
            "MARSBayesSearchCV__cv : gap=12",
            "MARSBayesSearchCV__cv : n_splits=3"
        ],
        "3=MARSMultioutBayesSearchCV": [
            "MARSMultioutBayesSearchCV__cv : gap=12",
            "MARSMultioutBayesSearchCV__cv : n_splits=3"
        ],
        "3=RidgeBayesSearchCV": [
            "RidgeBayesSearchCV__cv : gap=12",
            "RidgeBayesSearchCV__cv : n_splits=3"
        ],
        "3=LFRBayesSearchCV": [
            "LFRBayesSearchCV__cv : gap=12",
            "LFRBayesSearchCV__cv : n_splits=3",
            "LFRBayesSearchCV__search_spaces__n_estimators : low=20",
            "LFRBayesSearchCV__search_spaces__n_estimators : high=30",
            "LFRBayesSearchCV__search_spaces__n_estimators : dtype=integer",
            "LFRBayesSearchCV__search_spaces__max_depth : low=3",
            "LFRBayesSearchCV__search_spaces__max_depth : high=5",
            "LFRBayesSearchCV__search_spaces__max_depth : dtype=integer",
            "LFRBayesSearchCV__search_spaces__min_samples_split : low=2",
            "LFRBayesSearchCV__search_spaces__min_samples_split : high=5",
            "LFRBayesSearchCV__search_spaces__min_samples_split : dtype=integer",
            "LFRBayesSearchCV__search_spaces__min_samples_leaf : low=2",
            "LFRBayesSearchCV__search_spaces__min_samples_leaf : high=3",
            "LFRBayesSearchCV__search_spaces__min_samples_leaf : dtype=integer",
            "LFRBayesSearchCV : random_state=42",
            "LFRBayesSearchCV : verbose=2"
        ]
    };
    static __stratpos__ = ["LFRBayesSearchCV", "MARS", "MARSMultiout", "MARSBayesSearchCV", "RidgeBayesSearchCV"];
    static __stratneg__ = ["CPDPenalizedSearch", "CPDWindowSearch", "T2Statistics", "ElasticNetRegression"]

    constructor(system, monitored_object) {
        this.default = {
            "system": system,
            "model": monitored_object,
            "strategy": "",
            "new": true,
            "steps": ["0=StandardScaler", "1=VarianceThreshold"],
            "params": [],
            "test": true,
            //"metrics": ["r2, 0.0, 1, deactivate"], //<metric>, <initial_score>, <weight>, <event>, train", "r2, 0.0, test", "r2, 0.0, activate"
            "stop": "deployed",
            "synchronous": true,
            "verbose": 3
        }
        this.myjson = structuredClone(this.default);
        this.mosystem = system;
        this.monitored_object = monitored_object;
    }

    static checkParams(value) {
        let hit = false;
        for (let key in this.__paramdef__) {
            if (key == value) {
                hit = true;
                return key;
            }
        }
        if (!hit) return false;
    }

    static getParams() {
        return Strategy.__paramdef__;
    }

    getRetrainButton(strategy, target) {
        let retrainbtn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-primary", "me-2"])
        createsetAttribute(retrainbtn, "data", this.monitored_object + "§" + strategy);
        createsetAttribute(retrainbtn, "data-bs-toggle", "offcanvas");
        createsetAttribute(retrainbtn, "data-bs-target", "#offRetrainStrategy");
        createsetAttribute(retrainbtn, "aria-controls", "offRetrainStrategy");
        retrainbtn.type = "button";
        retrainbtn.id = "btnretrain-" + this.monitored_object + strategy;
        let retraini = createElementAndClassList("i", ["fa-solid", "fa-arrows-rotate", "me-2"]);
        retrainbtn.appendChild(retraini);
        retrainbtn.appendChild($T("Re-Train"));
        retrainbtn.addEventListener("click", (ev) => {
            myEvent.attach($("saveretstrat"), "click", (ev) => {
                console.info(this.myjson);
                this.retrainStrategy(ev.target, this.myjson, target);
            });
            let data = ev.target.getAttribute("data");
            createsetAttribute($("saveretstrat"), "data", data);
            data = data.split("§");
            $("retmo").value = data[0];
            $("retstrat").value = data[1];
            Status.fillStateList(cleanArea("retstate"), $("currentstate" + data[0] + data[1]).value);
        });
        return retrainbtn;
    }

    retrainStrategy(me, json, target) {
        let data;
        try { data = this.getAttribute("data"); }
        catch (e) {
            data = me.getAttribute("data");
        }
        data = data.split("§");
        let monitored_object = data[0];
        let strategy = data[1];
        let state = $("retstate").value;

        let myparams = Strategy.getParams();

        this.myjson = (json) ? json : structuredClone(this.default);
        this.myjson.model = monitored_object;
        this.myjson.system = this.mosystem;
        if (!this.myjson.steps.includes("2=" + strategy)) this.myjson.steps.push("2=" + strategy);
        this.myjson.stop = state;
        this.myjson.strategy = strategy;
        this.myjson.params = Object.keys(myparams).includes("2=" + strategy) ? myparams["2=" + strategy] : [];
        //this.myjson.metrics = null;
        try {
            let prog = new Progress();
            prog.updateValue("Retrain strategy, configuration...", 10);
            let mySpinner = prog.getLoadingElement()
            $("saveretstratactionbar").appendChild(mySpinner);
            createsetAttribute($("saveretstrat"), "disabled", "disabled");
            prog.updateValue("Train strategy model...", 40);
            callMe("/blackbox/model/train", "POST", this.myjson)
                .then(async (data) => {
                    if (Object.keys(data).includes("code") && Object.keys(data).includes("status")) {
                        prog.updateValue("Training failed with " + data.message, 90);
                        throw new TrainError(data);
                    }
                    else {
                        console.info(data);
                        prog.updateValue("Get training results...", 80);
                        let state = data.data[data.data.length - 1].state;
                        this.mosystem = data.details.system;
                        this.monitored_object = data.details.model;
                        prog.updateValue("Finalize retrain process...", 90);
                        let msgresponse = await callMe("/msg/insert", "POST", {
                            system: this.mosystem,
                            timestamp: new Date().toISOString(),
                            category: "Retrain strategy",
                            author: userjson.userPrincipalName,
                            msg: "Strategy " + data.details.strategy + " was retrained with target state " + data.details.stop
                                + " - and reached state " + state,
                            object: this.monitored_object
                        });
                        if (checkDebug()) console.log(msgresponse);
                    }
                })
                .finally(() => {
                    prog.updateValue("Done...", 100);
                    // for (let item of re) {
                    //     let id = item.id;
                    //     myEvent.remove($(id), "click");
                    //     for (let state of kpistate) { 
                    //         myEvent.remove($(id + "_" + state));
                    //         $(id + "_" + state).value = item.default;
                    //     }
                    // }
                    window.setTimeout(() => {
                        $("createretstratclose").click();
                        $("saveretstratactionbar").removeChild(mySpinner);
                        $("saveretstrat").removeAttribute("disabled");
                        this.myjson = structuredClone(this.default);
                        switch (target) {
                            case "a_model":
                                navigate(null, "a_model");
                                break;
                            case "mo":
                                navigate(null, "mo=" + this.monitored_object + "," + this.mosystem);
                                break;
                        }
                    }, 3500);
                })
                .catch((trainError) => { 
                    console.warn("Retrain Strategy rejected");
                    if (checkDebug()) console.warn(trainError);
                }
            );
        } catch (trainError) {
            console.warn(trainError);
            prog.updateValue("Error code" + trainError.code + ", check console...Now cleaning up", 100);
            window.setTimeout(() => {
                $("createretstratclose").click();
                $("saveretstratactionbar").removeChild(mySpinner);
                $("saveretstrat").removeAttribute("disabled");
                this.myjson = structuredClone(this.default);
                switch (target) {
                    case "a_model":
                        navigate(null, "a_model");
                        break;
                    case "mo":
                        navigate(null, "mo=" + this.monitored_object + "," + this.mosystem);
                        break;
                }
            }, 5000);
        }
    }

    cleanDeleteStrategy() {
        $("deleteType").innerHTML = "";
        $("deleteName").innerHTML = "";
        myEvent.remove($("deleteConfirm"), "click");
        myEvent.remove($("deleteCancel"), "click");
        cleanArea("deleteProgress");
        $("deleteClose").click();
    }

    fillDeleteStrategy(me) {
        let data;
        try { data = this.getAttribute("data"); }
        catch (e) {
            data = me.getAttribute("data");
        }
        data = data.split("§");
        let monitored_object = data[0];
        let strategy = data[1];

        $("deleteConfirm").removeAttribute("disabled");
        $("deleteCancel").removeAttribute("disabled");
        $("deleteType").innerHTML = "Strategy";
        $("deleteName").innerHTML = "strategy \"" + strategy + "\" of " + monitored_object;

        myEvent.attach($("deleteConfirm"), "click", () => {
            this.deleteStrategy(monitored_object, strategy);
        });
        myEvent.attach($("deleteCancel"), "click", () => {
            this.cleanDeleteStrategy()
        });
    }

    deleteStrategy(monitored_object, strategy) {
        if (monitored_object !== this.monitored_object)
            console.warn("mismatch: " + monitored_object + " vs. this.<" + this.monitored_object + ">");

        let prog = new Progress();
        prog.updateValue("Delete strategy, configuration...", 20);
        let mySpinner = prog.getLoadingElement()
        $("deleteProgress").appendChild(mySpinner);
        createsetAttribute($("deleteConfirm"), "disabled", "disabled");
        createsetAttribute($("deleteCancel"), "disabled", "disabled");
        prog.updateValue("Delete strategy " + strategy, 40);
        callMe("/blackbox/model/clear", "POST", { system: this.mosystem, monitored_object: monitored_object, strategy: strategy })
            .then((result) => {
                if (result["return code"] == 0) {
                    prog.updateValue("Strategy successfully deleted " + strategy, 60);
                    return true
                } else {
                    console.error("Delete Strategy Error: ", result);
                    prog.updateValue("Delete strategy failed", 100);
                    return false
                }
            })
            .then((check) => {
                if (check) {
                    prog.updateValue("Finalize delete process...", 90);
                    let msgresponse = callMe("/msg/insert", "POST", {
                        system: this.mosystem,
                        timestamp: new Date().toISOString(),
                        category: "Delete strategy",
                        author: userjson.userPrincipalName,
                        msg: "Strategy " + strategy + " was deleted",
                        object: monitored_object
                    });
                    return msgresponse;
                }
                else {
                    return false
                }
            })
            .then((response) => {
                if (response !== false) {
                    prog.updateValue("Process completed", 100);
                }
                window.setTimeout(() => {
                    this.cleanDeleteStrategy()
                    navigate(null, "mo=" + monitored_object + "," + this.mosystem);
                }, 3500);
            });
    }

    fillCreateStrategy(data) {
        if (!checkData(data, $("stratcontent"))) return;

        let text = $("stratjson");
        text.addEventListener("change", () => { prettyJsonPrint("stratjson"); })
        let myjson = this.myjson;

        text.textContent = JSON.stringify(myjson, null, 4);

        $("step2").addEventListener("change", () => {
            let step = $("step2").value;
            //console.log(step);
            if (myjson["steps"].length == 2) myjson["steps"].push(step);
            else if (myjson["steps"].length > 2) myjson["steps"][2] = step;

            if (step == "2=PCA")
                $("step3").classList.remove("d-none")
            else
                if (!$("step3").classList.contains("d-none")) {
                    let step3 = $("step3");
                    step3.classList.add("d-none");
                    step3.value = "";
                    if (myjson["steps"].length == 4) myjson["steps"].pop();
                }
            if (step == Strategy.checkParams(step)) myjson["params"] = Strategy.getParams()[step];
            else myjson["params"] = []

            text.textContent = JSON.stringify(myjson, null, 4)
        });
        $("step3").addEventListener("change", () => {
            let step = $("step3").value;
            //console.log(step);
            if (myjson["steps"].length == 3) myjson["steps"].push(step);
            else if (myjson["steps"].length > 3) myjson["steps"][3] = step;

            if (step == Strategy.checkParams(step)) myjson["params"] = Strategy.getParams()[step];
            else myjson["params"] = []

            if (step == "" && myjson["steps"].length == 4) {
                myjson["steps"].pop();
                myjson["params"] = [];
            }
            text.textContent = JSON.stringify(myjson, null, 4)
        });

        // continue list strategies with radio buttons
        // Edit text and html on offcanvas
        if ("template" in data)
            if ("strategy" in data.template)
                if (data.template.strategy.choices) {
                    let choices = data.template.strategy.choices
                    let group = $("stratcontent");
                    let list = $("stratexpert");
                    for (let opt in choices) {
                        let optval = choices[opt];
                        if (Strategy.__stratpos__.includes(optval)) {
                            let divinp = createElementAndClassList("div", "form-check");
                            let stratinp = createElementAndClassList("input", ["form-check-input"]);
                            stratinp.type = "radio";
                            stratinp.name = "strategy";

                            stratinp.id = optval;
                            if (choices[opt] == Strategy.__stratpos__[0]) {
                                stratinp.checked = "checked";
                                $("floatingStrategy").value = optval;
                                myjson["strategy"] = optval;
                                text.textContent = JSON.stringify(myjson, null, 4);
                                let step2 = $("step2")
                                step2.value = "2=" + optval;
                                var event = new Event('change');
                                step2.dispatchEvent(event);
                            }

                            let stratlab = createElementAndClassList("label", ["form-check-label", "mb-2"]);
                            createsetAttribute(stratlab, "for", optval);
                            stratlab.textContent = optval;
                            divinp.appendChild(stratinp);
                            divinp.appendChild(stratlab);
                            stratinp.addEventListener("click", (event) => {
                                let value = event.srcElement.id
                                $("floatingStrategy").value = value;
                                myjson["strategy"] = value;
                                text.textContent = JSON.stringify(myjson, null, 4);
                                let step2 = $("step2")
                                step2.value = "2=" + value;
                                var event = new Event('change');
                                step2.dispatchEvent(event);
                            });
                            group.appendChild(divinp);
                        }
                        else if (Strategy.__stratneg__.includes(optval)) {
                            continue;
                        }
                        else {
                            let li = createElementAndClassList("li", "list-group-item");
                            li.textContent = optval;
                            list.appendChild(li);
                        }
                    }
                }

        // stop state
        let myselect = cleanArea("stopstate")
        Status.fillStateList(myselect, "deployed");
        myEvent.attach(myselect, "change", (ev) => {
            console.log(ev.target.value);
            myjson["stop"] = ev.target.value;
            text.textContent = JSON.stringify(myjson, null, 4)
        });
        // Metrics
        for (let item of kpis) {
            let id = item["id"];
            myEvent.attach($(id), "click", () => {
                let me = $(id);
                if (me.checked) {
                    if (!("metrics" in myjson)) {
                        myjson.metrics = [];
                    }
                    for (let state of kpistate) {
                        $(id.substring(4) + "_" + state).disabled = false;
                        let myvalue = $(id.substring(4) + "_" + state).value;
                        myjson.metrics.push(item.name + "," + myvalue.toString() + "," + item.factor.toString() + "," + item.event); // + ", " + state);
                    }
                }
                else {
                    for (let state of kpistate) {
                        $(id.substring(4) + "_" + state).disabled = true;
                        for (let tuple of myjson.metrics) {
                            if (tuple.substring(0, tuple.indexOf(",")) == item.name) { // && tuple.substring(tuple.lastIndexOf(",")+2)==state) {
                                myjson.metrics.splice(myjson.metrics.indexOf(tuple), 1);
                                break;
                            }
                        };
                    }
                    if (myjson.metrics.length == 0) delete myjson.metrics;
                }
                text.textContent = JSON.stringify(myjson, null, 4);
            });
            for (let state of kpistate) {
                myEvent.attach($(id.substring(4) + "_" + state), "change", () => {
                    let newvalue = $(id.substring(4) + "_" + state).value;
                    let change = false;
                    for (let tuple of myjson.metrics) {
                        if (tuple.substring(0, tuple.indexOf(",")) == item.name) { // && tuple.substring(tuple.lastIndexOf(",")+2)==state) {
                            let idx = myjson.metrics.indexOf(tuple);
                            myjson.metrics[idx] = item.name + ", " + newvalue.toString() + "," + item.factor.toString() + "," + item.event; // + ", " + state;
                            change = true;
                            break;
                        }
                    }
                    if (!change) myjson.metrics.push(item.name + "," + newvalue.toString() + "," + item.factor.toString() + "," + item.event); // + ", " + state);
                    text.textContent = JSON.stringify(myjson, null, 4);
                });
            }
        }

        myEvent.attach($("savestrat"), "click", async () => {
            this.saveStrategy();
        });
    }

    saveStrategy() {
        try {
            let prog = new Progress();
            prog.updateValue("Create strategy configuration...", 10);
            let mySpinner = prog.getLoadingElement()
            $("savestratactionbar").appendChild(mySpinner);
            createsetAttribute($("savestrat"), "disabled", "disabled");
            createsetAttribute($("loadactuals"), "disabled", "disabled");

            prog.updateValue("Train strategy model...", 40);
            callMe("/blackbox/model/train", "POST",
                $("stratjson").value,
                false, true, false
            )
                .then((result) => {
                    if (Object.keys(result).includes("code") && Object.keys(result).includes("status")) {
                        prog.updateValue("Training failed with " + result.message, 90);
                        throw new TrainError(result);
                    }
                    else {
                        prog.updateValue("Get training results...", 50);
                        console.log(result);
                        let state = result.data[result.data.length - 1].state;
                        this.mosystem = result.details.system;
                        this.monitored_object = result.details.model;
                        if ($("loadactuals").checked) {
                            prog.updateValue("Trigger ingest of actuals ...", 70);
                            let trigger = callMe("/ingest/triggerExport", "POST", { model: this.model.id, container: "actuals" });
                            console.info("trigger timeseries", trigger);
                            prog.updateValue("Trigger ingest of actuals data ...Done.", 70);
                        }
                        prog.updateValue("Finalize Strategy creation...", 80);
                        let msgresponse = callMe("/msg/insert", "POST", {
                            system: result.details.system,
                            timestamp: new Date().toISOString(),
                            category: "New strategy",
                            author: userjson.userPrincipalName,
                            msg: "Strategy " + result.details.strategy + " was created with target state "
                                + result.details.stop + " - and reached state " + state,
                            object: result.details.model
                        });
                        if (checkDebug()) console.log(msgresponse);
                    }
                })
                .finally(() => {
                    prog.updateValue("Done...", 100);
                    window.setTimeout(() => {
                        $("createstratclose").click();
                        $("savestratactionbar").removeChild(mySpinner);
                        $("savestrat").removeAttribute("disabled");
                        $("loadactuals").removeAttribute("disabled");
                        this.myjson = structuredClone(this.default);
                        navigate(null, "mo=" + this.monitored_object + "," + this.mosystem);
                    }, 3500);
                })
                .catch((trainError) => { 
                    console.warn("Create Strategy rejected");
                    if (checkDebug()) console.log(trainError);
                }
            );
        } catch (trainError) {
            console.warn(trainError);
            prog.updateValue("Error code " + trainError.code + ", check console...Now cleaning up", 100);
            window.setTimeout(() => {
                $("createstratclose").click();
                $("savestratactionbar").removeChild(mySpinner);
                $("savestrat").removeAttribute("disabled");
                this.myjson = structuredClone(this.default);
                navigate(null, "mo=" + this.monitored_object + "," + this.mosystem);
            }, 5000);
        }
    }
}