import { $, createElementAndClassList, createsetAttribute, cleanArea, capFirstLetter, checkDebug, safeAppend, createListElementWithLink, PlotlyConfig, $T, $E } from "../helper.mjs";
import { myEvent } from "../index.mjs";
import { callMe } from "../mybackend.mjs";
import { kpis } from "./strategy.mjs";

export class Signals {
    static single = { interval: "short", category: "responses", signals: {}, number: 0 };
    static intervals = ["short", "middle", "long"];
    static categories = ["responses", "features"];
    static _signals = [];
    constructor(system, mo, context = "mo", strategy = "", interval = "all", fixResize = undefined) {
        this.system = system;
        this.mo = mo;
        this.stats = [];
        this.context = context;
        this.fixResize = fixResize;
        let getter = [this.getStatsSignals(this.context)];
        if (strategy !== "" && strategy !== null) getter.push(this.getStratSignals(strategy, interval, this.context));
        Promise.all(getter);
    }

    async getStatsSignals(context) {
        let number = 0;
        let getstats = [];
        Signals._signals = [];
        Signals.intervals.forEach((interval) => {
            Signals.categories.forEach((category) => {
                let item = { ...Signals.single };
                item.interval = interval;
                item.category = category;
                item.context = context == "Model" ? "mo" : context;
                this.stats.push(item);
                getstats.push(callMe("/blackbox/stats", "POST",
                    { system: this.system, sensors: Object.keys(this.mo[item.category]), interval: item.interval, onlysignals: "true" })
                );
            });
        });
        await Promise.all(getstats)
            .then((responses) => {

                for (let s = 0; s < this.stats.length; s++) {
                    let item = this.stats[s];
                    if (responses[s].hasOwnProperty("data"))
                        if (responses[s].data.hasOwnProperty("signals")) {
                            item.signals = {};
                            item.type = "mo";
                            item.number = 0;
                            Object.keys(responses[s].data.signals).forEach((v) => {
                                if (responses[s].data.signals[v].hasOwnProperty("signal")) {
                                    item.signals[v] = responses[s].data.signals[v];
                                    item.number += responses[s].data.signals[v]["signal"];
                                }
                            });
                            if (item.number > 0) {
                                number += 1;
                                Signals._signals.push(item);
                            }
                        }
                }
            })
            .finally(() => {
                if (number > 0) {
                    if (context == "Model") SignalView.fillMOSignals(Signals._signals, "mo");
                    else SignalView.fillMOSignals(Signals._signals, context);
                }
            });
    }

    async getStratSignals(strategy, interval = "all", context) {
        // Signals.intervals.forEach
        let promisarray = [];
        if (interval == "all") {
            Signals.intervals.forEach((interval) => {
                promisarray.push(callMe("/blackbox/scoring", "POST",
                    { model: this.mo.id, strategy: strategy, system: this.system, interval: interval }
                ));
            });
        }
        else {
            promisarray.push(callMe("/blackbox/scoring", "POST",
                { model: this.mo.id, strategy: strategy, system: this.system, interval: interval }
            ));
        }
        await Promise.all(promisarray)
            .then((response) => {
                let content;
                let accord = createElementAndClassList("div", "accordion");
                let signalnumber = 0;
                if (context == "mostart") content = $("signals" + context);
                else content = $("signalsModel");
                Object.keys(response).forEach((key) => {
                    if (Object.keys(response[key]).includes("data")) {
                        if (response[key].data.signal > 0) {
                            signalnumber += response[key].data.signal;
                        }
                    } else {
                        if (Object.keys(response[key]).includes("status")) {
                            console.warn(["Error getting Signals", response[key].status, response[key].name, response[key].code, response[key].stack]);
                        }
                    }
                });

                if (signalnumber > 0) {
                    if (context == "mostart") {
                        let content = $("signals" + context);
                        if (content.hasChildNodes())
                            if (content.firstElementChild.hasAttribute("data")) {
                                content = cleanArea("signals" + context);
                            }
                        accord.id = this.context + "model" + "signalaccordion";
                        content.appendChild(accord);
                        let mototalInner = $("mototal").innerHTML;
                        let mototal = cleanArea("mototal");
                        mototal.append($T(String(Number(mototalInner) + signalnumber)));
                    } else {
                        if (content.firstElementChild.hasAttribute("data")) {
                            content = cleanArea("signals" + context);
                        }
                        accord.id = this.context + "model" + "signalaccordion";
                        content.appendChild(accord);
                        let modeltotalInner = $("Modeltotal").innerHTML;
                        let modeltotal = cleanArea("Modeltotal");
                        modeltotal.append($T(String(Number(modeltotalInner) + signalnumber)));
                    }
                    SignalView.fillScoringSignals(strategy, response, accord, context, this.fixResize);
                }
                
            })
    }

    static addPredictionSignal(newsignals, interval) {
        if (newsignals && Array.isArray(newsignals)) {
            Object.keys(newsignals).forEach((item) => {
                if (newsignals[item] > 0) {
                    Signals._signals.push({
                        type: "model",
                        number: newsignals[item],
                        category: capFirstLetter(item),
                        interval: interval
                    })
                }
            });
            SignalView.fillPredictionSignals(Signals._signals);
        }
    }
}

export class SignalView {
    static fillMOSignals(signals, context) {
        let content = $("signals" + context);
        if (signals.length > 0 && content.hasChildNodes())
            if (content.firstElementChild.hasAttribute("data")) {
                content = cleanArea("signals" + context);
            }
        let number = 0;
        let accord = createElementAndClassList("div", "accordion");
        accord.id = context + "mo" + "signalaccordion";
        signals.forEach((item) => {
            if (item.context == context) {
                number += 1;
                let accitem = createElementAndClassList("div", "accordion-item");
                let acchead = createElementAndClassList("h2", "accordion-header");
                let accbutt = createElementAndClassList("button", ["accordion-button", "collapsed", "text-bg-warning"]);
                accbutt.type = "button";
                createsetAttribute(accbutt, "data-bs-toggle", "collapse");
                createsetAttribute(accbutt, "data-bs-target", "#" + item.type + context + item.category + item.interval);
                createsetAttribute(accbutt, "aria-controls", item.type + context + item.category + item.interval);
                createsetAttribute(accbutt, "aria-expanded", "false");
                accbutt.textContent = capFirstLetter(item.category) + " in period" + "\"" + capFirstLetter(item.interval) + "\" show signals: " + Object.keys(item.signals).map((v, i) => { return v });
                acchead.appendChild(accbutt);
                accitem.appendChild(acchead);
                let acccoll = createElementAndClassList("div", ["accordion-collapse", "collapse"]);
                acccoll.id = item.type + context + item.category + item.interval;
                createsetAttribute(acccoll, "data-bs-parent", "#" + accord.id)
                let accbody = createElementAndClassList("div", "accordion-body");
                let cardgrid = createElementAndClassList("div", ["row", "row-cols-1", "row-cols-md-2", "g-4"]);
                SignalView.renderSignals(item.signals, cardgrid);
                accbody.appendChild(cardgrid);
                acccoll.appendChild(accbody);
                accitem.appendChild(acccoll);
                accord.appendChild(accitem);
            }
        });
        safeAppend(content, accord);
        try {
            let mototal = $("mototal")
            mototal.innerHTML = Number(mototal.innerHTML) + number;
        } catch (error) {
            console.warn("potential site change, content lost destination");
            console.info(error);
        }
    }

    static fillScoringSignals(strategy, response, accord, context, fixResize = undefined) {
        let accorditem = createElementAndClassList("div", "accordion-item");
        safeAppend(accord, accorditem);
        let title = createElementAndClassList("h2", "accordion-header");
        let button = createElementAndClassList("button", ["accordion-button", "text-bg-success", "collapsed"]);
        button.type = "button";
        createsetAttribute(button, "data-bs-toggle", "collapse");
        createsetAttribute(button, "data-bs-target", "#mo" + context + "metrics");
        createsetAttribute(button, "aria-controls", "mo" + context + "metrics");
        createsetAttribute(button, "aria-expanded", "false");
        button.textContent = "Active strategy \"" + strategy + "\" show healthy metrics";
        myEvent.attach(button, "click", fixResize);
        title.appendChild(button);
        accorditem.appendChild(title);
        let accordcoll = createElementAndClassList("div", ["accordion-collapse", "collapse"]);
        createsetAttribute(accordcoll, "id", "mo" + context + "metrics");
        if (context == "mostart")
            createsetAttribute(accordcoll, "data-bs-parent", "#" + context + "mosignalaccordion");
        else
            createsetAttribute(accordcoll, "data-bs-parent", "#" + context + "modelsignalaccordion");
        accorditem.appendChild(accordcoll);
        let body = createElementAndClassList("div", "accordion-body");
        accordcoll.appendChild(body);

        let r = new metricRenderer("short", {
            kpis: kpis,
            basis: response[0].data.basis,
            scores: response[0].data.scores
        }
        ).renderSignal(body);
        let divblock = createElementAndClassList("div", ["d-flex"]);
        let divblock2 = createElementAndClassList("div", ["container"]);
        let divrow = createElementAndClassList("div", "row");
        let divint = createElementAndClassList("div", ["col", "col-3", "fw-bold", "p-0"]);
        let divmet = createElementAndClassList("div", ["col", "col-3", "fw-bold", "p-0"]);
        let divval = createElementAndClassList("div", ["col", "col-3", "fw-bold", "p-0"]);
        let divthd = createElementAndClassList("div", ["col", "col-3", "fw-bold", "p-0"]);
        divint.appendChild($T("Period"));
        divmet.appendChild($T("Metric"));
        divval.appendChild($T("Value"));
        divthd.appendChild($T("Audit"));
        divrow.append(divint, divmet, divval, divthd);
        divblock2.appendChild(divrow);
        let titlebutton = createElementAndClassList("button", ["btn", "btn-outline-primary", "invisible"]);
        let icon = createElementAndClassList("i", ["fa-solid", "fa-chart-bar"]);
        titlebutton.appendChild(icon);
        divblock.append(divblock2, titlebutton);
        r.table.appendChild(divblock);

        Object.keys(response).forEach((key) => {
            if (Object.keys(response[key]).includes("data")) {
                divblock = createElementAndClassList("div", ["d-flex"]);
                if (checkDebug()) console.log(response[key].data);
                divblock2 = createElementAndClassList("div", ["container"]);
                kpis.forEach((kpi) => {
                    divrow = createElementAndClassList("div", "row");
                    divint = createElementAndClassList("div", ["col", "col-3", "p-0"]);
                    divmet = createElementAndClassList("div", ["col", "col-3", "p-0"]);
                    divval = createElementAndClassList("div", ["col", "col-3", "p-0"]);
                    divthd = createElementAndClassList("div", ["col", "col-3", "p-0"]);
                    divint.appendChild($T(response[key].details.interval));
                    divmet.appendChild($T(kpi.short));
                    let myval = Number(response[key].data.scores[kpi.name]);
                    let mythd = Number(response[key].data.basis[kpi.name]);
                    divval.appendChild($T(myval.toFixed(3)));
                    divthd.appendChild($T(mythd.toFixed(3)));
                    if (myval < mythd) {
                        divval.classList.add("text-bg-danger");
                        divthd.classList.add("text-bg-danger");
                    }
                    divrow.append(divint, divmet, divval, divthd);

                    divblock2.append(divrow);
                });
                let chartbutton = createElementAndClassList("button", ["btn", "btn-outline-primary", "tochart"]);
                chartbutton.title = "to metric chart";
                if (key==0) chartbutton.classList.add("active");
                myEvent.attach(chartbutton, "click", (ev) => {
                    let r = new metricRenderer(response[key].details.interval, {
                        kpis: kpis,
                        basis: response[key].data.basis,
                        scores: response[key].data.scores
                    }
                    ).showMetricIndicators();
                    let btns = document.getElementsByClassName("tochart");
                    for(let i = 0; i < btns.length; i++ ) {
                        let e = btns[i];
                        if (e.classList.contains("active")) e.classList.remove("active");
                    };
                    if (ev.target.nodeName == "I") ev.target.parentElement.classList.add("active");
                    else ev.target.classList.add("active");
                });
                let icon = createElementAndClassList("i", ["fa-solid", "fa-chart-bar"]);
                chartbutton.appendChild(icon);

                if (response[key].data.signal == 1) {
                    if (button.classList.contains("text-bg-success")) {
                        button.classList.remove("text-bg-success");
                        button.classList.add("text-bg-warning");
                        button.textContent = "Active strategy \"" + strategy + "\" shows metrics signal";
                    }                    
                }
                divblock.append(divblock2, chartbutton);
                r.table.appendChild(divblock);
                r.table.appendChild($E("hr"));
            } else {
                if (response[key]) {
                    console.log(response[key]["return code"] + ": " + response[key].details);
                    console.log(response[key].message);
                } else {
                    console.log(response["return code"] + ": " + response.details);
                    console.log(response.message);
                    console.log(response);
                }
            }
        });
    }

    static fillPredictionSignals(signals) {
        let content;
        let number = 0;
        signals.forEach((item, i) => {
            if (item.type == "model") {
                number += 1;
                if (number == 1) content = cleanArea("signalsModel");
                let listitem = createElementAndClassList("li", ["list-group-item", "text-bg-warning"]);
                listitem.textContent = capFirstLetter(item.category) + " signals unknown data distribution in interval " + "\"" + capFirstLetter(item.interval) + "\"" + " compared to training";
                content.appendChild(listitem);
            }

        });
        $("modeltotal").innerHTML = number;
    }

    static renderSignals(signals, parent) {
        Object.keys(signals).forEach((v) => {
            let col = createElementAndClassList("div", "col");
            let card;
            switch (v) {
                case "Box":
                    card = new iqrRenderer(v, signals[v]).renderSignal();
                    break;
                case "Outlier":
                    card = new outliersRenderer(v, signals[v]).renderSignal();
                    break;
                case "Variable":
                    card = new behaviourRenderer(v, signals[v]).renderSignal();
                    break;
                case "Constant":
                    card = new behaviourRenderer(v, signals[v]).renderSignal();
                    break;
            }
            col.appendChild(card);
            parent.appendChild(col);
        });
    }
}

class SignalRenderer {
    constructor(key, name, imgsrc) {
        this.key = key;
        this.name = name;
        this.imgsrc = imgsrc;
    }

    addContent(content) {
        this.content = content;
    }

    renderSignal(elem, subtitle) {
        let card = createElementAndClassList("div", ["card", "h-100"]);
        let cardbody = createElementAndClassList("div", ["card-body"]);
        let titcont = createElementAndClassList("div", ["d-flex", "justify-content-between"]);
        let titcontleft = createElementAndClassList("div", ["container", "p-0"]);
        let title = createElementAndClassList("h5", "card-title");
        title.textContent = this.name;
        titcontleft.appendChild(title);

        if (subtitle !== undefined) {
            let sub = createElementAndClassList("h6", ["card-subtitle", "mb-2", "text-body-secondary"]);
            sub.textContent = subtitle;
            titcontleft.appendChild(sub);
        }
        titcont.append(titcontleft);
        if (this.imgsrc !== "") {
            let icon = createElementAndClassList("img", "img-thumbnail");
            icon.src = this.imgsrc;
            icon.width = 70;
            titcont.appendChild(icon);
        }
        cardbody.appendChild(titcont);
        if (elem !== undefined) cardbody.append(elem);
        card.appendChild(cardbody);
        return card;
    }
}
class iqrRenderer extends SignalRenderer {
    constructor(key, content) {
        super(key, "Boxplot Check", "../images/boxplotlow.png");
        this.addContent(content);
        this.sections = ["above", "below"];
        this.sectionicons = [["fa-solid", "fa-arrows-up-to-line", "me-2"], ["fa-solid", "fa-arrows-down-to-line", "me-2"]];
    }

    renderSignal() {
        let ul = createElementAndClassList("ul", ["list-group", "h-100"]);
        this.sections.forEach((v, i) => {
            let item = createElementAndClassList("li", ["list-group-item", "text-break"]);
            let icon = createElementAndClassList("i", this.sectionicons[i]);
            let itemul = createElementAndClassList("ul", "list-group");
            item.append(
                icon,
                $T("Sensors " + v + " training quantiles: "),
                itemul
            );
            if (this.content[v].length > 0) {
                this.content[v].forEach((v) => {
                    createListElementWithLink(itemul, v, "plantpart=" + v);
                })
                ul.appendChild(item);
            }
        });
        let card = super.renderSignal($T("Interquantile Level Comparison"));
        card.appendChild(ul);
        return card;
    }
}
class outliersRenderer extends SignalRenderer {
    constructor(key, content) {
        super(key, "Outlier Check", "../images/" + key + ".png");
        this.addContent(content);
    }

    renderSignal() {
        let ul = createElementAndClassList("ul", ["list-group", "h-100"]);
        Object.keys(this.content).forEach((v) => {
            if (v !== "signal") createListElementWithLink(ul, v + " has " + this.content[v] + " outliers", "plantpart=" + v);
        });
        let card = super.renderSignal();
        card.appendChild(ul);
        return card;
    }
}
class behaviourRenderer extends SignalRenderer {
    constructor(key, content) {
        super(key, "Sensor Behaviour", "../images/" + key + ".png");
        this.addContent(content);
        this.opposite = key == "constant" ? "variable" : "constant";
    }

    renderSignal() {
        let card = super.renderSignal(
            $T("Sensors have been observed as " + this.key + ", but now seem to be " + this.opposite + "."),
        );
        let ul = createElementAndClassList("ul", ["list-group", "h-100"]);
        Object.keys(this.content).forEach((v) => {
            if (v !== "signal")
                for(let i = 0; i < this.content[v].length; i++) {
                    createListElementWithLink(ul, this.content[v][i], "plantpart=" + v);
                }
        });
        card.appendChild(ul);
        return card;
    }
}
class metricRenderer extends SignalRenderer {
    constructor(key, content) {
        super(key, "Metric Check: " + key, "");
        if (
            Object.keys(content).includes("kpis") &&
            Object.keys(content).includes("basis") &&
            Object.keys(content).includes("scores")
        ) {
            this.addContent(content);
            if ($("metricActive")) {
                this.container = $("metricActive");
                this.container.parentElement.getElementsByTagName("h5")[0].textContent = "Metric Check: " + key;
            }
            else {
                this.container = createElementAndClassList("div", ["container", "p-0"]);
                this.container.id = "metricActive";
            }            
        }
        else throw new TypeError("metricRenderer requires all of kpis, basis & scores as keys of object content and all of type Array");
    }

    renderSignal(body) {
        let card = super.renderSignal(this.container);
        let row = createElementAndClassList("div", ["row", "row-cols-1", "row-cols-xl-2", "g-2"]);
        let cardcol = createElementAndClassList("div", ["col", "col-xl-6", "col-12", "p-1"]);
        let tabcol = createElementAndClassList("div", ["col", "col-xl-6", "col-12", "p-1"]);
       
        row.append(tabcol, cardcol);
        cardcol.appendChild(card);
        let tabcard = createElementAndClassList("div", ["card", "h-100"]);
        let tabbody = createElementAndClassList("div", ["card-body", "p-1"]);
        tabcard.appendChild(tabbody);
        tabcol.appendChild(tabcard);
        body.appendChild(row);
        this.showMetricIndicators()
        return { card: card, table: tabbody, content: this.content };
    }

    showMetricIndicators() {
        var data = [];
        let row = 0;
        let col = 0;
        let kpis = this.content.kpis;
        let basis = this.content.basis;
        let scores = this.content.scores;
        kpis.forEach((kpi) => {
            let min = basis[kpi.name] < scores[kpi.name] ? basis[kpi.name] : scores[kpi.name];
            let minimizer = min < -10 ? 10 : 1; 
            let bigimizer = min < -100 ? 100 : 1;
            min = min < -1 ? Math.floor(min) - bigimizer - minimizer : -1;
            let mid = kpi.short == "r2" ? 0 : -1;
            let max = kpi.short == "r2" ? 1 : 0;
            let range1 = kpi.short == "r2" ? [min, mid] : [min, min];
            let range2 = kpi.short == "r2" ? [mid, max] : [min, max];
            data.push({
                domain: { row: row, column: col },
                value: scores[kpi.name],
                title: { text: kpi.short, font: {size: 20} },
                type: "indicator",
                mode: "gauge+number+delta",
                delta: { 
                    decreasing: { symbol: "\u0394 "},
                    increasing: { symbol: "\u0394 "},
                    reference: basis[kpi.name], 
                    font: { size: 15} 
                },
                number: { font: {size: 20}},
                gauge: {
                    shape: "bullet",
                    axis: { range: [min, max] },
                    threshold: {
                        line: {
                            color: "red",
                            width: 4
                        },
                        thickness: 1,
                        value: basis[kpi.name]
                    },
                    steps: [
                        { range: range1, color: "lightgray" },
                        { range: range2, color: "white" }
                    ],
                }
            });
            row += 1;
        });

        var layout = {
            autosize: true, 
            grid: { 'rows': row, 'columns': 1, 'pattern': 'independent' },
            margin: {
                autoexpand: false,
                l: 60,
                b: 30,
                t: 0,
                r: 10
            }
        };
        Plotly.newPlot(this.container.id, data, layout, new PlotlyConfig().getConfig(""));
    }
}