import { $, $E, $T, cleanArea, createComplexLink, createElementAndClassList, createsetAttribute, safeAppend } from "../helper.mjs";
import { myEvent } from "../index.mjs";
import { callMe } from "../mybackend.mjs";
import { MySystems } from "./a_dataing.mjs";
import { Status } from "../core/monitoredobject.mjs";
import { Strategy } from "../core/strategy.mjs";


async function showModels(models, mysystem) {
    let container = cleanArea("molist");
    let monitoredobjects = [];
    let moselect = cleanArea("MOSelect");
    let all = $E("option");
    all.value = "all";
    all.textContent = "All";
    moselect.appendChild(all);

    for (let m in models) {
        let model = models[m];

        if (!monitoredobjects.includes(model.monitored_object)) {
            monitoredobjects.push(model.monitored_object);
            let option = $E("option");
            option.value = model.monitored_object;
            option.textContent = model.monitored_object;
            moselect.appendChild(option);
        }

        let modelcard = createElementAndClassList("div", ["card", "mt-3", model.monitored_object]);

        let cardrow = createElementAndClassList("div", ["row", "g-0"]);
        let cardcol1 = createElementAndClassList("div", "col-md-3");

        let modelhead = createElementAndClassList("h5", ["card-title", "ms-2"]);
        let link = createComplexLink([], undefined, $T(model.monitored_object), "mo=" + model.monitored_object + "," + mysystem);
        modelhead.appendChild(link);
        modelhead.appendChild($T("-" + model.strategy));
        cardcol1.appendChild(modelhead);

        let ul = createElementAndClassList("ul", "list-group");
        cardcol1.appendChild(ul);

        let modelref = createElementAndClassList("div", "list-group-item");
        modelref.textContent = "Reference: " + model.reference;
        ul.appendChild(modelref);

        let index = 0;
        for (let i in Status.labels) {
            if (Status.labels[i] == model.object_state) {
                index = i;
                break;
            }
        }
        let modelstateimg = createElementAndClassList("i", Status.images[index]);
        modelstateimg.classList.add("fa-lg");
        let modelstate = createElementAndClassList("div", "list-group-item");
        let currentstate = $E("span");
        currentstate.id = "currentstate" + model.monitored_object + model.strategy;
        currentstate.value = model.object_state;
        currentstate.textContent = model.object_state;
        modelstate.append($T("State: "), modelstateimg, $T(" "), currentstate);
        let vr = createElementAndClassList("div", ["vr", "me-2", "ms-2"]);
        vr.style = "height: 1.25em; position: relative; bottom: -0.25em";
        modelstate.appendChild(vr);
        let retrain = new Strategy(mysystem, model.monitored_object).getRetrainButton(model.strategy, "a_model");
        modelstate.appendChild(retrain);

        ul.appendChild(modelstate);

        cardrow.appendChild(cardcol1);

        let cardcol2 = createElementAndClassList("div", "col-md-9");
        let modelbody = createElementAndClassList("div", "card-body");
        modelbody.id = model.monitored_object + "-" + model.strategy;
        cardcol2.appendChild(modelbody);

        cardrow.appendChild(cardcol2);

        modelcard.appendChild(cardrow);

        container.appendChild(modelcard);
    }

    myEvent.attach(moselect, "change", (opt) => {
        for (let item of monitoredobjects) {
            for (let elem of document.getElementsByClassName(item)) { elem.classList.add("d-none"); }
        }
        if (opt.target.value != "") {
            for (let elem of document.getElementsByClassName(opt.target.value)) { elem.classList.remove("d-none"); }
        } else
            for (let item of monitoredobjects) {
                for (let elem of document.getElementsByClassName(item)) { elem.classList.remove("d-none"); }
            }
    });

    function checkUsage(sensor, usage) {
        for (let u in usage) {
            if (usage[u].sensor == sensor) {
                if (usage[u]["tier: monitored"]) return "monitored object";
                if (usage[u]["tier: initial"]) return "initial model";
                if (usage[u]["tier: trained"]) return "trained model";
                return "";
            }
        }
    }

    for (let m in models) {
        let model = models[m];

        try {
            let est = await callMe(
                "/blackbox/estimator",
                "POST",
                {system: mysystem, model: model.monitored_object, strategy: model.strategy}
            );
            console.log(est);
            let estimator = est["data"];
            let features = estimator["features"];
            let responses = estimator["responses"];
            let events = estimator["scoring"]["events"];
            let scores = estimator["scoring"]["scores"];
            let usage = estimator["used sensors"];

            let body = $(model.monitored_object + "-" + model.strategy);
            let bodyrow = createElementAndClassList("div", "row");
            safeAppend(body, bodyrow);

            let wrapf = createElementAndClassList("div", ["col", "features", "d-none"]);
            let h6f = $E("h6");
            h6f.textContent = "Features (Usage)";
            wrapf.appendChild(h6f);
            let ulf = createElementAndClassList("ul", "list-group");
            for (let f in features) {
                let li = createElementAndClassList("li", "list-group-item");
                li.textContent = features[f] + " (" + checkUsage(features[f], usage) + ")";
                ulf.appendChild(li);
            }
            wrapf.appendChild(ulf);
            bodyrow.appendChild(wrapf);

            let wrapr = createElementAndClassList("div", ["col", "responses", "d-none"]);
            let h6r = $E("h6");
            h6r.textContent = "Responses (Usage)";
            wrapr.appendChild(h6r);
            let ulr = createElementAndClassList("ul", "list-group");
            for (let r in responses) {
                let li = createElementAndClassList("li", "list-group-item");
                li.textContent = responses[r] + " (" + checkUsage(responses[r], usage) + ")";;
                ulr.appendChild(li);
            }
            wrapr.appendChild(ulr);
            bodyrow.appendChild(wrapr);

            let wraps = createElementAndClassList("div", ["col", "scores"]);
            let h6s = $E("h6");
            h6s.textContent = "Scores & Thresholds";
            wraps.appendChild(h6s);
            let tables = createElementAndClassList("table", "table");
            let thead = $E("thead");
            let tr = $E("tr");
            let thstate = $E("th");
            createsetAttribute(thstate, "scopes", "col");
            thstate.textContent = "State";
            let thmetr = $E("th");
            createsetAttribute(thmetr, "scopes", "col");
            thmetr.textContent = "Metric";
            let thsens = $E("th");
            createsetAttribute(thsens, "scopes", "col");
            thsens.textContent = "Sensor";
            let thvalue = $E("th");
            createsetAttribute(thvalue, "scopes", "col");
            thvalue.textContent = "Value";
            tr.append(thstate, thmetr, thsens, thvalue);
            thead.appendChild(tr);
            tables.appendChild(thead);

            let tbody = $E("tbody");
            for (let s in scores) {
                let tr = $E("tr");
                let sparts = s.split(",", 3);
                let tdstat = $E("td");
                tdstat.textContent = sparts[0];
                let tdmetr = $E("td");
                tdmetr.textContent = sparts[1];
                let tdsens = $E("td");
                tdsens.textContent = sparts[2];
                let tdvalue = $E("td");
                tdvalue.textContent = scores[s].toFixed(3);
                tr.append(tdstat, tdmetr, tdsens, tdvalue);

                tbody.appendChild(tr);
            }
            tables.appendChild(tbody);

            wraps.appendChild(tables);
            bodyrow.appendChild(wraps)
        }
        catch (error) {
            console.warn("Get Estimator Error, check details");
            console.info(error);
        }
    }
}

export function starta_model() {
    console.log("starta_model");
    let sys = new MySystems();
    let mysystem = sys.fillSystemSelector("systemSelect");
    myEvent.attach(sys.select, "change", async () => {
        sys.mysystem = sys.select.value;
        callMe(
            "/blackbox/molist",
            "POST",
            { model: "", type: "registration", status: null, system: sys.mysystem }
        )
            .then((models) => { showModels(models["data"], sys.mysystem); })
    });

    let buttons = ["features", "responses", "scores"];
    for (let bname of buttons) {
        let button = createElementAndClassList("button", ["btn", "btn-outline-primary"])
        button.type = "button";
        button.id = bname;
        createsetAttribute(button, "data-bs-toggle", "button");
        myEvent.attach(button, "click", (pointer) => {
            if (pointer.srcElement.classList.contains("active"))
                for (let elem of document.getElementsByClassName(bname)) { elem.classList.remove("d-none"); }
            else
                for (let elem of document.getElementsByClassName(bname)) { elem.classList.add("d-none"); }
        });
        button.textContent = bname[0].toUpperCase() + bname.slice(1);
        $("amodelbtnform").appendChild(button);
    }

    callMe(
        "/blackbox/molist",
        "POST",
        { model: "", type: "registration", status: null, system: sys.mysystem }
    )
        .then((models) => {
            let button = $("scores");
            createsetAttribute(button, "aria-pressed", "true");
            button.classList.toggle("active");
            showModels(models["data"], sys.mysystem);
        })
}

export function destroya_model() {
    myEvent.remove($("systemSelect"), "click");

    let buttons = ["features", "responses", "scores"];
    for (let bname of buttons) {
        myEvent.remove($(bname), "click");
    }
}