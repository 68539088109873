/* general methods relevant for all micro visuals, except activation  */

import { navigate, addSensorToDict, myEvent } from "./index.mjs";

export var $ = (id) => { return document.getElementById(id); };
export var $T = (text) => { return document.createTextNode(text); };
export var $E = (elem) => { return document.createElement(elem); }

export function injectEscape() {
    /*
        unescape strings
    */
    var tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;'
    };
    return String(this.replace(/[&<>]/g, function (tag) {
        return tagsToReplace[tag] || tag;
    }));
}

String.prototype.escape = injectEscape;

export function checkDebug(debug=1) {
    if ($("debug").value == debug && debug > 0)
        return true;
    else
        return false;
}

class Helper {
    constructor() { }
}
class Configurator extends Helper {
    constructor() {
        super();
        this.t = new Date(); // for timestamp in filename
        this.config = {};
    }

    getConfig() {
        return this.config;
    }
}
export class PlotlyConfig extends Configurator {
    constructor() {
        super();
    }
    getConfig(title) {
        this.config = {
            //modeBarButtonsToRemove: ['zoom2d', 'pan2d', 'select2d', 'zoomIn2d', 'zoomOut2d', 'lasso2d', 'autoScale2d', 'resetScale2d', 'hoverClosestGl2d', 'hoverClosestPie', 'toggleHover', 'resetViews', 'sendDataToCloud', 'editInChartStudio', 'toggleSpikelines', 'resetViewMapbox'],
            modeBarButtonsToRemove: ['zoomIn2d', 'zoomOut2d', 'sendDataToCloud', 'editInChartStudio'],
            responsive: true,
            title: title == "" ? undefined : title,
            displaylogo: false,
            toImageButtonOptions: {
                format: 'png', // one of png, svg, jpeg, webp
                filename: title + "_" + this.t.getFullYear() + (this.t.getMonth() + 1) + this.t.getDate() + "-" + this.t.getHours() + ":" + this.t.getMinutes(),
                height: 400, // actual size rouhgly 130
                width: 800, // actual size rouhgly 200
                scale: 2 // Multiply title/legend/axis/canvas sizes by this factor
            }
        }
        return super.getConfig();
    }

    static getZIcon() {
        return {
            width: 576,
            height: 512,
            path: "M137.4 502.6c12.5 12.5 32.8 12.5 45.3 0l96-96c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7V288H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H448V109.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-96-96c-12.5-12.5-32.8-12.5-45.3 0l-96 96c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L384 109.3V224H192 128 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96V402.7L86.6 361.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l96 96zM128 192h64V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V192zM448 320H384V448c0 17.7 14.3 32 32 32s32-14.3 32-32V320z"
        }
    }
}

export class EventList extends Helper {
    constructor() {
        super();
        this.eventList = [];
    }

    attach(element, handler, callback) {
        let attach = false;
        if (!element.hasAttribute("listener" + handler)) {
            createsetAttribute(element, "listener" + handler, true);
            attach = true;
        } else if (element.getAttribute("listener" + handler) == "false") attach = true;
        if (attach) {
            element.addEventListener(handler, callback);
            this.eventList.push(new Object({ element: element, handler: handler, callback: callback }));
            return true;
        } else return false;
    }

    remove(element, handler) {
        for (let i=0; i < this.eventList.length; i++) {
            if (this.eventList[i].element == element && this.eventList[i].handler == handler) {
                element.removeEventListener(handler, this.eventList[i].callback);
                element.setAttribute("listener" + handler, false);
                this.eventList.splice(i, 1);
                break;
            }
        }
    }
}

export class TrainError extends Error {
    constructor(data) {
        super(data.message)
        this.code = data.code;
        this.message = data.message;
        this.status = data.status;
    }
}

export class ListTools extends Helper {
    constructor() {
        super();
        this.dict = {};
        this.list = "";
        this.list_sum = "";
        this.opt = "";
    }

    setConfig(list, list_sum, dict, opt) {
        this.dict = dict;
        this.list = list;
        this.list_sum = list_sum;
        this.opt = opt;
        this.config = {
            "dict": this.dict,
            "list": this.list,
            "list_sum": this.list_sum,
            "opt": this.opt
        };
    }

    removeSensor(del) {
        let remove;
        if (del.srcElement.nodeName == "BUTTON") remove = del.srcElement.parentNode;
        else remove = del.srcElement.parentElement.parentNode;
        this.list.removeChild(remove);
        for (let sli of this.list_sum.getElementsByTagName("li")) {
            if (sli.textContent.includes(remove.textContent))
                this.list_sum.removeChild(sli);
        }
        delete this.dict[this.opt.srcElement.value];
    }

    addSensor(add = true, opt = undefined) {
        if (opt !== undefined) {
            this.opt = opt;
            this.config.opt = this.opt;
        }
        let li = createElementAndClassList("li", ["list-group-item", "fs-6"]);
        li.textContent = this.opt.srcElement.text;
        let li2 = li.cloneNode(true)
        let icButton = createElementAndClassList("button", ["btn", "btn-secondary", "ms-5"])
        icButton.type = "button"
        let icon = createElementAndClassList("i", ["fa-regular", "fa-trash-can", "fa-xs"])
        icButton.appendChild(icon)
        icButton.addEventListener("click", (del) => { this.removeSensor(del); });
        li.appendChild(icButton)
        this.list.appendChild(li);
        this.list_sum.appendChild(li2);
        let rText = this.opt.srcElement.text.indexOf(" - ") >= 0 ? this.opt.srcElement.text.substring(this.opt.srcElement.text.indexOf(" - ") + 3) : "";
        if (add) addSensorToDict(this.dict, this.opt.srcElement.value, rText);
    }
}

export function safeAppend(content, elem) {
    try {
        content.appendChild(elem);
    } catch (error) {
        console.warn("potential site change, async data from previous page cannot arrive");
        console.info(error);
    }
}

export function capFirstLetter(text) {
    return text.charAt(0).toUpperCase().concat(text.slice(1));;
}

export function cleanArea(id) {
    let content = $(String(id).escape());
    if (content) content.textContent = "";
    if (content) while (content.hasChildNodes()) content.removeChild(content.firstChild);
    return content;
}

export function createsetAttribute(elem, attribute, value) {
    elem.setAttributeNode(document.createAttribute(attribute));
    elem.setAttribute(attribute, value);
}

export function createElementAndClassList(tag, classList) {
    let myclasslist = Array.isArray(classList) ? classList : [classList];
    let elem = $E(tag);
    elem.classList.add(...myclasslist);
    return elem;
}

export function updateOnChange(source, target) {
    myEvent.attach($(source), "change", ()=> {
        $(target).value = $(source).value;
    });
}

export function createListElementWithLink(list, text, data, icon=undefined) {
    let li = createElementAndClassList("li", ["list-group-item", "text-break"]);
    let link = createComplexLink(["link-opacity-100", "icon-link"], icon, $T(text), data);
    li.appendChild(link);
    safeAppend(list, li);
}

export function createComplexLink(classList, icon, textNode, data) {
    let link = createElementAndClassList("a", classList);
    link.href = "#";
    if (icon) link.appendChild(icon);
    link.appendChild(textNode);
    createsetAttribute(link, "data", data);
    link.addEventListener("click", navigate);
    return link;
}

export function checkData(data, elem) {
    try {
        if (data.hasOwnProperty("return code") && data["return code"] != 0) {
            let msg = createElementAndClassList("div", "text-bg-warning");
            msg.appendChild($T("Messages:"));
            elem.appendChild(msg) 
            writeMessages(data.message, elem)
            return false;
        } else if (data.hasOwnProperty("error")) {
            elem.innerText = "Data for visual is not available.\nMessage: " + data.error;
            return false;
        } else return true;
    } catch (e) { console.log(e); }
}

export function writeMessages(messages, elem) {
    messages.forEach((v, i, a) => {
        let msg = createElementAndClassList("div", "text-bg-info");
        msg.appendChild($T(v));
        elem.appendChild(msg);
    })
}

export function prettyJsonPrint(id) {
    var ugly = $(id).value;
    var obj = JSON.parse(ugly);
    var pretty = JSON.stringify(obj, undefined, 4);
    $(id).value = pretty;
}

export class Spinner {
    constructor() {
        /*<div class="spinner-border text-dark" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>*/
        this.loading = createElementAndClassList("div", ["spinner-border", "text-primary"]);
        createsetAttribute(this.loading, "role", "status");
        let text = createElementAndClassList("span", "visually-hidden");
        text.textContent = "Loading...";
        this.loading.appendChild(text);
    }
    getLoadingElement() {
        return this.loading;
    }
}
export class Progress extends Spinner {
    /*<div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
        <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 75%"></div>
    </div>*/
    constructor() {
        super();
        this.loading = createElementAndClassList("div", ["progress", "height3em", "mb-3"]);
        createsetAttribute(this.loading, "role", "progressbar");
        createsetAttribute(this.loading, "aria-label", "Animated striped progress bar");
        createsetAttribute(this.loading, "aria-valuenow", "0");
        createsetAttribute(this.loading, "aria-valuemin", "0");
        createsetAttribute(this.loading, "aria-valuemax", "100");
        this.bar = createElementAndClassList("div", ["progress-bar", "progress-bar-striped", "progress-bar-animated", "prog0"]);
        this.loading.appendChild(this.bar);
    }

    updateValue(label, value) {
        let proglist = ["prog0","prog10","prog20","prog30","prog40","prog50","prog60","prog70","prog80","prog90","prog100"];
        if (value % 10 == 0) {
        this.loading.setAttribute("aria-valuenow", value);
            proglist.forEach((prog) => {
                if (this.bar.classList.contains(prog)) {
                    this.bar.classList.remove(prog);
                }
            });
            this.bar.classList.add("prog"+value)
            this.bar.style.width = value + "%";
            this.bar.textContent = label;
        }
    }

}