import { $, $T, $E, cleanArea, createElementAndClassList, injectEscape } from "../helper.mjs";
import { myEvent } from "../index.mjs";
import { callMe } from "../mybackend.mjs";
import { gLoginState } from "../mymsal.mjs";

String.prototype.escape = injectEscape;
let mysystem;
let workers = [];

export class MySystems {
    constructor() {
        this.mysystem;
        this.plants;
        this.select;
    }

    fillSystemSelector(id) {
        this.plants = gLoginState.getAuthorizedPlants();
        this.select = $(id);
        for (let p in this.plants) {
            let option = $E("option");
            option.value = this.plants[p].System;
            option.textContent = this.plants[p].NameKey;
            this.select.appendChild(option);
        }
        this.mysystem = this.select.value;
        return this.mysystem;
    }
}

export function fillSystemSelector() {
    let plants = gLoginState.getAuthorizedPlants();
    console.log(plants);
    let select = $("systemSelect");
    for (let p in plants) {
        let option = $E("option");
        option.value = plants[p].System;
        option.textContent = plants[p].NameKey;
        select.appendChild(option);
    }
    select.addEventListener("change", () => { mysystem = select.value; });
    return select.value;
}

async function fillMOSelector() {
    let data = await callMe(
        "/blackbox/molist", "POST", { model: "", type: "definition", state: null, system: mysystem }
    );
    let select = $("moSelect");
    for (let d in data["data"]) {
        let line = data["data"][d];
        if (line["type"] !== undefined && line["type"] == "monitored object") {
            let option = $E("option");
            option.value = line.id;
            option.textContent = line.name;
            select.appendChild(option);
        }
    };
}

async function uploadToBlackBox(container, blobname = "") {
    let interval = blobname.substring(blobname.lastIndexOf("_") + 1, blobname.indexOf(".", blobname.lastIndexOf("_")));
    console.log(blobname + " - " + interval);
    $("containerMessages").value += "Blob will now be sent to Endpoint upload/actuals " + "\n";
    try {
        let response = await callMe("/blackbox/timeseries/uploadFromBlob", "POST",
            { system: parseInt(mysystem, 10), container: container, interval: interval, sources: blobname },
            false, true, true
        );
        $("containerMessages").value += JSON.stringify(response) + "\n";
    } catch (e) {
        $("containerMessages").value += e + "\n";
    }
    refreshContainers();
}

async function deleteBlob(container, blobname) {

    $("containerMessages").value += "Blob will now be marked for deletion " + "\n";
    let response = await callMe(
        "/blob/delete",
        "POST",
        { account: gLoginState.currentEnv.ingeststorage, container: container, blobname: blobname }
    );
    $("containerMessages").value += JSON.stringify(response) + "\n";
    $("containerMessages").value += blobname + " was deleted " + "\n";
    refreshContainers();
}

async function downloadBlob(container, blobname) {

    $("containerMessages").value += "Blob download will now start " + "\n";
    // change to blob.download like in https://medium.com/@idorenyinudoh10/how-to-export-data-from-javascript-to-a-csv-file-955bdfc394a9
    //https://stackoverflow.com/questions/64443295/fetch-image-from-azure-blob-and-send-it-to-client-without-saving-locally-using-n

    // Create a link element
    const link = $E("a");
    let response = await callMe(
        "/blob/download",
        "POST",
        { account: gLoginState.currentEnv.ingeststorage, container: container, blobname: blobname }, false, true
    );
    // Set link's href to point to the Blob URL
    link.href = decodeURIComponent(String(response.url).escape());
    link.download = blobname;
    link.target = "_download";
    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        })
    );
    // Remove link from body
    document.body.removeChild(link);
    $("containerMessages").value += "Download for " + blobname + " finished" + "\n";
    // clean up Url
    refreshContainers();
}

function fillContainerContents(data) {
    data = Array.isArray(data) ? data : [data];
    ["actuals", "training-samples", "failed"].forEach((container) => {
        let content = $(container);
        let ul = createElementAndClassList("ul", "list-group");
        let one = false
        data.forEach((blob) => {
            if (blob.Container == container) {
                let li = createElementAndClassList("li", ["list-group-item", "d-flex", "justify-content-between"]);
                li.textContent = blob.Blob;
                let span = $E("span");
                if (container == "actuals" || container == "training-samples") {
                    let buttons = [{ "id": "upload", "label": "Upload to BlackBox", "style": ["fa-solid", "fa-cloud-arrow-up"] }, { "id": "download", "label": "Download", "style": ["fa-solid", "fa-cloud-arrow-down"] }, { "id": "delete", "label": "Delete", "style": ["fa-regular", "fa-trash-can"] }];
                    buttons.forEach((button) => {
                        let btn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-primary", "icon-link", "me-2"]);
                        btn.type = "button";
                        btn.id = button.id;
                        if (btn.id == "upload") myEvent.attach(btn, "click", () => { uploadToBlackBox(container, blob.Blob); });
                        if (btn.id == "delete") myEvent.attach(btn, "click", () => { deleteBlob(container, blob.Blob); });
                        if (btn.id == "download") myEvent.attach(btn, "click", () => { downloadBlob(container, blob.Blob); });
                        btn.appendChild(createElementAndClassList("i", button.style));
                        btn.appendChild($T(button.label));
                        span.appendChild(btn);
                    })
                }
                if (container == "failed") {
                    let buttons = [{ "id": "delete", "label": "Delete", "style": ["fa-regular", "fa-trash-can"] }, { "id": "download", "label": "Download", "style": ["fa-solid", "fa-cloud-arrow-down"] }];
                    buttons.forEach((button) => {
                        let btn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-primary", "icon-link", "me-2"]);
                        btn.type = "button";
                        btn.id = button.id;
                        if (btn.id == "delete") myEvent.attach(btn, "click", () => { deleteBlob(container, blob.Blob); });
                        if (btn.id == "download") myEvent.attach(btn, "click", () => { downloadBlob(container, blob.Blob); });
                        btn.appendChild(createElementAndClassList("i", button.style));
                        btn.appendChild($T(button.label));
                        span.appendChild(btn);
                    })
                }
                li.appendChild(span);
                ul.appendChild(li);
                one = true
            }
        });
        if (!one) {
            let msg = createElementAndClassList("div", "container");
            msg.textContent = "Container seems to be empty";
            content.appendChild(msg);
        } else {
            content.appendChild(ul);
        }

    });
}

async function refreshContainers() {
    cleanArea("actuals");
    cleanArea("failed");
    cleanArea("training-samples");

    if (mysystem !== "undefined") {
        fillContainerContents(await callMe(
            "/blob/container/contents",
            "POST",
            { account: gLoginState.currentEnv.ingeststorage }
        ));
        $("containerMessages").value += "Containers refreshed" + "\n";
    }
}

export async function starta_dataing() {

    console.log("starta_dataing");
    let sys = new MySystems();
    mysystem = sys.fillSystemSelector("systemSelect");
    myEvent.attach(sys.select, "change", () => { sys.mysystem = sys.select.value; });
    if (mysystem !== "undefined") {
        fillContainerContents(await callMe(
            "/blob/container/contents",
            "POST",
            { account: gLoginState.currentEnv.ingeststorage }
        ));
    }
    fillMOSelector();

    myEvent.attach($("triggerFullIngest"), "click", async () => {
        $("ingestMessages").value += "Start complete Ingest" + "\n";
        let response = await callMe("/ingest/triggerExport", "POST", { model: [""], container: "actuals" });
        $("ingestMessages").value += JSON.stringify(response) + "\n";
    });

    myEvent.attach($("triggerMOIngest"), "click", async () => {
        let mo = $("moSelect").value;
        $("ingestMessages").value += "Start actuals ingest for " + mo + "\n";
        let response = await callMe("/ingest/triggerExport", "POST", { model: mo, container: "actuals" });
        $("ingestMessages").value += JSON.stringify(response) + "\n";
    });

    myEvent.attach($("triggerMOTrainingIngest"), "click", async () => {
        let mo = $("moSelect").value;
        $("ingestMessages").value += "Start training-samples ingest for " + mo + "\n";
        let response = await callMe("/ingest/triggerExport", "POST", { model: mo, container: "training-samples" });
        $("ingestMessages").value += JSON.stringify(response) + "\n";
    });

    myEvent.attach($("refreshContainers"), "click", refreshContainers);
}

export function destroya_dataing() {
    mysystem = undefined;
    myEvent.remove($("systemSelect"), "click");
    myEvent.remove($("triggerFullIngest"), "click");
    myEvent.remove($("triggerMOIngest"), "click");
    myEvent.remove($("triggerMOTrainingIngest"), "click");
    myEvent.remove($("refreshContainers"), "click");
}