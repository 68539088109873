import { navigate, SYSTEMDATA } from "../index.mjs";
import { $, $E, $T, cleanArea, createElementAndClassList, createListElementWithLink, createsetAttribute, checkDebug, writeMessages } from "../helper.mjs"
import { gLoginState } from "../mymsal.mjs";
import { SignalView } from "../core/signals.mjs";
import { PlantPartTimeSeries } from "./timeseries.mjs";

export const GROUP2PLANT = {
    EDLPD_GEI_Acetylene_Viewer: "GEI_AcetylenePl",
    EDLPD_GEI_Acetylene_Admin: "GEI_AcetylenePl",
    EDLPD_GEI_Util_Viewer: "GEI_Util",
    EDLPD_GEI_Util_Admin: "GEI_Util",
    "SA-FUN-SA-TGU": "BR1G_Util",
    "Production AI in SA": "BR1G_Util"
}

export const PLANTS = {
    GEI_AcetylenePl: {
        "AreaKey": "GEI_AcetylenePl",
        "NameKey": "GEI_AcetylenePl - Acetylene Plant",
        "Name": "Acetylene Plant",
        "Menu": "Geismar, Acetylene Plant",
        "Prefix": "ACT_",
        "File": "us_geismar.csv",
        "Viewer": "EDLPD_GEI_Acetylene_Viewer",
        "Admin": "EDLPD_GEI_Acetylene_Admin",
        "System": 10,
        "MenuId": "GeiACT",
        "ShPoint": "https://basf.sharepoint.com/teams/GeismarAcetyleneFormic/PIDs/Forms/AllItems.aspx?viewid=c81057a7%2Ded40%2D4012%2Da552%2D199652c6faea&view=7&q=",
        "VIP": "ACT_FY_T375_01A.PNT",
        "VIPname": "ACETYLENE TO D385 STONS/DAY",
        "Path": "config-us-geismar.csv"
    },
    BR1G_Util: {
        "AreaKey": "BR1G_Util",
        "NameKey": "BR1G_Util - Energy & Utilities",
        "Name": "Utilities",
        "Menu": "Guaratanguetá, Energy & Utilities",
        "Prefix": "",
        "File": "br_guara.csv",
        "Viewer": "SA-FUN-SA-TGU",
        "Admin": "Production AI in SA",
        "System": 20,
        "MenuId": "BR1GUtil",
        "ShPoint": "",
        "VIP": "",
        "VIPname": "",
        "Path": "config-br-guara.csv"

    },
    GEI_Util: {
        "AreaKey": "GEI_Util",
        "NameKey": "GEI_Util - Utilities",
        "Name": "Utilities",
        "Menu": "Geismar, Waste Water & Utilities",
        "Prefix": "WWT_",
        "File": undefined,
        "Viewer": "EDLPD_GEI_Util_Viewer",
        "Admin": "EDLPD_GEI_Util_Admin",
        "System": undefined,
        "MenuId": undefined,
        "ShPoint": "",
        "VIP": "",
        "VIPname": "",
    }
}

export const SENSORMAPPING = {
    "A": {
        "label": "Analysis",
        "icon": "fa-microchip",
        "uom": ""
    },
    "B": {
        "label": "Boolean state",
        "icon": ["fa-solid", "fa-yin-yang"],
        "uom": "on/off"
    },
    "D": {
        "label": "Duration",
        "icon": ["fa-regular", "fa-calendar"],
        "uom": ["hours", "days", "weeks", "months", "years"]
    },
    "F": {
        "label": "Flow",
        "icon": "fa-gauge",
        "uom": ""
    },
    "H": {
        "label": "Hand Valve",
        "icon": ["fa-solid", "fa-faucet-drip"],
        "uom": "open/closed"
    },
    "L": {
        "label": "Level",
        "icon": ["fa-battery-three-quarters", "fa-rotate-270"],
        "uom": "%"
    },
    "P": {
        "label": "Pressure",
        "icon": "fa-gauge",
        "uom":  ["bar", "mbar"]
    },
    "PD": {
        "label": "Differential Pressure",
        "icon": "fa-gauge-high",
        "uom":  ["bar", "mbar"]
    },
    "R": {
        "label": "Ratio",
        "icon": ["fa-solid", "fa-percent"],
        "uom": "%"
    },
    "T": {
        "label": "Temperature",
        "icon": "fa-temperature-three-quarters",
        "uom": "°C"
    },
    "V": {
        "label": "Vibrations (V)",
        "icon": ["fa-solid", "fa-group-arrows-rotate"],
        "uom": "mm"
    },
    "X": {
        "label": "Vibrations (X)",
        "icon": ["fa-solid", "fa-group-arrows-rotate"],
        "uom": "mm"
    },

    " ": {
        "label": "not labelled yet",
        "icon": ["fa-gear", "fa-rotate-90"],
        "uom": ""
    }
}

export class PlantObject {
    static _singleton = new PlantObject();

    static instance(name = "", key = "", callback = undefined) {
        if (this._singleton == null) {
            this._singleton = new PlantObject(callback);
        }
        if (key != "") this._singleton.init(name, key, true, callback);
        return this._singleton;
    }

    constructor() {
        this.name;
        this.key;
    }

    init(name, key, fill = true, callback = undefined) {
        this.name = name;
        this.key = key;
        this.callback = callback;
        this.findMe();
        if (checkDebug()) console.log(this);
        if (name = "") $("plantpartname").textContent = this.name;
        this.findParent();
        this.findSystem(this.parent);
        if (fill) this.fillMe();
        this.findChildren();
        this.fillChildren();
    }

    findMe() {
        if (PlantObject.#_DATA || SYSTEMDATA) {
            if (SYSTEMDATA) PlantObject.setData(SYSTEMDATA);
            for (let line in PlantObject.#_DATA) {
                if (PlantObject.#_DATA[line]["obj_id"] == this.key) {
                    let me = PlantObject.#_DATA[line]
                    if (checkDebug()) console.log(me);
                    // Parent,Child,Name,TypeName,value,uom,datatype,digits,RangeMax,RangeMin,LimitHiHi,LimitHigh,LimitLow,LimitLoLo,UniqueId,TagId
                    this.parent = me["parent"];
                    this.name = me["objname"];
                    this.type = me["typeidname"];
                    this.uom = me["uom"];
                    this.datatype = me["datatype"];
                    this.digits = me["digits"];
                    this.RangeMax = me["RangeMax"];
                    this.RangeMin = me["RangeMin"];
                    this.LimitHiHi = me["LimitHiHi"];
                    this.LimitHigh = me["LimitHigh"];
                    this.LimitLow = me["LimitLow"];
                    this.LimitLoLo = me["LimitLoLo"];
                    this.UniqueId = me["UniqueId"];
                    this.TagId = me["TagId"];

                    break;
                }
            }
        }
    }

    findParent() {
        if (PlantObject.#_DATA || SYSTEMDATA) {
            if (SYSTEMDATA) PlantObject.setData(SYSTEMDATA);
            for (let line in PlantObject.#_DATA) {
                if (PlantObject.#_DATA[line]["obj_id"] == this.parent) {
                    let me = PlantObject.#_DATA[line]
                    this.parentname = me["objname"];
                    break;
                }
            }
        }
    }

    findSystem(key) {
        if (PlantObject.#_DATA || SYSTEMDATA) {
            if (SYSTEMDATA) PlantObject.setData(SYSTEMDATA);
            for (let line in PlantObject.#_DATA) {
                if (PlantObject.#_DATA[line]["obj_id"] == key) {
                    let me = PlantObject.#_DATA[line]
                    if (me["typeidname"] == "Plant") {
                        gLoginState.setCurrentPlant(me["obj_id"]);
                        this.system = gLoginState.getCurrentPlant().System;
                    }


                    else this.findSystem(me["parent"]);
                    break;
                }
            }
        }
    }

    fillMe() {
        $("plantpartparent").value = this.parent + " - " + this.parentname;
        let btn = $("gotoparent");
        createsetAttribute(btn, "data", "plantpart=" + this.parent + " - " + this.parentname);
        btn.addEventListener("click", navigate);
        $("plantparttype").value = this.type;
        $("plantpartuom").value = this.uom;

        let name = $("plantpartname");
        name.textContent = "";
        let icon = createElementAndClassList("i", ["fa-solid", "me-2"]);
        switch (this.type) {
            case "App":
                icon.classList.add("fa-globe");
                break;
            case "Site":
                icon.classList.add("fa-map-location-dot");
                break;
            case "Plant":
                icon.classList.add("fa-industry");
                break;
            case "Section":
                icon.classList.add("fa-cheese");
                break;
            case "Equipment":
                icon.classList.add("fa-gears", "fa-rotate-270");
                break;
            case "Sensor":
                let s_key = this.key.indexOf("_") > 0 ?
                    this.key.substring(gLoginState.currentPlant.Prefix.length, this.key.indexOf("_", gLoginState.currentPlant.Prefix.length + 1)) :
                    this.key.substring(0, 1);
                if (s_key in SENSORMAPPING) {
                    if (Array.isArray(SENSORMAPPING[s_key]["icon"])) {
                        SENSORMAPPING[s_key]["icon"].forEach((v) => {
                            icon.classList.add(v);
                        });
                    }
                    else icon.classList.add(SENSORMAPPING[s_key]["icon"]);
                    $("plantparttype").value = $("plantparttype").value + " (" + SENSORMAPPING[s_key]["label"] + ")";
                }
                else
                    if (s_key.substring(0, 1) in SENSORMAPPING) {
                        icon.classList.add(SENSORMAPPING[s_key.substring(0, 1)]["icon"]);
                        $("plantparttype").value = $("plantparttype").value + " (" + SENSORMAPPING[s_key.substring(0, 1)]["label"] + ")";
                    }
                    else {
                        icon.classList.add(SENSORMAPPING[" "]["icon"]);
                        $("plantparttype").value = $("plantparttype").value + " (" + SENSORMAPPING[" "]["label"] + ")";
                    }
                break;
            default:
                icon.classList.add("fa-cloud");
                break;
        }
        name.appendChild(icon);
        name.appendChild($T(this.name));

        if (this.type == "Sensor") {
            $("childrencol").classList.add("d-none");
            let divcol = $("actuals");
            if (divcol instanceof Element)
                divcol = cleanArea("actuals");
            else {
                divcol = createElementAndClassList("div", ["col-md-8", "order-5"]);
                divcol.id = "actuals";
            }
            let divcard = createElementAndClassList("div", ["card", "card25"]);
            let divhead = createElementAndClassList("div", ["card-header"]);
            let divbody = createElementAndClassList("div", ["card-body", "fs-6", "p-0"]);
            divbody.id = "actuals_chart";
            let title = createElementAndClassList("h5", ["card-title", "d-inline", "me-2"]);
            title.textContent = "Actuals Chart";
            let divider = createElementAndClassList("div", "vr");
            let ultime = createElementAndClassList("div", ["d-inline", "ms-2"]);
            let intervalbtns = [{ "interval": "short", "label": "Short (3 days)" }, { "interval": "middle", "label": "Middle (12 weeks)" }, { "interval": "long", "label": "Long (12 months)" }];
            for (let i = 0; i < intervalbtns.length; i++) {
                if (!$(intervalbtns[i].interval)) {
                    let li = createElementAndClassList("li", ["nav-item", "d-inline"]);
                    let btn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-primary", "me-2"]);
                    btn.type = "button";
                    btn.id = intervalbtns[i]["interval"];
                    btn.textContent = intervalbtns[i]["label"];
                    btn.addEventListener("click", async (ev) => {
                        let ppts = new PlantPartTimeSeries(intervalbtns[i]["interval"], undefined);
                        await this.callback([ppts.getTimeSeries(undefined, intervalbtns[i]["interval"], this.system, this.key)], this);
                        for (let j = 0; j < intervalbtns.length; j++)
                            if ($(intervalbtns[j].interval).classList.contains("active"))
                                $(intervalbtns[j].interval).classList.remove("active");
                        ev.target.classList.add("active");
                    });
                    li.appendChild(btn);
                    ultime.appendChild(li);
                }
            }
            divhead.append(title, divider, ultime);
            divcard.append(divhead, divbody);
            divcol.appendChild(divcard);
            $("cardlist").appendChild(divcol);

            if (this.LimitHiHi != "" || this.LimitHigh != "" || this.LimitLow != "" || this.LimitLoLo != "" || this.RangeMax != "" || this.RangeMin != "") {
                let divcol = $("limits");
                if (divcol instanceof Element)
                    divcol = cleanArea("limits");
                else {
                    divcol = createElementAndClassList("div", ["col", "order-5"]);
                    divcol.id = "limits";
                }
                let divcard = createElementAndClassList("div", ["card", "card25"]);
                let divbody = createElementAndClassList("div", ["card-body", "fs-6"]);
                divbody.id = "limitContent";
                let title = createElementAndClassList("h5", "card-title");
                title.textContent = "Range & Limits";
                divbody.appendChild(title);
                divcard.appendChild(divbody);
                divcol.appendChild(divcard);
                $("cardlist").appendChild(divcol);

                let params = ["RangeMax", "RangeMin", "LimitHiHi", "LimitHigh", "LimitLow", "LimitLoLo"];
                for (let p in params) {
                    let divform = createElementAndClassList("div", ["form-floating", "mb-3"]);
                    let inputtext = createElementAndClassList("input", "form-control");
                    inputtext.type = "text";
                    createsetAttribute(inputtext, "id", params[p]);
                    createsetAttribute(inputtext, "placeholder", "");
                    createsetAttribute(inputtext, "disabled", "disabled");
                    inputtext.value = this[params[p]];
                    let label = $E("label")
                    createsetAttribute(label, "for", params[p]);
                    label.textContent = params[p];
                    divform.appendChild(inputtext);
                    divform.appendChild(label);
                    divbody.appendChild(divform);
                }

            }
        }
        let btnover = createElementAndClassList("button", ["btn", "btn-outline-primary"]);
        btnover.type = "button";
        btnover.addEventListener("click", navigate);
        if (this.type == "Plant") {
            createsetAttribute(btnover, "data", "plant=" + this.key);
            btnover.textContent = "Go to Monitored Objects";
            $("detail-footer").appendChild(btnover);
            let btnAsset = createElementAndClassList("button", ["btn", "btn-outline-primary"]);
            btnAsset.type = "button";
            createsetAttribute(btnAsset, "data", "asset=" + this.key);
            btnAsset.textContent = "Go to Assets";
            $("detail-footer").appendChild(btnAsset);
            btnAsset.addEventListener("click", navigate);
        } else if (this.type == "Site" || this.type == "App") {
            createsetAttribute(btnover, "data", "multiplant");
            btnover.textContent = "Go to plant overview";
            $("detail-footer").appendChild(btnover);
        }
    }

    findChildren() {
        this.children = [];
        if (PlantObject.#_DATA || SYSTEMDATA) {
            if (SYSTEMDATA) PlantObject.setData(SYSTEMDATA);
            for (let line in PlantObject.#_DATA) {
                if (PlantObject.#_DATA[line]["parent"] == this.key) {
                    this.children.push(PlantObject.#_DATA[line]);
                }
            }
        }
    }

    fillChildren() {
        let mylist = $("children");
        for (let n in this.children) {
            createListElementWithLink(mylist,
                this.children[n]["obj_id"] + " - " + this.children[n]["objname"],
                "plantpart=" + this.children[n]["obj_id"] + " - " + this.children[n]["objname"]);
        }
    }

    fillStats(data, compare) {
        let divcol;
        divcol = $("stats");
        if (divcol instanceof Element)
            divcol = cleanArea("stats");
        else {
            divcol = createElementAndClassList("div", ["col", "order-4"]);
            divcol.id = "stats";
            $("cardlist").appendChild(divcol);
        }
        let divcard = createElementAndClassList("div", ["card", "card25"]);

        let divbody = createElementAndClassList("div", ["card-body", "fs-6"]);
        divbody.id = "statsContent";
        let title = createElementAndClassList("h5", "card-title");
        title.textContent = "Data Statistics";
        divbody.appendChild(title);
        divcard.appendChild(divbody);
        divcol.appendChild(divcard);

        let namhd = createElementAndClassList("div", "col");
        let acthd = createElementAndClassList("div", "col");
        let samhd = createElementAndClassList("div", "col");
        let row = createElementAndClassList("div", ["row", "fw-bold"]);
        namhd.textContent = "KPI";
        acthd.textContent = "Actual Value";
        samhd.textContent = "Training Value";
        row.append(namhd, acthd, samhd);
        divbody.appendChild(row);
        if ("data" in data) {
            for (let d in data.data.statistics) {
                if (d == this.key) {
                    for (let name in data.data.statistics[d]) {
                        let actvalue = data.data.statistics[d][name];
                        let samvalue = compare.data.statistics[d][name];
                        let row = createElementAndClassList("div", "row");
                        let namcol = createElementAndClassList("div", "col");
                        let actcol = createElementAndClassList("div", "col");
                        let samcol = createElementAndClassList("div", "col");
                        namcol.textContent = name;
                        actcol.textContent = isNaN(actvalue) || name.startsWith("count_") ? actvalue : Number(actvalue).toFixed(3);
                        samcol.textContent = isNaN(samvalue) || name.startsWith("count_") ? samvalue : Number(samvalue).toFixed(3)
                        row.append(namcol, actcol, samcol);
                        divbody.appendChild(row);
                    }
                }
            }
        } else {
            if ("error" in data) {
                let msg = createElementAndClassList("div", "text-bg-warning");
                msg.appendChild($T(data.error));
                divbody.appendChild(msg);
            }
            if ("message" in data) {
                writeMessages(data.message, divbody)
            }
        }
    }

    fillSignals(data) {
        let divcol;
        divcol = $("signals");
        if (divcol instanceof Element)
            divcol = cleanArea("signals");
        else {
            divcol = createElementAndClassList("div", ["col", "order-2"]);
            divcol.id = "signals";
            $("cardlist").appendChild(divcol);
        }
        let divcard = createElementAndClassList("div", ["card", "card25"]);

        let divbody = createElementAndClassList("div", ["card-body", "fs-6"]);
        divbody.id = "signalsContent";
        let title = createElementAndClassList("h5", "card-title");
        let count = $E("span");
        count.textContent = 0;
        count.id = "signalcount";
        title.append(count, $T(" Signal(s)"));
        divbody.appendChild(title);
        divcard.appendChild(divbody);
        divcol.appendChild(divcard);

        let divrow = createElementAndClassList("div", ["row", "row-cols-1", "row-cols-md-2", "g-2"]);
        let mysignals = {};
        if (data) {
            Object.keys(data.signals).forEach((v) => {
                if (data.signals[v].hasOwnProperty("signal")) {
                    mysignals[v] = data.signals[v];
                }
            });
            SignalView.renderSignals(mysignals, divrow);
            divbody.appendChild(divrow);
            let number = Object.keys(mysignals).length;
            $("signalcount").textContent = number;

            if (number > 0) divcard.classList.add("text-bg-warning");
        }
    }

    async showMOs(data) {
        let content = $("molist");
        for (let n in data) {
            if (this.type == "Plant" || data[n].hasOwnProperty("id"))
                if (this.type == "Plant" || data[n]["id"].indexOf(this.key) >= 0 || (this.children.find(v => data[n]["id"].indexOf(v["obj_id"]) >= 0)) || data[n]["id"].substring(0, 2) == this.key.substring(2, 4)
                )
                    createListElementWithLink(content,
                        data[n]["id"] + "-" + data[n]["name"],
                        "mo=" + data[n]["id"] + "," + gLoginState.currentPlant.System
                    );
        }
        if (this.type == "Sensor") {
            $("mo-title").textContent = "Used in Monitored Objects:";
            Sensor2MO(data, this.key);
        }
        return {};
    }

    static #_DATA;
    static setData(data) {
        PlantObject.#_DATA = data;
    }
    static getData() {
        return PlantObject.#_DATA;
    }
}

export function Sensor2MO(data, key) {
    let parent = $("mo-title").parentElement;
    parent.removeChild(parent.lastElementChild);
    for (let n in data) {
        if ("id" in data[n]) {
            let monitored_object = data[n]["id"];
            let system = gLoginState.currentPlant.System;
            let ul;
            let params = {
                "responses": {
                    "title": "... as responses:",
                    "path": "responses",
                    "listid": "responselist"
                },
                "features": {
                    "title": "... as features:",
                    "path": "features",
                    "listid": "featurelist"
                }
            }
            Object.keys(params).forEach((v) => {
                if (data[n].hasOwnProperty(params[v].path))
                    if (Object.keys(data[n][params[v].path]).includes(key)) {
                        if (parent.getElementsByTagName("h6").length == 0 || parent.getElementsByTagName("h6").length == 1 && !(parent.getElementsByTagName("h6")[0].textContent == params[v].title)) {
                            let h6 = $E("h6");
                            h6.textContent = params[v].title;
                            parent.appendChild(h6);
                            ul = createElementAndClassList("ul", "list-group");
                            ul.id = params[v].listid;
                            parent.appendChild(ul);
                        }
                        let li = createElementAndClassList("li", "list-group-item");
                        let link = $E("a");
                        link.href = "#";
                        createsetAttribute(link, "data", "mo=" + monitored_object + "," + system);
                        link.textContent = monitored_object;
                        link.addEventListener("click", navigate);
                        li.appendChild(link);
                        $(params[v].listid).appendChild(li);
                    }
            });
        }
    }
} 