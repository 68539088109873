import { $, Spinner, createElementAndClassList, createsetAttribute, cleanArea, $E, $T } from "../helper.mjs";
import { myEvent, navigate, SYSTEMDATA } from "../index.mjs";
import { callMe } from "../mybackend.mjs";
import { gLoginState, usersettings } from "../mymsal.mjs";
import { MonitoredObjectController, MonitoredObjectView, Status } from "../core/monitoredobject.mjs";
import { Signals } from "../core/signals.mjs"
import { PredictedTimeSeries, MeasurementTimeSeries, ScatterPlotMatrix } from "../core/timeseries.mjs";
import { Strategy } from "../core/strategy.mjs";

let splom = new ScatterPlotMatrix();

function fillMORegistration(mosystem, data, signals, myTS) {
    let models = data.data;
    let counter = 0;

    if (!models || Object.keys(models).length === 0) {
        let button = createElementAndClassList("button", ["nav-link", "strategy", "active", "icon-link"]);
        button.id = "createStrategy";
        createsetAttribute(button, "data-bs-toggle", "offcanvas");
        createsetAttribute(button, "data-bs-target", "#offCreateStrategy");
        createsetAttribute(button, "aria-controls", "offCreateStrategy");
        button.appendChild(createElementAndClassList("i", ["fa-solid", "fa-plus"]));
        button.appendChild($T("Create Strategy"));
        $("nav-tab-reg").appendChild(button);
    }
    for (var key in models) {
        //Nav-Tab-Buttons
        let button = createElementAndClassList("button", ["nav-link", "strategy"]);
        if (counter == 0) button.classList.add("active");
        let tempid = "nav-" + models[key].monitored_object + models[key].strategy
        button.id = tempid + "-tab";
        button.type = "button";
        button.textContent = models[key].monitored_object + ":" + models[key].strategy + " ";
        button.appendChild(createElementAndClassList("i", Status.images[Status.labels.indexOf(models[key].object_state)]));

        createsetAttribute(button, "data-bs-target", "#" + tempid);
        createsetAttribute(button, "aria-controls", tempid);
        createsetAttribute(button, "data-bs-toggle", "tab");
        createsetAttribute(button, "role", "tab");
        createsetAttribute(button, "aria-selected", "false");
        createsetAttribute(button, "data", models[key].monitored_object + "§" + models[key].strategy);

        $("nav-tab-reg").appendChild(button);

        let divtab = createElementAndClassList("div", ["tab-pane", "fade"]);
        if (counter == 0) divtab.classList.add("active", "show");
        divtab.id = tempid;
        createsetAttribute(divtab, "role", "tabpanel");
        createsetAttribute(divtab, "aria-labelledby", button.id);
        createsetAttribute(divtab, "tabindex", key);

        let navchar = createElementAndClassList("nav", ["navbar", "navbar-expand-xl", "basfdb3", "pt-1", "pb-1"]);
        let divcharcon = createElementAndClassList("div", "container-fluid");
        let btnchartggl = createElementAndClassList("button", "navbar-toggler");
        btnchartggl.type = "button";
        createsetAttribute(btnchartggl, "data-bs-toggle", "collapse");
        createsetAttribute(btnchartggl, "data-bs-target", "#TimeButtons-" + models[key].monitored_object + models[key].strategy);
        createsetAttribute(btnchartggl, "aria-controls", "TimeButtons-" + models[key].monitored_object + models[key].strategy);
        createsetAttribute(btnchartggl, "aria-expanded", "false");
        createsetAttribute(btnchartggl, "aria-label", "Toggle navigation");
        let btntchargglicon = createElementAndClassList("span", "navbar-toggler-icon")
        btnchartggl.appendChild(btntchargglicon);
        let divcharcoll = createElementAndClassList("div", ["collapse", "navbar-collapse"]);
        divcharcoll.id = "TimeButtons-" + models[key].monitored_object + models[key].strategy;
        let ulchar = createElementAndClassList("ul", ["navbar-nav", "me-auto", "mb-2", "mb-lg-0", "d-flex", "align-items-center"]);

        let stratacttxt = createElementAndClassList("span", ["navbar-text", "me-2", "small"]);
        stratacttxt.textContent = "Strategy Actions:";
        ulchar.appendChild(stratacttxt);

        let newli = createElementAndClassList("li", "nav-item");
        let newbtn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-primary", "me-2"]);
        newbtn.type = "button"
        createsetAttribute(newbtn, "data-bs-toggle", "offcanvas");
        createsetAttribute(newbtn, "data-bs-target", "#offCreateStrategy");
        createsetAttribute(newbtn, "aria-controls", "offCreateStrategy");
        newbtn.appendChild(createElementAndClassList("i", ["fa-solid", "fa-plus", "me-2"]));
        newbtn.appendChild($T("Create"));
        newli.appendChild(newbtn);
        ulchar.appendChild(newli);

        ulchar.appendChild(createElementAndClassList("div", ["vr", "ms-1", "me-2"]));

        let retrainli = createElementAndClassList("li", "nav-item");
        retrainli.appendChild(new Strategy(mosystem, models[key].monitored_object).getRetrainButton(models[key].strategy, "mo"));
        ulchar.appendChild(retrainli);

        let delli = createElementAndClassList("li", "nav-item");
        let delbtn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-primary", "me-2"])
        createsetAttribute(delbtn, "data", models[key].monitored_object + "§" + models[key].strategy);
        createsetAttribute(delbtn, "data-bs-toggle", "offcanvas");
        createsetAttribute(delbtn, "data-bs-target", "#offDelete");
        createsetAttribute(delbtn, "aria-controls", "offDelete");
        delbtn.type = "button";
        delbtn.id = "btndelete-" + models[key].monitored_object + models[key].strategy;
        let deli = createElementAndClassList("i", ["fa-regular", "fa-trash-can", "me-2"]);
        delbtn.appendChild(deli);
        delbtn.appendChild($T("Delete"));
        delbtn.addEventListener("click", (ev) => { new Strategy(mosystem, models[key].monitored_object).fillDeleteStrategy(ev.target); })
        delli.appendChild(delbtn);
        ulchar.appendChild(delli);

        ulchar.appendChild(createElementAndClassList("div", ["vr", "ms-1", "me-2"]));

        let periodtxt = createElementAndClassList("span", ["navbar-text", "me-2", "small"]);
        periodtxt.textContent = "Data Period:";
        ulchar.appendChild(periodtxt);

        let intervalbtns = [{ "interval": "short", "label": "Short (3 days)" }, { "interval": "middle", "label": "Middle (12 weeks)" }, { "interval": "long", "label": "Long (12 months)" }];
        for (let i = 0; i < intervalbtns.length; i++) {
            let li = createElementAndClassList("li", "nav-item");
            let btn = createElementAndClassList("button", ["btn", "btn-sm", "btn-outline-primary", "me-2"]);
            createsetAttribute(btn, "data", models[key].monitored_object + "§" + models[key].strategy);
            btn.type = "button";
            btn.id = "btn" + intervalbtns[i]["interval"] + "-" + models[key].monitored_object + models[key].strategy;
            btn.textContent = intervalbtns[i]["label"];
            btn.addEventListener("click", async (ev) => {
                let arrayOfPromises = [() => { $('splom-sensor-select').value = "all" }];
                myTS.forEach((v) => { arrayOfPromises.push(v.getTimeSeries(ev.target, intervalbtns[i]["interval"])) });
                await Promise.all(arrayOfPromises)
                    .then(() => { fixResize(); });
            });
            li.appendChild(btn);
            ulchar.appendChild(li);
        }
        divcharcoll.appendChild(ulchar);
        divcharcon.appendChild(btnchartggl);
        divcharcon.appendChild(divcharcoll);
        navchar.appendChild(divcharcon);

        divtab.appendChild(navchar)

        let divrow = createElementAndClassList("div", ["row", "row-cols-1", "row-cols-md-2", "g-4"]);
        let divcol1 = createElementAndClassList("div", ["col", "col-md-3"]);
        let divcard1 = createElementAndClassList("div", ["card", "h-100"]);
        let divcard1_body = createElementAndClassList("div", "card-body");
        divcard1_body.id = "details-" + models[key].monitored_object + models[key].strategy;

        divcard1.appendChild(divcard1_body);
        divcol1.appendChild(divcard1);

        let divcol2 = createElementAndClassList("div", ["col", "col-md-9"]);
        let divcard2 = createElementAndClassList("div", ["card", "h-100"]);
        let divcard2_body = createElementAndClassList("div", ["card-body", "content"]);

        let divcaro = createElementAndClassList("div", ["carousel", "carousel-dark", "slide"]);
        createsetAttribute(divcaro, "data-bs-ride", "false");
        divcaro.id = "carouselModel-" + models[key].monitored_object + models[key].strategy;

        let divcaroind = createElementAndClassList("div", "carousel-indicators");
        let divcaroinn = createElementAndClassList("div", "carousel-inner");

        for (let i = 0; i < 2; i++) {
            let buttonind = $E("button");
            buttonind.type = "button";
            createsetAttribute(buttonind, "data-bs-target", "#" + divcaro.id);
            createsetAttribute(buttonind, "data-bs-slide-to", i.toString());
            myEvent.attach(buttonind, "click", fixResize);
            
            let divcaroitem = createElementAndClassList("div", "carousel-item");
            let divcarocont = createElementAndClassList("div", ["d-block", "w-100"]);
            
            if (i == 0) {
                buttonind.classList.add("active");
                divcaroitem.classList.add("active");
            }
            let seq = 0;
            if (usersettings["default_object_visual"] == "line") seq = 1;
            if (i == seq) {
                createsetAttribute(buttonind, "aria-label", "BoxPlot");
                divcarocont.classList.add("myboxplot");
                divcarocont.id = "boxplot-" + models[key].monitored_object + models[key].strategy;
            }
            else {
                createsetAttribute(buttonind, "aria-label", "TimeSeries");
                divcarocont.classList.add("mytimeseries");
                divcarocont.id = "timeseries-" + models[key].monitored_object + models[key].strategy;
            }    
            divcarocont.appendChild(new Spinner().getLoadingElement());   
            divcaroind.appendChild(buttonind);   
            divcaroitem.appendChild(divcarocont);
            divcaroinn.appendChild(divcaroitem);
        }
        divcaro.appendChild(divcaroind);
        divcaro.appendChild(divcaroinn);

        divcard2_body.appendChild(divcaro);
        divcard2.appendChild(divcard2_body);
        divcol2.appendChild(divcard2);

        divrow.appendChild(divcol1);
        divrow.appendChild(divcol2);
        divtab.appendChild(divrow);

        $("tab-content").appendChild(divtab);

        counter += 1;

        myEvent.attach(button, "click", (ev) => {
            var allitems = [];
            allitems = Array.prototype.concat.apply(allitems, document.getElementsByClassName("mytimeseries"));
            allitems = Array.prototype.concat.apply(allitems, document.getElementsByClassName("myboxplot"));
            for (let v in allitems) {
                let me = (allitems[v]) ? allitems[v] : null;
                if (!me) break;
                if (!me.id) break;
                Plotly.purge(me.id);
            }
            myTS[0].getTimeSeries(ev.target, "short");
        }
        );

        let navall = createElementAndClassList("nav", ["navbar", "navbar-expand-xl", "p-0"]);

        let divcont = createElementAndClassList("div", ["nav", "nav-tabs"]);
        divcont.id = "navtabreg-" + models[key].monitored_object + models[key].strategy;

        let regbtns = [{ "id": "btnreg-", "name": "State", "target": "#state" },
        { "id": "btnest-", "name": "KPIs", "target": "#kpis" }]

        if (models[key].object_state == "active")
            regbtns.push({ "id": "btnevt-", "name": "KPI Config", "target": "#evts" });

        for (let btn in regbtns) {
            let tabbtn;
            if (btn == 0) tabbtn = createElementAndClassList("button", ["nav-link", "active", "me-2"]);
            else tabbtn = createElementAndClassList("button", ["nav-link", "me-2"]);
            tabbtn.type = "button";
            tabbtn.id = regbtns[btn].id + models[key].monitored_object + models[key].strategy;
            tabbtn.textContent = regbtns[btn].name;
            createsetAttribute(tabbtn, "data-bs-target", regbtns[btn].target + models[key].monitored_object + models[key].strategy);
            createsetAttribute(tabbtn, "data-bs-toggle", "tab");
            createsetAttribute(tabbtn, "role", "tab");
            createsetAttribute(tabbtn, "aria-controls", regbtns[btn].target + models[key].monitored_object + models[key].strategy);
            if (btn == 0) createsetAttribute(tabbtn, "aria-selected", "true");
            else createsetAttribute(tabbtn, "aria-selected", "false");
            divcont.appendChild(tabbtn);
        }

        navall.appendChild(divcont);

        let divtabcon = createElementAndClassList("div", "tab-content");

        let stratdetails = ["strategy", "amended_on", "assessed_on", "assessment"];
        let strattexts = ["Model strategy", "Status update on", "Model assessed on", "Recommended change"]
        let divlist = createElementAndClassList("div", ["tab-pane", "active"]);
        divlist.id = "state" + models[key].monitored_object + models[key].strategy;
        createsetAttribute(divlist, "role", "tabpanel");
        createsetAttribute(divlist, "area-labelledby", "btnreg-" + models[key].monitored_object + models[key].strategy);
        createsetAttribute(divlist, "tabindex", 0);

        for (var sd in stratdetails) {
            let divform = createElementAndClassList("div", ["form-floating", "mb-3"]);
            let inputdis = createElementAndClassList("input", ["form-control", "disabled"]);
            inputdis.type = "text";
            inputdis.readOnly = "readonly";
            inputdis.id = stratdetails[sd] + models[key].monitored_object + models[key].strategy;
            inputdis.value = models[key][stratdetails[sd]];

            let labeldis = $E("label");
            labeldis.for = inputdis.id;
            labeldis.textContent = strattexts[sd];

            divform.appendChild(inputdis);
            divform.appendChild(labeldis);

            divlist.appendChild(divform);
        }
        let inputstate = createElementAndClassList("input", ["visually-hidden"]);
        inputstate.readOnly = "readonly";
        inputstate.type = "text";
        inputstate.id = "currentstate" + models[key].monitored_object + models[key].strategy;
        inputstate.value = models[key].object_state;
        divlist.appendChild(inputstate);
        let content = cleanArea("details-" + models[key].monitored_object + models[key].strategy);

        // Object State / Model State
        let divstat = createElementAndClassList("div", "mb-3");
        let labelstat = $E("label");
        labelstat.for = "object_state" + models[key].monitored_object + models[key].strategy;
        labelstat.textContent = "Model State:";

        divstat.appendChild(labelstat);

        let divstatus = createElementAndClassList("div", "card-group");
        Status.createStateView(divstatus, models[key].object_state);

        divstat.appendChild(divstatus);
        divlist.appendChild(divstat);

        content.appendChild(navall);
        divtabcon.appendChild(divlist);

        // Estimator
        let divest = createElementAndClassList("div", "tab-pane");
        divest.id = "kpis" + models[key].monitored_object + models[key].strategy;
        divest.textContent = "Estimator Details for " + models[key].monitored_object + "-" + models[key].strategy
        createsetAttribute(divest, "role", "tabpanel");
        createsetAttribute(divest, "area-labelledby", "btnest-" + models[key].monitored_object + models[key].strategy);
        createsetAttribute(divlist, "tabindex", 0);

        divtabcon.appendChild(divest);

        // Events
        if (models[key].object_state == "active") {
            let divevt = createElementAndClassList("div", "tab-pane");
            divevt.id = "evts" + models[key].monitored_object + models[key].strategy;
            divevt.textContent = "KPI configuration for " + models[key].monitored_object + "-" + models[key].strategy
            createsetAttribute(divevt, "role", "tabpanel");
            createsetAttribute(divevt, "area-labelledby", "btnevt-" + models[key].monitored_object + models[key].strategy);
            createsetAttribute(divlist, "tabindex", 0);

            divtabcon.appendChild(divevt);
        }

        content.appendChild(divtabcon);
    }
}

function checkButtonDoPlot(me) {
    let mybutton = $("nav-" + me.id.substring(me.id.indexOf("-") + 1, me.id.length) + "-tab");
    if (mybutton.classList.contains("active") && (me.firstElementChild)) {
        try {
            Plotly.newPlot(me.id, me.data, me.layout, me.config);
        } catch (e) { }
        return true;
    } else return false;
}

function fixResize() {
    for (const name in ["mytimeseries", "myboxplot"]) {
        let allitems = document.getElementsByClassName(name);
        for (const v in allitems) {
            let me = (allitems[v]) ? allitems[v] : null;
            if (!me) break;
            if (!me.id) break;
            if (checkButtonDoPlot(me)) break;
        }
    }

    let defitems = []
    for (let v of ["periodTS", "resp_ts", "feat_ts", "metricActive"]) {
        if ($(v) !== null)
            defitems.push($(v));
    }
    for (let v of defitems) {
        try {
            let p = { ...v };
            cleanArea(v.id);
            Plotly.newPlot(v.id, p.data, p.layout, p.config);
        } catch (e) { console.log(e); }
    }

}

export async function startmo(scope, system) {
    console.log("startmo", scope, Number(system));
    let mosystem = Number(system);
    let plant;
    for (let p in gLoginState.authorizedPlants) {
        if (gLoginState.authorizedPlants[p].System == mosystem) {
            plant = p;
            break;
        }
    }
    gLoginState.setCurrentPlant(plant);
    Signals._signals = [];

    createsetAttribute($("condensed"), "data", "mostart=" + scope + "," + mosystem);
    myEvent.attach($("condensed"), "click", navigate);

    let myTS;

    let moctrl = new MonitoredObjectController(undefined, mosystem);
    await Promise.all([
        moctrl.loadMonitoredObjectConfig(system + "-config-" + scope + ".json"),
        callMe(
            "/blackbox/molist", "POST",
            { model: scope, type: "registration", status: null, system: mosystem }
        ),
        callMe("/blackbox/estimator/params", "GET", null)
    ])
        .then(async (responses) => {
            // _ = responses[0];
            var registration = responses[1];
            var estimatorparams = responses[2];

            myTS = [new PredictedTimeSeries("short", moctrl.model), new MeasurementTimeSeries("short", moctrl.model)];
            myTS.forEach((v) => { v.setMOSystem(mosystem); });

            let nuller = false;
            let arrayOfPromises = [moctrl.registerListEntries(SYSTEMDATA)];

            if (responses[0] !== "") arrayOfPromises.push(MonitoredObjectView.fillMODefinition(moctrl.model, fixResize));
            if (registration !== "") {
                arrayOfPromises.push(fillMORegistration(mosystem, registration, null, myTS));
                if (registration["return code"] == 0) {
                    let active = Object.values(registration.data).find((v) => {
                        if (v.object_state == "active") return true;
                        else return false;
                    });
                    if (active !== undefined) {
                        arrayOfPromises.push(
                            new Signals(mosystem, moctrl.model, "Model", active.strategy, "all", fixResize)
                        )
                    } else nuller = true;
                } else nuller = true;
                if (nuller) {
                    arrayOfPromises.push(
                        new Signals(mosystem, moctrl.model, "Model", null, "all", fixResize)
                    );
                }
            }
            if (estimatorparams !== "") {
                arrayOfPromises.push(new Strategy(mosystem, moctrl.model.id).fillCreateStrategy(estimatorparams));
            }
            let tabs = document.getElementsByClassName("strategy");
            if (tabs.length > 1) {
                for (let t in tabs) {
                    if (tabs[t].classList.contains("active")) {
                        myTS.forEach((v) => { arrayOfPromises.push(v.getTimeSeries(tabs[t], "short")); });
                        break;
                    }
                }
            } else {
                arrayOfPromises.push(myTS[1].getTimeSeries(moctrl.model.id, "short"));
            }
            
            return arrayOfPromises;
        })
        .then((arrayOfPromises) => {
            Promise.all(arrayOfPromises)
            .then((res) => {
                let id = "splom";
                let listid = "splom-sensors";
                splom = new ScatterPlotMatrix(res[res.length-1].timeseries);
                myEvent.attach($("toggleoffdist"), "click", () => {
                    let moresponses = [];
                    let mofeatures = [];
                    for (let s in moctrl.model.responses) {
                        moresponses.push(s);
                    }
                    for (let s in moctrl.model.features) {
                        mofeatures.push(s);
                    }
                    splom.fill(id, moresponses, mofeatures, listid);
                });
                myEvent.attach($("offdistributionClose"), "click", () => {
                    splom.init(id, listid);
                });
            });
        })
        .finally(() => {
            myEvent.attach($("MO_edit"), "click", () => {
                moctrl.prepareEditMO();
            });
            window.addEventListener("resize", fixResize);
        });
    
}

export function destroymo() {
    window.removeEventListener("resize", fixResize);
    myEvent.remove($("condensed"), "click");
    myEvent.remove($("MO_edit"), "click");
    myEvent.remove($("splom-sensor-select"), "change");
    myEvent.remove($("offdistributionClose"), "click");
    myEvent.remove($("toggleoffdist"), "click");

    splom.dispose("splom", "splom-sensors");

    var allitems = [];
    allitems = Array.prototype.concat.apply(allitems, document.getElementsByClassName("mytimeseries"));
    allitems = Array.prototype.concat.apply(allitems, document.getElementsByClassName("myboxplot"));

    for (let v in allitems) {
        let me = (allitems[v]) ? allitems[v] : null;
        if (!me) break;
        if (!me.id) break;
        Plotly.purge(me.id);
    }
    for (let v of ["periodTS", "resp_ts", "feat_ts", "metricActive"]) {
        if ($(v)) Plotly.purge(v);
    }
}