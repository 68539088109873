import { $, EventList, cleanArea, createElementAndClassList, createsetAttribute, injectEscape, checkDebug, createListElementWithLink } from "./helper.mjs";
import { MessageBroker } from "./messages.mjs";
import { login } from "./mymsal.mjs";
import { starta_dataing, destroya_dataing } from "./fragments/a_dataing.mjs";
import { starta_model, destroya_model } from "./fragments/a_model.mjs";
import { startabout, destroyabout } from "./fragments/about.mjs";
import { destroycompsens, getComputedSensors, startcompsens } from "./fragments/compsens.mjs";
import { startcs, destroycs } from "./fragments/cs.mjs";
import { startmo, destroymo } from "./fragments/mo.mjs";
import { startmostart, destroymostart } from "./fragments/mostart.mjs";
import { startmultiplant, destroymultiplant } from "./fragments/multiplant.mjs";
import { startplant, destroyplant } from "./fragments/plant.mjs";
import { startplantpart, destroyplantpart } from "./fragments/plantpart.mjs";
import { startprivacy, destroyprivacy } from "./fragments/privacy.mjs";
import { startuser, destroyuser } from "./fragments/user.mjs";
import { startusersettings, destroyusersettings } from "./fragments/usersettings.mjs";
import { startwelcome, destroywelcome } from "./fragments/welcome.mjs";
import { destroyasset, startasset } from "./fragments/asset.mjs";
import { callMe } from "./mybackend.mjs";
import { PlantObject } from "./core/plant.mjs";


///////////////////////////
//*** Cache Handling ***//
/////////////////////////
export function checkForUpdate() {
    if (window.applicationCache != undefined && window.applicationCache != null) {
        window.applicationCache.addEventListener('updateready', updateApplication);
    }
}
export function updateApplication(event) {
    if (window.applicationCache.status != 4) return;
    window.applicationCache.removeEventListener('updateready', updateApplication);
    window.applicationCache.swapCache();
    window.location.reload();
}

///////////////////////
//*** Event List ***//
/////////////////////
export let myEvent = new EventList();
myEvent.attach(document.getElementsByTagName("body")[0], "load", checkForUpdate);

/////////////////////////
//*** initializeD3 ***//
///////////////////////
/*
    especially time and number format
    can be used via globals myLocale and numlocale
*/

export var myLocale = null;
export var numlocale = null;

export var language = "";
if (window.navigator.languages) {
    language = window.navigator.languages[0];
} else {
    language = window.navigator.userLanguage || window.navigator.language;
}
if (language.length == 2) {
    if (language == "ca") language = "ca-ES";
    else language = language + "-" + language.toUpperCase();
}
if (language === "en-EN") { language = "en-US"; }
console.log(Intl.getCanonicalLocales(language));

await initializeD3(language);

async function initializeD3(language) {
    await fetch("https://cdn.jsdelivr.net/npm/d3-time-format@4/locale/" + language + ".json")
        .then(d => d.json())
        .then(locale => {
            myLocale = d3.timeFormatDefaultLocale(locale);
            //console.info(myLocale);
        });
    await fetch("https://cdn.jsdelivr.net/npm/d3-format@3/locale/" + language + ".json")
        .then(d => d.json())
        .then(locale => {
            numlocale = d3.formatDefaultLocale(locale);
            //console.info(numlocale);
        })
}

////////////////////////////////
//*** Navigation Enabling ***//
//////////////////////////////
const fragments = ["user", "privacy", "about", "welcome", "usersettings"];
const navigates = ["contact", "confidential", "logo", "logo-footer", "f_welcome", "a_dataing", "a_model"];
const pages = fragments.concat(...["a_dataing", "a_model", "cs", "mo", "mostart", "multiplant", "plant", "plantpart", "asset", "compsens"]);
const titles = ["User Data", "Privacy Information", "About ICMP", "Welcome to ICMP", "User Settings", "Administration: Data Ingest", "Administration: Monitored Objects & Models", "Computed Sensor: ", "(Data Science) Monitored Object: ", "Monitored Object: ", "All Plants Overview", "Plant Overview for ", "Details: ", "Plant Overview by Asset for ", "List of Computed Sensors for "];
const icons = [["fa-solid", "fa-user"], ["fa-solid", "fa-user-lock"], ["fa-solid", "fa-book"], ["fa-solid", "fa-house"], ["fa-solid", "fa-sliders"], ["fa-solid", "fa-right-left"], ["fa-solid", "fa-user-gear"], ["fa-solid", "fa-square-root-variable"], ["fa-solid", "fa-chess-queen"], ["fa-solid", "fa-sitemap"], ["fa-solid", "fa-globe"], ["fa-solid", "fa-chart-pie"], ["fa-solid", "fa-gear"], ["fa-solid", "fa-fan"], ["fa-solid", "fa-square-root-variable"]];

String.prototype.escape = injectEscape;

class UrlHistory {
    static _track = [{ "page": "welcome", "title": "Welcome to ICMP", "icon": createElementAndClassList("i", icons[3]), "detail1": "", "detail2": "" }];
    static push(page, title, icon, det1, det2) {
        this._track.unshift({ "page": page, "title": title, "icon": icon, "detail1": det1, "detail2": det2 });
    }
    static render(id) {
        const content = cleanArea(id);
        this._track.forEach((entry) => {
            if (entry.detail1 == "") createListElementWithLink(content, entry.title, entry.page, entry.icon);
            else {
                if (entry.detail2 == "") createListElementWithLink(content, entry.title + entry.detail1, entry.page + "=" + entry.detail1, entry.icon);
                else createListElementWithLink(content, entry.title + entry.detail1, entry.page + "=" + entry.detail1 + "," + entry.detail2, entry.icon);
            }
        });
    }
}

export const StartHandler = {
    startabout,
    starta_dataing,
    starta_model,
    startasset,
    startcompsens,
    startcs,
    startmo,
    startmostart,
    startmultiplant,
    startplant,
    startplantpart,
    startprivacy,
    startuser,
    startusersettings,
    startwelcome
}

export const DestroyHandler = {
    destroyabout,
    destroya_dataing,
    destroya_model,
    destroyasset,
    destroycompsens,
    destroycs,
    destroymo,
    destroymostart,
    destroymultiplant,
    destroyplant,
    destroyplantpart,
    destroyprivacy,
    destroyuser,
    destroyusersettings,
    destroywelcome
}

export function loadFragment(identifier, mydetail1, mydetail2) {
    let page = (identifier) ? identifier + "" : "";
    const detail1 = (mydetail1) ? String(mydetail1).escape() : "";
    const detail2 = (mydetail2) ? String(mydetail2).escape() + "" : "";
    try { page = this.id + "" } catch (e) {
        if (page === "") {
            return;
        }
    }
    if (pages.includes(page)) {
        const idx = pages.findIndex((v) => v == page);
        const title = titles[idx];
        const icon = createElementAndClassList("i", icons[idx]);
        const fragment = "fragments/" + page + ".html";
        let myNode = $("content");
        //destroy old content
        const destroy = "destroy" + myNode.getAttribute("data").replace("fragments/", "").replace(".html", "");
        if (checkDebug()) console.log(destroy);
        DestroyHandler[destroy]();
        while (myNode.firstChild) {
            myNode.removeChild(myNode.lastChild);
        }
        myNode.setAttribute("data", "");
        //now get new content
        ; (async () => {
            let res = await fetch(fragment);
            if (res.ok) {
                if (checkDebug()) console.log("now should start: " + page);
                myNode.innerHTML = await res.text();
                myNode.setAttribute("data", fragment);
                const start = "start" + page;
                try {
                    if (detail2 == "")
                        if (detail1 == "") StartHandler[start]();
                        else StartHandler[start](detail1);
                    else StartHandler[start](detail1, detail2);
                } catch (e) { console.warn(page, "no load", e); }
            }
            else { console.warn(res); }
        })();
        if (checkDebug()) console.log(new Date().toISOString() + ": Load: " + fragment);
        // Fragment is loaded (or not), now treat fragment independent specialties
        //search
        cleanArea("searchglobalresults");
        $("searchglobal").value = "";
        $('searchglobalresults').classList.remove('show');
        $('searchglobaldropdown').classList.remove('show');
        //Messages
        clearTimeout(MessageBroker._timeout_id);
        MessageBroker.instance().runOnce(true);
        //URL History
        UrlHistory.push(page, title, icon, detail1, detail2);
        UrlHistory.render("urlhistorylist");
    }
}

export function navigate(evt, data) {
    let myparams = ""
    if ((!evt) && (data)) myparams = data;
    else {
        let target = evt.target.tagName == "IMG" ? evt.target.parentElement : evt.target;
        if (evt) myparams = target.getAttribute("data");
    }
    let details = "";
    if (checkDebug()) console.log(myparams);
    if (myparams.indexOf(",") > 0) {
        details = myparams.split(",", 2);
    }
    if (details.length < 2) {
        myparams = myparams.split("=", 2);
        if (myparams.length == 2) {
            loadFragment(myparams[0], myparams[1]);
        } else if (myparams.length == 1) {
            loadFragment(myparams[0]);
        }
    } else if (details.length == 2) {
        myparams = details[0].split("=", 2);
        if (myparams.length == 2) {
            loadFragment(myparams[0], myparams[1], details[1]);
        }
    }
    $("messageClose").click();

}

fragments.forEach((frag) => {
    myEvent.attach($(frag), "click", loadFragment);
});
navigates.forEach((nav) => {
    myEvent.attach($(nav), "click", navigate);
});

myEvent.attach($("loginbw"), "click", login);

///////////////////////////
//*** SYSTEMDATA     ***//
/////////////////////////
export let SYSTEMDATA = [{
    parent: "",
    obj_id: "ICMD",
    objname: "Integrated Condition Monitoring Dashboard",
    obj_shortname: "ICMD",
    typeidname: "App",
    value: 0,
    uom: "",
    datatype: "",
    digits: 0,
    RangeMax: null,
    RangeMin: null,
    LimitHiHi: null,
    LimitHigh: null,
    LimitLow: null,
    LimitLoLo: null,
    UniqueId: "",
    TagId: "",
    System: ""
}];

export function resetSystemData() {
    SYSTEMDATA = [{
        parent: "",
        obj_id: "ICMD",
        objname: "Integrated Condition Monitoring Dashboard",
        obj_shortname: "ICMD",
        typeidname: "App",
        value: 0,
        uom: "",
        datatype: "",
        digits: 0,
        RangeMax: null,
        RangeMin: null,
        LimitHiHi: null,
        LimitHigh: null,
        LimitLow: null,
        LimitLoLo: null,
        UniqueId: "",
        TagId: "",
        System: ""
    }];
}

export async function initSystemData(files) {
    console.time("get circle data");
    let result = new Array();
    let systems;
    try {
        systems = await callMe("/config/systems", "GET");
    }
    catch (err) { console.warn(err); }

    files.forEach((file) => {
        let prefix = file.Prefix;
        let table = systems[file.Path].split("\r\n");
        if (table.length == 1) {
            table = systems[file.Path].split("\n");
        }
        if (checkDebug()) console.log(table.length);
        for (let i = 1; i < table.length; i++) {
            let line = table[i].split(",");
            let entry = new Object();
            entry["parent"] = line[0];
            entry["obj_id"] = line[1];
            entry["objname"] = line[2];
            entry["obj_shortname"] = line[1];
            entry["typeidname"] = line[3];
            entry["value"] = line[4];
            entry["uom"] = line[5];
            entry["datatype"] = line[6];
            entry["digits"] = line[7];
            entry["RangeMax"] = line[8];
            entry["RangeMin"] = line[9];
            entry["LimitHiHi"] = line[10];
            entry["LimitHigh"] = line[11];
            entry["LimitLow"] = line[12];
            entry["LimitLoLo"] = line[13];
            entry["UniqueId"] = line[14];
            entry["TagId"] = line[15];
            entry["System"] = line[16];
            if (!(prefix === null) && !(prefix === "")) {
                if (entry["obj_shortname"].substr(0, prefix.length) == prefix) {
                    entry["obj_shortname"] = entry["obj_shortname"].substr(prefix.length, entry["obj_shortname"].length - prefix.length);
                }
            }
            result.push(entry);
        }
    });
    console.timeEnd("get circle data");
    SYSTEMDATA = SYSTEMDATA.concat(result);

    PlantObject.setData(SYSTEMDATA);

    return true;
}

export function addSensorToDict(dict, sensor, long) {
    let idx = SYSTEMDATA.findIndex(x => x["obj_id"] == sensor);
    let uom = idx >= 0 ? SYSTEMDATA[idx]["uom"] : "";

    dict[sensor] = {
        "name": [
            sensor,
            long
        ],
        "uom": uom,
        "type": "sensor"
    }
}

export async function addComputedSensors2System(moSystem, treesystem) {
    await getComputedSensors(moSystem)
        .then((cs_list) => {
            if (cs_list !== undefined) 
                Object.keys(cs_list).forEach((id) => {
                    let cs = cs_list[id];
                    let shortname = String(cs["short_name"]);
                    cs["parent"] = shortname.substring(shortname.indexOf("_", 4) + 1, shortname.indexOf("_", 7));
                    cs["obj_id"] = shortname;
                    cs["objname"] = cs["technical_desc"];
                    cs["obj_shortname"] = shortname;
                    cs["typeidname"] = "Sensor";
                    cs["value"] = 400;
                    cs["datatype"] = "computed";
                    cs["System"] = treesystem;
                });
            return cs_list;
        })
        .then((cs_list) => {
            let result = new Array();
            if (cs_list !== undefined) 
                Object.keys(cs_list).forEach((id) => {
                    let cs = cs_list[id];
                    let decider = false;
                    let parent = cs["parent"];
                    if (SYSTEMDATA.findIndex((v) => v.parent == parent) > 0) decider = true;
                    if (!decider) if (SYSTEMDATA.findIndex((v) => v.parent == parent.slice(0, -1)) > 0) {
                        decider = true;
                        parent = parent.slice(0, -1);
                    }
                    if (decider)
                        result.push({
                            parent: parent,
                            obj_id: cs["obj_id"],
                            objname: cs["objname"],
                            obj_shortname: cs["obj_shortname"],
                            typeidname: cs["typeidname"],
                            value: cs["value"],
                            uom: cs["uom"],
                            datatype: cs["datatype"],
                            digits: 3,
                            RangeMax: "",
                            RangeMin: "",
                            LimitHiHi: "",
                            LimitHigh: "",
                            LimitLow: "",
                            LimitLoLo: "",
                            UniqueId: "",
                            TagId: "",
                            System: cs["System"]
                        });
                });
            return result;
        })
        .then((res) => {
            SYSTEMDATA = SYSTEMDATA.concat(res);
        })
        .finally(() => {
            PlantObject.setData(SYSTEMDATA);
        });
}



///////////////////////////
//*** Search - Start ***//
/////////////////////////
function doSearch(term) {
    let result = [];
    let regexp = new RegExp(term.split(" ").join(".*"), 'i');
    for (let n in SYSTEMDATA) {
        let v = SYSTEMDATA[n]
        if (v["obj_id"].search(regexp) >= 0 ||
            v["objname"].search(regexp) >= 0 ||
            v["typeidname"].search(regexp) >= 0 ||
            v["uom"].search(regexp) >= 0
        )
            result.push(v);
    }
    return result;
}

var thread = null;
var exit = false;
function findMember(searchString) {
    $("searchglobaldropdown").classList.add("show");
    $("searchglobalresults").classList.add("show");
    cleanArea("searchglobalresults");
    exit = false;
    if (searchString.length < 2) {
        let li = createElementAndClassList("li", "dropdown-item");
        li.textContent = $("atleast").innerHTML;
        $("searchglobalresults").appendChild(li);
        return;
    }
    searchString = String(searchString).escape();
    $('searchglobal').value = searchString;
    let results = doSearch(searchString);
    cleanArea("searchglobalresults");
    if (results.length == 0) {
        let li = createElementAndClassList("li", "dropdown-item");
        li.textContent = $("nomatch").innerHTML;
        $("searchglobalresults").appendChild(li)
        return;
    }

    for (let i in results) {
        let li = createElementAndClassList("li", "dropdown-item");
        let h6 = createElementAndClassList("h6", "d-inline");
        let link = document.createElement("a");
        link.href = "#";
        createsetAttribute(link, "data", "plantpart=" + results[i]["obj_id"] + " - " + results[i]["objname"]);
        link.textContent = results[i]["typeidname"] + ": " + results[i]["obj_id"] + " - " + results[i]["objname"];
        h6.appendChild(link);
        li.appendChild(h6);
        $("searchglobalresults").appendChild(li);
        link.addEventListener("mousedown", navigate);
    }
    $("searchglobal").focus();
}

function leaveSearch() {
    if (exit === true) {
        clearTimeout(thread);
        cleanArea("searchglobalresults");
        $('searchglobalresults').classList.remove('show');
        $('searchglobaldropdown').classList.remove('show');
    }
}
function leaveSearchArea() {
    exit = true;
}
function enterSearchArea() {
    exit = false;
}

console.info("setup search");
var search = $("searchglobal");
var searchresults = $("searchglobalresults");
if (!search.getAttribute("focuslistener")) {
    search.addEventListener("blur", leaveSearch);
    search.addEventListener("focusout", leaveSearch);
    search.setAttribute("focuslistener", "true");
}
if (!searchresults.getAttribute("mouseleavelistener")) {
    search.addEventListener("mouseleave", leaveSearchArea);
    search.setAttribute("mouseleavelistener", "true");
}
if (!searchresults.getAttribute("mouseenterlistener")) {
    search.addEventListener("mouseenter", enterSearchArea);
    search.setAttribute("mouseenterlistener", "true");
}

myEvent.attach($('searchglobal'), "keyup", () => {
    clearTimeout(thread);
    var $this = $('searchglobal'); thread = setTimeout(function () { findMember($this.value) }, 750);
});
