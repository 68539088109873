import { getFilteredPlantData } from "../fragments/asset.mjs";
import { $, $E, $T, cleanArea, createElementAndClassList, ListTools, prettyJsonPrint, updateOnChange } from "../helper.mjs";
import { myEvent } from "../index.mjs";
import { gLoginState } from "../mymsal.mjs";
import { SENSORMAPPING } from "./plant.mjs";

export class ComputedSensorModel {
    constructor() {
        this.key = "";
        this.names = [];
        this.uom = "";
        this.type = "sensor";
        this.parameters = [];
        this.formula = "";
        this.module = "";
        this.arguments = {};

        this.classKey = "";
        this.class = "";
        this.relatedAsset = "";

        this._mandatory = ["key", "names", "uom", "parameters"];
        this._oneofpairs = [["formula", "module"]];
    }

    check() {
        let result = true;
        this.model._mandatory.forEach((v) => {
            if (typeof this.model[v] == "string" && this.model[v] == "") result = false;
            if (typeof this.model[v] == "object" && (this.model[v] == [] || this.model[v] == {})) result = false;
        });
        this.model._oneofpairs.forEach((v) => {
            if (this.model[v[0]] == "" && this.model[v[1]] == "") result = false;
        });
        return result;
    }

    getJSON() {
        let myjson = {};
        myjson[this.key] = {
            "name": this.names,
            "type": this.type,
            "computed": {
                "parameters": this.parameters,
                "formula": this.module == "" ?
                    this.formula :
                    {
                        "module": this.module,
                        "arguments": this.arguments
                    }
            }
        }
        return myjson;
    }
}


export class ComputedSensorView {
    static lt = new ListTools();

    static updateView(model) {
        if (model instanceof ComputedSensorModel) {
            $("cskey").value = model.key;
            $("csname").appendChild($T(model.names.map((a, i) => { if (i > 0) return a; }).join(' - ')));
            $("csuom").value = model.uom;
            $("cstype").value = model.class;
            $("csparameters").appendChild($T(model.parameters.map((p, i) => "#".concat(String(i), ": ", p)).join("\n")));
            $("csparameters").rows = model.parameters.length;
            $("csformula").textContent = model.formula !== "" ? model.formula : JSON.stringify({
                "arguments": model.arguments,
                "module": model.module
            });
            if (model.formula == "") prettyJsonPrint("csformula");
            $("csformula").rows = model.formula !== "" ? model.parameters.length : 5 + Object.keys(model.arguments).length;
        }
    }

    static prepare4edit(model) {
        ComputedSensorView.prepare4new(model);
        $("csEditUom").removeAttribute("disabled");
        $("csEditType").value = model.classKey;
        $("csEditUom").value = model.uom;
        $("csEditSumUom").value = model.uom;
        $("csEditAsset").value = model.relatedAsset;
        $("csEditKey").value = model.key;
        $("csEditSumKey").value = model.key;
        $("csEditName").value = Array.isArray(model.names) ? (model.names.map((v,i) => { if (i>0) return v; }).join(" - ")).substring(3) : model.names;
        $("csEditSumName").value = $("csEditName").value;
        $("csEditFormString").value = model.formula;
        $("csEditFormSumString").value = model.formula;
        $("csEditFormSelect").value = model.module;
        $("csEditFormSumSelect").value = model.module;
        $("csEditFormSumString").rows = model.formula !== "" ? model.parameters.length : 5 + Object.keys(model.arguments).length;

        ComputedSensorView.lt.setConfig(cleanArea("csEditParamList"),cleanArea("csEditParamSumList"), model.parameters, undefined);
        for (let v in model.parameters) {
            this.lt.addSensor(false, { srcElement: { text: model.parameters[v], value: model.parameters[v] } });
        };
    }

    static prepare4new(model) {

        let instrumentType = $("csEditType");
        Object.keys(SENSORMAPPING).forEach((v) => {
            let option = $E("option");
            option.value = v;
            option.appendChild($T(v + " - " + SENSORMAPPING[v].label));
            instrumentType.appendChild(option);
        });
        myEvent.attach(instrumentType, "change", () => {
            let key = $("csEditKey").value
            if (key == "ACT_.CS") {
                key = key.slice(0,4) + instrumentType.value + "_" + key.slice(4);
            } else {
                key.slice(0,4) + instrumentType.value + key.slice(5);
            }
        });

        let uomSelect = $("csEditUom");
        uomSelect.setAttribute("disabled", "disabled")
        let uom = SENSORMAPPING[model.classKey];
        if (uom !== undefined) {
            if (!Array.isArray(uom.uom)) {
                uom.uom = [uom.uom];
            }
            uom.uom.forEach((v) => {
                let option = $E("option");
                option.value = v;
                option.appendChild($T(uom.label + " (" + v + ")"));
                uomSelect.appendChild(option);
            });
        }
        else {
            uom = model.uom;
            let option = $E("option");
            option.value = uom;
            option.appendChild($T(uom));
            uomSelect.appendChild(option);
        }
        if (uom == undefined || uom == "") {
            Object.keys(SENSORMAPPING).forEach((v) => {
                if (!Array.isArray(SENSORMAPPING[v].uom)) {
                    SENSORMAPPING[v].uom = [SENSORMAPPING[v].uom];
                }
                let uomSet = [];
                SENSORMAPPING[v].uom.forEach((v) => {
                    if (!uomSet.includes(v)) uomSet.push(v);
                });
                uomSet.sort();
                uomSet.forEach((v)=>{
                    let option = $E("option");
                    option.value = v;
                    option.appendChild($T(v));
                    uomSelect.appendChild(option);
                });
            });
        }

        let assets = getFilteredPlantData(gLoginState.currentPlant.AreaKey, "Equipment");
        assets.sort((a,b) => a.obj_id.localeCompare(b.obj_id));
        let assetSelect = $("csEditAsset");
        assets.forEach((v)=>{
            let option = $E("option");
            option.value = v.obj_id;
            option.appendChild($T(v.obj_id + " - " + v.objname));
            assetSelect.appendChild(option);
        });
        myEvent.attach(instrumentType, "change", () => {
            let key = $("csEditAsset").value
            if (key == "ACT_.CS") {
                key = key.slice(0,4) + instrumentType.value + key.slice(4);
            } else {
                key.slice(0,key.indexOf("_", 5)+1) + instrumentType.value + key.slice(key.indexOf("_", 7));
            }
        });


        let sensors = getFilteredPlantData(gLoginState.currentPlant.AreaKey, "Sensor");
        sensors.forEach((sensor) => {
            let opt = createElementAndClassList("option", "fs-6");
            opt.value = sensor.obj_id;
            opt.text = sensor.obj_id + " - " + sensor.objname;
            $("csEditParamSelect").appendChild(opt);
            
            opt.addEventListener("click", (opt2) => {
                ComputedSensorView.lt.setConfig($("csEditParamList"), $("csEditParamSumList"), model.parameters, opt2)
                ComputedSensorView.lt.addSensor();
            });
            
        });

        updateOnChange("csEditKey", "csEditSumKey");
        updateOnChange("csEditName", "csEditSumName");
        updateOnChange("csEditUom", "csEditSumUom");
        updateOnChange("csEditFormSelect", "csEditFormSumSelect");
        updateOnChange("csEditFormString", "csEditFormSumString");
    }
}

export class ComputedSensorController {
    constructor() {
        this.initData();
    }

    initData() {
        this.model = new ComputedSensorModel();
    }

    setData(newmodel) {
        let state = true;
        Object.keys(this.model).forEach((v) => {
            try {
                this.model[v] = newmodel[v];
            } catch (error) {
                state = false;
                console.log(error);
            }
        });
        if (state) ComputedSensorView.updateView(this.model);
    }

    setDataFromObject(key, cs_object) {
        this.initData();
        this.model.key = key;
        this.model.names = cs_object["name"];
        this.model.classKey = key.substring(4, key.indexOf("_", 4));
        this.model.uom = cs_object["uom"] == "" ? (this.model.classKey in SENSORMAPPING ? (Array.isArray(SENSORMAPPING[this.model.classKey].uom) ? SENSORMAPPING[this.model.classKey].uom[0] : SENSORMAPPING[this.model.classKey].uom) : cs_object["uom"]) : cs_object["uom"];
        this.model.class = this.model.classKey in SENSORMAPPING ? SENSORMAPPING[this.model.classKey].label + " (" + this.model.classKey + ")" : this.model.classKey;
        this.model.relatedAsset = key.substring(key.indexOf("_", 4) + 1, key.indexOf("_", 7));
        this.model.parameters = cs_object["computed"]["parameters"];
        this.model.formula = typeof cs_object["computed"]["formula"] == "string" ? cs_object["computed"]["formula"] : "";
        this.model.module = typeof cs_object["computed"]["formula"] == "object" ? cs_object["computed"]["formula"]["module"] : "";
        this.model.arguments = this.model.module !== "" ? cs_object["computed"]["formula"]["arguments"] : {};

        ComputedSensorView.updateView(this.model);
    }

    save() {
        if (this.model.check()) {

        }
    }

    delete() {

    }




}