import { ComputedSensorController, ComputedSensorView } from "../core/computedsensor.mjs";
import { Sensor2MO } from "../core/plant.mjs";
import { ComputedSensorTimeSeries } from "../core/timeseries.mjs";
import { $, createsetAttribute } from "../helper.mjs";
import { myEvent, navigate } from "../index.mjs";
import { callMe } from "../mybackend.mjs";
import { gLoginState } from "../mymsal.mjs";
import { getFilteredPlantData } from "./asset.mjs";

function fixResize() {
    try {
        let actualTS = $("actuals_chart");
        Plotly.react(actualTS.id, actualTS.data, actualTS.layout, actualTS.config);

    } catch (e) { console.log(e); }
}

export async function startcs(sensor, system) {
    console.log("startcs", sensor);
    let mosystem = Number(system);
    let plant;
    for (let p in gLoginState.authorizedPlants) {
        if (gLoginState.authorizedPlants[p].System == mosystem) {
            plant = p;
            break;
        }
    }
    gLoginState.setCurrentPlant(plant);

    let key = sensor;
    let cs_object;

    let cs_ctrl = new ComputedSensorController();

    await Promise.all([
        callMe("/blackbox/molist", "POST",
            { model: "_CS", type: "definition", system: mosystem }
        )
    ])
        .then((res) => {
            if ("data" in res[0]) {
                Object.keys(res[0]["data"]).forEach((v) => {
                    if (v.endsWith("_CS.json")) {
                        Object.keys(res[0]["data"][v]).forEach((val) => {
                            if (val == key) {
                                cs_object = structuredClone(res[0]["data"][v][val]);
                            }
                        })
                    }
                });
            }
        })
        .finally(() => {
            cs_ctrl.setDataFromObject(key, cs_object);
            ComputedSensorView.prepare4edit(cs_ctrl.model);
        });
    
    window.addEventListener("resize", fixResize);
    let assets = getFilteredPlantData(gLoginState.getCurrentPlant().AreaKey, "Equipment")
    let parent = key.substring(key.indexOf("_", 4)+1, key.indexOf("_", 7));
    if (assets.findIndex((v)=>{return v.obj_id == parent}) < 0 ) parent = parent.slice(0, -1);
    if (assets.findIndex((v)=>{return v.obj_id == parent}) > 0 ) 
        createsetAttribute($("gotoparent"), "data", "plantpart=" + parent);
    else $("gotoparent").classList.add("d-none");
    myEvent.attach($("gotoparent"), "click", navigate);
    ["short", "middle", "long"].forEach((id) => {
        myEvent.attach($(id), "click", async () => {
            await myTS.getTimeSeries(mosystem, [key].concat(cs_object["computed"]["parameters"]), id, "actuals_chart");
            ["short", "middle", "long"].forEach((i) => {
                if ($(i).classList.contains("active")) $(i).classList.remove("active");
            });
            $(id).classList.add("active");
        });
    });

    let myTS = new ComputedSensorTimeSeries("short");
    let arrayOfPromises = [
        callMe("/blackbox/molist", "POST", { model: "", type: "definition", system: mosystem }),
        myTS.getTimeSeries(mosystem, [key].concat(cs_object["computed"]["parameters"]), "short", "actuals_chart")
    ];

    await Promise.all(arrayOfPromises)
        .then((responses) => {
            fixResize();
            var molist = responses[0];
            Sensor2MO(molist["data"], key);
        })
}

export function destroycs() {
    window.removeEventListener("resize", fixResize);
}