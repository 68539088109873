import { myEvent, navigate, SYSTEMDATA } from "../index.mjs";
import { $, $T, cleanArea, createElementAndClassList, createsetAttribute, injectEscape } from "../helper.mjs";
import { gLoginState } from "../mymsal.mjs";
import { callMe } from "../mybackend.mjs";

String.prototype.escape = injectEscape;

function fillSections(data) {
    $("plantname").textContent = data[0].parent;

    let accordion = cleanArea("accordionPlantSections");

    for (var linenumber in data) {
        let d = data[linenumber];
        let accitem = createElementAndClassList("div", "accordion-item");
        accitem.id = "accitem-" + d.obj_id;
        let header = createElementAndClassList("h4", "accordion-header");

        let buttonnav = createElementAndClassList("button", "accordion-button");
        buttonnav.type = "button";
        let tempid = "panel-" + d.obj_id;
        buttonnav.id = "button-" + d.obj_id;
        createsetAttribute(buttonnav, "data-bs-toggle", "collapse");
        createsetAttribute(buttonnav, "data-bs-target", "#" + tempid);
        createsetAttribute(buttonnav, "aria-expanded", "true");
        createsetAttribute(buttonnav, "aria-controls", tempid);
        let puzzle = createElementAndClassList("i", ["fa-solid", "fa-cheese"]);
        let title = document.createElement("span");
        title.appendChild($T("\xa0" + d.obj_id + "\xa0-\xa0" + d.objname));
        buttonnav.appendChild(puzzle);
        buttonnav.appendChild(title);

        header.appendChild(buttonnav);
        accitem.appendChild(header);

        let panelItem = createElementAndClassList("div", ["accordion-collapse", "collapse", "show"]);
        panelItem.id = tempid;
        let accBody = createElementAndClassList("div", "accordion-body");
        let bodyRow = createElementAndClassList("div", ["row", "row-cols-1", "row-cols-md-2", "row-cols-lg-3", "row-cols-xl-4", "row-cols-xxl-5", "g-4"]);
        bodyRow.id = "content-" + d.obj_id;
        accBody.appendChild(bodyRow);

        panelItem.appendChild(accBody);
        accitem.appendChild(panelItem);

        accordion.appendChild(accitem);
    }
}

function fillAssets(assets) {
    assets.forEach(asset => {
        let parent = $("content-" + asset.parent);
        let card = createElementAndClassList("div", ["card"]);
        let cardBody = createElementAndClassList("div", ["card-body", "d-flex", "justify-content-between"]);
        cardBody.id = asset.obj_id;
        let cardTitle = createElementAndClassList("h5", ["card-title", "icon-link"]);
        let link = document.createElement("a");
        link.href = "#";
        createsetAttribute(link, "data", "plantpart=" + asset.obj_id + "," + gLoginState.getCurrentPlant().System);
        link.append(
            $T(asset.obj_id + " -"),
            document.createElement("br"),
            $T(asset.objname)
        );
        myEvent.attach(link, "click", navigate);
        cardTitle.appendChild(link);
        cardBody.appendChild(cardTitle);
        card.appendChild(cardBody);
        parent.appendChild(card);
    });
}

export function getFilteredPlantData(plant, filter) {
    var result = SYSTEMDATA;
    var data = [];
    for (let i = 0; i < result.length; i++) {
        let line = result[i];
        if (line["typeidname"] !== filter) {
            continue;
        }
        if (line["System"] == plant) {
            data.push(line);
        }
    }
    return data;
}

export async function startasset(scope) {
    console.log("startasset", scope);
    gLoginState.setCurrentPlant(scope);
    let plant = gLoginState.getCurrentPlant();
    let system = getFilteredPlantData(plant.AreaKey, "Section");
    fillSections(system);
    let assets = getFilteredPlantData(plant.AreaKey, "Equipment");
    fillAssets(assets);

    createsetAttribute($("g_plant"), "data", "plant="+plant.AreaKey);
    myEvent.attach($("g_plant"), "click", navigate);
    createsetAttribute($("g_compsens"), "data", "compsens="+plant.AreaKey);
    myEvent.attach($("g_compsens"), "click", navigate);

    Promise.all([
        callMe("/blackbox/molist", "POST", { model: "", type: "definition", state: null, system: plant.System }),
        callMe("/blackbox/info/sensors", "POST", {criteria: "", computed: true, system: plant.System})
    ])
        .then((responses) => {
            let moList = responses[0]["data"];
            let compSens = responses[1]["data"];
            Object.keys(moList).forEach((id)=>{
                let mo = moList[id];
                if ("type" in mo && mo.type == "monitored object") {
                    let idx = String(mo.id).indexOf("_");
                    let short = "";
                    if (idx > 0) short = mo.id.substring(0, idx).escape();
                    else short = String(mo.id).escape();
                    let asset = $(short);
                    if (asset) {
                        let icon = createElementAndClassList("i", ["fa-solid", "fa-sitemap"]);
                        icon.id = "icon-" + short;
                        if (!$("icon-" + short)) asset.appendChild(icon);
                    }
                }
            });
            Object.keys(compSens).forEach((id)=>{
                let sensor = compSens[id];
                let name = String(sensor.short_name);
                let assetString = name.substring(name.indexOf("_", 4)+1, name.indexOf("_", 7)).escape();
                let fits = assets.filter((v) => {
                    if (String(v.obj_id).startsWith(assetString)) return v
                });
                if (fits.length == 0) {
                    assetString = assetString.slice(0, -1);
                    fits = assets.filter((v) => {
                        if (String(v.obj_id).startsWith(assetString)) return v
                    });
                }
                let parent = "";
                if (fits.length !== 0) parent = $(fits[0].obj_id);
                if (parent !== "") {
                    let icon = createElementAndClassList("i", ["fa-solid", "fa-square-root-variable"]);
                    icon.id = "icon-cs-" + assetString;
                    if (!$("icon-cs-" + assetString)) parent.appendChild(icon);
                }
            });
        })
}

export function destroyasset() {

}