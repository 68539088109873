import { myEvent, navigate } from '../index.mjs';
import { $, createComplexLink, createElementAndClassList, createsetAttribute } from "../helper.mjs";
import { userjson, usergroups, admingroups, isAdmin, gLoginState } from '../mymsal.mjs';
import { GROUP2PLANT, PLANTS } from '../core/plant.mjs'

function fillGroups(id, groups) {
    let content = $(id);
    let ul = createElementAndClassList("ul", "list-group");

    for (let g in groups) {
        let li = createElementAndClassList("li", "list-group-item");
        if (Object.keys(GROUP2PLANT).includes(groups[g])) {
            let a = createComplexLink([], undefined, );
            a.href = "#";
            a.textContent = groups[g];
            createsetAttribute(a, "data", "plantpart=" + PLANTS[GROUP2PLANT[groups[g]]].NameKey);
            myEvent.attach(a, "click", navigate);
            li.appendChild(a);
        } else li.textContent = groups[g];
        ul.appendChild(li);
    }

    content.appendChild(ul);
}

export function setUser() {
    $("user_name").value = userjson.displayName;
    $("user_phone").value = userjson.businessPhones;
    $("user_mobile").value = userjson.mobilePhone;
    $("user_mail").value = userjson.mail;
    $("user_user").value = userjson.userPrincipalName;
    $("user_isAdmin").value = isAdmin;
};

export function initUser() {
    $("user_name").value = "";
    $("user_phone").value = "";
    $("user_mobile").value = "";
    $("user_mail").value = "";
    $("user_user").value = "";
    $("user_isAdmin").value = "";
};

export function startuser() {
    console.log("startuser");
    if (gLoginState.loginstate) {
        setUser();

        fillGroups("viewer", usergroups);
        fillGroups("admin", admingroups);
    }
}

export function destroyuser() {
    initUser();
}
