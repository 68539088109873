import { navigate, SYSTEMDATA } from "../index.mjs";
import { $, $E, $T, checkDebug, cleanArea, createComplexLink, createElementAndClassList, createsetAttribute } from "../helper.mjs";
import { callMe } from "../mybackend.mjs";
import { gLoginState } from "../mymsal.mjs";
import { MonitoredObjectController, MonitoredObjectView } from "../core/monitoredobject.mjs";

// globals
let color, signalcolor;

function adaptView() {
    let size = 0;
    if (window.innerWidth < window.innerHeight) {
        size = window.innerWidth * 96 / 100;
    } else {
        size = window.innerHeight * 5 / 7;
    }

    let width = size * 9 / 10;
    let height = width;

    d3.select("#multi")
        .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
        .attr("width", size + "px")
        .attr("height", size + "px")

    return [width, height];
}

//Build it
function chart(data, signaldata, molist, object_type, moCtrl, width, height) {

    console.time("chart");

    let pack = (data) => d3.pack()
        .size([width, height])
        .radius(d => 10)
        .padding(3)
        (d3.hierarchy(data)
            .sum(d => d.value)
            .sort((a, b) => b.value - a.value));
    const root = pack(data);
    if (checkDebug()) console.log(root);
    let focus = root;
    let view;
    const svg = d3.select("#multi")
        .style("background", "white")
        .style("cursor", "pointer")
        .on("click", () => zoom(root))
        .on("resize", () => zoom(focus));

    const node = svg.append("g")
        .selectAll("circle")
        .data(root.descendants().slice(1))
        .join("circle")
        .attr("fill", d => d.data.data.typeid == 50 || d.data.data.typeid == 60 || d.data.data.typeid == 61 ? signalcolor(signaldata.find(x => x[0] === d.data.data.obj_id)[1]) : !d.children ? color(color.domain()[1]) : color(d.depth))
        .attr("pointer-events", null) // !d.children ? "none" : null 
        .attr("id", d => "node_" + d.data.data.obj_id)
        .style("display", "inline")
        .attr("data-bs-toggle", "tooltip")
        .attr("data-bs-title", d => d.data.data.obj_shortname)
        .on("click", d => focus !== d && (zoom(d), d3.event.stopPropagation()))
        .on("mouseover", function () { d3.select(this).attr("stroke", "#000"); })
        .on("mouseout", function () { d3.select(this).attr("stroke", null); })
        ;

    let label = svg.append("g")
        .attr("pointer-events", "none")
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "text-top")
        .attr("y", -20)
        .selectAll("text")
        .data(root.descendants())
        .join("text")
        .attr("id", (d) => "label_" + d.data.data.obj_id)
        .style("fill-opacity", d => d.parent === root ? 0.3 : 0)
        .style("font", d => d.depth === 5 && d.parent.children.length > 130 ? "0.4em sans-serif" : 3 / d.depth + "em sans-serif")
        ;

    label
        .selectAll("tspan")
        .data(d => d.depth === 5 ? d.data.data.obj_shortname.split(/[ _-]/) : [d.data.data.obj_shortname])
        .join("tspan")
        .text(d => d)
        .attr("x", 0)
        .attr("y", (d, i) => (i - 1) * (10))
        ;

    var graphme = $("graphme");
    var graphparent = $("graphparent");
    var graphchildren = $("graphchildren");
    var mome = $("mome");
    var mochildren = $("mochildren");


    function zoomTo(v) {
        const k = width / v[2];
        view = v;
        label.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
        node.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
        node.attr("r", d => d.r * k);
    }

    zoomTo([root.x, root.y, root.r * 2]);
    let oldkey;

    function zoom(d) {
        const focus0 = focus;
        focus = d;

        const transition = svg.transition()
            .duration(d3.event.altKey ? 7500 : 750)
            .tween("zoom", d => {
                const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
                return t => zoomTo(i(t));
            });

        label
            .filter(function (d) { return d.parent === focus || this.style.display === "inline"; })
            .transition(transition)
            .style("fill-opacity", d => d.parent === focus && (d.children ? d.children.length > 1 : true) ? 1 : 0)
            .on("start", function (d) { if (d.parent === focus) this.style.display = "inline"; })
            .on("end", function (d) { if (d.parent !== focus) this.style.display = "none"; });

        //Details to selected object
        graphme.value = d.data.data.obj_id + " - " + d.data.data.objname;
        graphparent.value = d.depth === 0 ? "" : d.data.parent.data.objname;
        graphchildren.innerHTML = "";

        for (let i = 0; i < d.data.children.length; i++) {
            let line = createElementAndClassList("li", "list-group-item");

            let link = $E("a");
            link.href = "#";
            createsetAttribute(link, "data", "plantpart=" + d.data.children[i].data.obj_id + " - " + d.data.children[i].data.objname);
            link.textContent = d.data.children[i].data.obj_id + " - " + d.data.children[i].data.objname;
            link.addEventListener("click", navigate);
            line.appendChild(link);
            graphchildren.appendChild(line);
        }

        //check & set plant / system if necessary
        $("modetails").href = "/" + d.data.data.obj_id;
        mome.value = d.data.data.obj_id + " - " + d.data.data.objname;
        mochildren.innerHTML = "";

        let plantkey = "";
        switch (d.depth) {
            case 0:
                plantkey = "";
                break;
            case 1:
                plantkey = "";
                break;
            case 2:
                plantkey = d.data.id;
                break;
            case 3:
                plantkey = d.parent.data.data.obj_id;
                break;
            case 4:
                plantkey = d.parent.data.data.parent;
                break;
        }
        gLoginState.setCurrentPlant(plantkey == "" ? "" : plantkey);
        if (plantkey !== "" && oldkey !== plantkey) {
            oldkey = plantkey;
            MonitoredObjectView.filterBlobsToPeriods(moCtrl.cfgBlobs);
            moCtrl.registerListEntries(SYSTEMDATA);
        }

        let btndetail = $("graphdetail");
        if (gLoginState.currentPlant !== undefined) createsetAttribute(btndetail, "data", "mo=" + d.data.data.obj_id + "," + gLoginState.currentPlant.System);
        else createsetAttribute(btndetail, "data", "mo=" + d.data.data.obj_id);
        btndetail.addEventListener("click", navigate);

        //Monitored Object List
        let thismolist = {};
        if (plantkey !== "") {
            thismolist = molist[plantkey].data;
        } else {
            Object.keys(molist).forEach((v1) => {
                if (d.data.children.findIndex((v2, i2, o) => { return o[i2].id == v1 }) >= 0)
                    thismolist = Object.assign(thismolist, molist[v1].data);
            });
        }
        let empty = true;
        let id = "id";
        let name = "name";
        let mysystem = gLoginState.currentPlant !== undefined ? gLoginState.currentPlant.System : "";
        for (var mo in thismolist) {
            if (id in thismolist[mo]) {
                switch (d.depth) {
                    case 0:
                        break;
                    case 1:
                        break;
                    case 2:
                        let search = "system";
                        break;
                    case 3:
                        empty = true;

                        for (var i = 0; i < d.data.children.length; i++) {
                            if (thismolist[mo][id].indexOf(d.data.children[i].data.obj_id) >= 0) {
                                empty = false;
                                break;
                            }
                        }
                        if (empty && (thismolist[mo][id].indexOf(d.data.data.obj_id.substr(2, 2)) < 0)) continue;

                        break;
                    case 4:

                        if (thismolist[mo][id].indexOf(d.data.data.obj_id) < 0) continue;

                        break;
                }
                let line = createElementAndClassList("li", "list-group-item");
                let link = createComplexLink([], undefined, $T(thismolist[mo][id] + " - " + thismolist[mo][name]), "mo=" + thismolist[mo][id] + "," + mysystem)
                line.appendChild(link);
                mochildren.appendChild(line);
            }
        }
    }
    console.timeEnd("chart");
    return svg
}

//basic page creation         
export async function startmultiplant() {
    /**************/
    /* Activation */
    /**************/
    // D3 preparation
    if (gLoginState.getLoginState()) {
        console.time("startmultiplant");
        // set globals
        color = d3.scaleLinear()
            .domain([0, 5]) // 0 for app, 1 for plant, 2 for section area, 3 for section, 4 for equipment, 5 for sensor
            .range(["rgb(16,80,192)", "rgb(255,255,255)"])
            .interpolate(d3.interpolateRgb);
        let width, height;
        signalcolor = d3.scaleOrdinal(
            ["ALERT", "CRITICAL", "WARNING", "TRENDING", "CONSTANT", "NORMAL", "DEACTIVATED"],
            ["red", "darkorange", "gold", "yellow", "cornflowerblue", "darkseagreen", "darkgrey"]
        );
        [width, height] = adaptView();

        // call-group: 
        let arrayOfPromises = [
            callMe("/blob/container/contents", "POST", { account: gLoginState.currentEnv.modelstorage })
        ];
        let myplant = gLoginState.getAuthorizedPlants();
        Object.keys(myplant).forEach((v, i) => {
            if (myplant[v].System !== undefined) {
                arrayOfPromises.push(
                    callMe("/blackbox/molist", "POST", { model: "", type: "definition", state: null, system: myplant[v].System })
                );
            } else {
                delete myplant[v];
            }
        });
        await Promise.all(arrayOfPromises)
            .then((responses) => {
                console.time(`process`);

                var data = SYSTEMDATA;
                var mysignaldata = [];
                var cfgBlobs = responses[0];
                let MOList = {}
                Object.keys(myplant).forEach((v, i) => {
                    MOList[v] = responses[i + 1];
                });

                //D3 stratify to create tree
                const stratify = d3.stratify()
                    .id(d => d["obj_id"])
                    .parentId(d => d["parent"]);

                var newdata = stratify(data);
                for (var n = 0; n < data.length; n++) {
                    if (mysignaldata == null) mysignaldata = new Array();
                    if (!mysignaldata.find(m => m[0] == data[n].obj_id)) {
                        var mynew = new Object();
                        mynew[0] = data[n].obj_id;
                        mynew[1] = "NORMAL";
                        mynew[2] = "Normal"
                        mysignaldata.push(mynew);
                    }
                }

                [width, height] = adaptView();
                let myMoCtrl = new MonitoredObjectController(cfgBlobs,)
                chart(newdata, mysignaldata, MOList, null, myMoCtrl, width, height);

                console.timeEnd(`process`);
            });
        console.timeEnd("startmultiplant");
    }

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));
    window.addEventListener("resize", () => { adaptView(); });
}

export function destroymultiplant() {
    if ($("multi") !== null) {
        Plotly.purge("multi");
    }
    let _ = cleanArea("multi");
}